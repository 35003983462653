import { Chip, Grid, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CONFIG_APP_BASE_API } from '../../../configApp';

const list = [
  {
    type: "cpu",
    title: "Total CPU % (Past 1 hour)",
    desc: "The computer's central processing unit (CPU) is the portion of a computer that retrieves and executes instructions",
    url: "containerd.cpu.total"
  },
  {
    type: "memory",
    title: "RSS Memory (Past 1 hour)",
    desc: "Resident Set Size is memory allocated to the process and is in RAM",
    url: "kubernetes.memory.rss"
  }, {
    type: "cpu",
    title: "CPU Usage % (Past 1 hour)",
    desc: "The average processor utilization percentage for all processors currently online",
    url: "container.cpu.usage"
  }, {
    type: "io",
    title: "IO Read % (Past 1 hour)",
    desc: "The number of read input/output operations generated by a process, including file, network, and device I/Os",
    url: "container.io.read"
  }, {
    type: "io",
    title: "IO Write % (Past 1 hour)",
    desc: "The number of writes input/output operations generated by a process, including file, network, and device I/Os",
    url: "container.io.write"
  }, {
    type: "memory",
    title: "Memory Limit % (Past 1 hour)",
    desc: "The maximum random access memory (RAM) installed in any computer system is limited by hardware, software and economic factors",
    url: "container.memory.limit"
  }, {
    type: "memory",
    title: "Memory Usage % (Past 1 hour)",
    desc: "Memory usage by the container, in bytes.",
    url: "container.memory.usage"
  }, {
    type: "net",
    title: "Net Received  % (Past 1 hour)",
    desc: "Network traffic received by the container.",
    url: "container.net.rcvd"
  }, {
    type: "net",
    title: "Net Sent % (Past 1 hour)",
    desc: "Network traffic sent by the container.",
    url: "container.net.sent"
  }, {
    type: "pid",
    title: "PID Open files % (Past 1 hour)",
    desc: "Process ID When a new process is created by the user, the operating system assigns a unique ID i.e a process-ID to that process",
    url: "container.pid.open_files"
  }, {
    type: "pid",
    title: "PID Thread Count % (Past 1 hour)",
    desc: "The thread count of a process is the number of threads that are currently active within that process.",
    url: "container.pid.thread_count"
  }, {
    type: "others",
    title: "Uptime % (Past 1 hour)",
    desc: "The percentage of time, the application is running and operational",
    url: "container.uptime"
  }, {
    type: "cpu",
    title: "CPU Limit % (Past 1 hour)",
    desc: "The maximum amount of CPU resources that a system, a process, or a container can use",
    url: "containerd.cpu.limit"
  }, {
    type: "cpu",
    title: "CPU System % (Past 1 hour)",
    desc: "Percentage of time the CPU was busy processing kernel code.",
    url: "containerd.cpu.system"
  }, {
    type: "cpu",
    title: "CPU User % (Past 1 hour)",
    desc: "Percentage of time the CPU was busy processing non-kernel code.",
    url: "containerd.cpu.user"
  }, {
    type: "others",
    title: "Restarts % (Past 1 hour)",
    desc: "Restarts is the process of closing and starting a computer or its operating system from the initial boot loading sequence.",
    url: "kubernetes.containers.restarts"
  }, {
    type: "others",
    title: "Running % (Past 1 hour)",
    desc: "The program is running",
    url: "kubernetes.containers.running"
  }, {
    type: "cpu",
    title: "Total CPU Usage % (Past 1 hour)",
    desc: "indicates the total percentage of processing power exhausted to process data and run various programs on a network device, server, or computer at any given point",
    url: "kubernetes.cpu.usage.total"
  },
]

function DataDog() {
  const match = useRouteMatch();
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [filterValue, setfilterValue] = useState("cpu");


  useEffect(() => {
    if (match.params.id) {
      axios.post(`${CONFIG_APP_BASE_API}/api/v1/dataDog`, {

        "appName": match.params.id,
        "userId": localStorage.getItem("userId"),

      }).then((res) => {
        setdata(null)
        setisLoading(false)
        setdata(res)
      }).catch((err) => {
        setisLoading(false)
      })

    }

  }, []);




  return (
    <div>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{mb:2}}
      >
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "" ? "filled" : "outlined"} label="Show All" color="primary" onClick={()=> setfilterValue("")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "cpu" ? "filled" : "outlined"} label="CPU (6 Charts) " color="primary" onClick={()=> setfilterValue("cpu")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "memory" ? "filled" : "outlined"} label="Memory (3 Charts) " color="primary" onClick={()=> setfilterValue("memory")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "io" ? "filled" : "outlined"} label="IO (2 Charts) " color="primary" onClick={()=> setfilterValue("io")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "net" ? "filled" : "outlined"} label="Network (2 Charts) " color="primary" onClick={()=> setfilterValue("net")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "pid" ? "filled" : "outlined"} label="Process Id (2 Charts) " color="primary" onClick={()=> setfilterValue("pid")} /> }
        </Grid>
        <Grid item>
          {isLoading ? <Skeleton variant='rounded' width={90} height={30} style={{borderRadius:"24px"}}/> : <Chip variant={filterValue === "others" ? "filled" : "outlined"} label="Others (3 Charts) " color="primary" onClick={()=> setfilterValue("others")} /> }
        </Grid>
        
      </Grid>

      {isLoading && <Skeleton variant='rounded' width={600} height={300} />}

      <Grid container spacing={2}>
        {data && data.data && list.map((e, i) => {
          if (e.type === filterValue || filterValue === "") {
            return (<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Typography variant='h6'>{e.title}</Typography>
              <Typography style={{ color: "grey", marginBottom: "10px" }} variant='body2'>{e.desc}</Typography>
              {data && data.data && data.data[e.url] &&
                <iframe src={`https://us5.datadoghq.com/graph/embed?token=${data.data[e.url].embed_id}&height=300&width=600&legend=false`} width="600" height="300" style={{ border: "none" }}></iframe>
              }
            </Grid>)
          }

        })}
      </Grid>





    </div>
  )
}

export default DataDog