import { Box, Button, Container, Tabs, Tab, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Paper, Typography, Dialog } from '@mui/material'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Nav from '../Settings/Nav'
import { BiSolidTimeFive } from 'react-icons/bi'
import { IoIosPeople } from 'react-icons/io'
import { APP, formatTime } from '../Deployments/Overview/Main'
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { useLazyQuery } from '@apollo/client'
import { GoDotFill } from 'react-icons/go'
import { ELB_URL } from '../Deployments/Services/Main'
import { MdContentCopy, MdOutlineTimelapse } from 'react-icons/md'
import { RiCloseCircleFill } from 'react-icons/ri'
import FolderList from '../Deployments/Logs/LogList';
import DeleteApp from '../Deployments/AppSettings/DeleteApp';
import UserDetailsTooltip from '../Home/UserDetailsTooltip';
import { CustomTooltip } from '../Settings/Organization';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function OverviewDatabase() {
    const match = useRouteMatch();
    let history = useHistory();
    const [value, setValue] = React.useState(0);
    const [open, setopen] = React.useState(false);
    const [showPassword, setshowPassword] = React.useState(false);
    const [showUserName, setshowUserName] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleChange = (newValue) => {
        setValue(newValue);
    };

    function getDatabasePassword(data) {
        // Parse environment arguments from JSON
        const envArgs = JSON.parse(data.app.envArgs);

        // Mapping of database identifiers to environment variable keys
        const passwordKeys = {
            mysql: 'MYSQL_ROOT_PASSWORD',
            postgres: 'POSTGRES_PASSWORD',
            redis: 'REDIS_PASSWORD',
            maria: 'MYSQL_ROOT_PASSWORD',
            mongo: 'MONGO_INITDB_ROOT_PASSWORD'
        };

        // Check which database is included in the image name
        const databaseNames = Object.keys(passwordKeys);
        const includedDatabase = databaseNames.find(name => data.app.imageName.includes(name));

        // Return the corresponding password if a database name is found
        if (includedDatabase) {
            const passwordKey = passwordKeys[includedDatabase];
            // Only return the password if it exists in envArgs
            return envArgs[passwordKey] || 'Password not found';
        }

        // Return a message if no database is included in the image name
        return 'No recognized database found in image name';
    }

    const handleCopy = (value) => {
        navigator.clipboard.writeText(value)
        enqueueSnackbar("Copied to clipboard!", {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
    }

    const handleDeleteDialog = () => {
        console.log("called del")
        setopen(false)
        history.push("/databases");
    }


    const [fetchELB, { data: urldata, error: urlerr, loading: urlload }] = useLazyQuery(ELB_URL, {
        variables: {
            appName: match.params.id
        }
    })


    const [fetchAppOverview, { loading, error, data }] = useLazyQuery(APP, { variables: { name: match.params.id } });
    useEffect(() => {
        fetchAppOverview();
        fetchELB();

        return () => {

        };
    }, [data]);


    return (
        <Box>
            <Nav />
            <Container>
                <Tabs value={value} aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} onClick={() => handleChange(0)} />
                    <Tab label="logs" {...a11yProps(1)} onClick={() => handleChange(1)} />
                    <Tab label="Settings" {...a11yProps(2)} onClick={() => handleChange(2)} />


                </Tabs>

                <TabPanel value={value} index={0}>
                    {data && data.app && <Grid container>
                        <Grid item xs={12} md={8}>
                            <Box>

                                {data && data.app.status && <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >

                                    {(data && data.app.status === "Active") ? <svg className="checkmark" style={{ margin: "0px 8px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg> : <RiCloseCircleFill style={{ fontSize: "25px", margin: "0px 8px" }} />}
                                    <Typography sx={{ my: 4, fontSize: "22px", textAlign: "center" }}>
                                        Your Database is {data.app.status}
                                    </Typography>
                                </Grid>}




                                {data && <Paper variant='outlined' sx={{ borderRadius: "12px", p: 4 }}>
                                    <Grid container>
                                        {data && data.app && <Grid item xs={12} md={4} lg={4} sx={{ cursor: "pointer" }}>
                                            <ListItem sx={{ borderRadius: "12px", p: 1, pl: 0 }} >
                                                <ListItemIcon sx={{ p: 0, m: 0 }}>
                                                    <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "0px" }}><IoIosPeople /></IconButton>
                                                </ListItemIcon>
                                                <ListItemText sx={{ textDecoration: "capitalize" }} primary="Organization" secondary={<span>{data && data.app.organization.name}</span>} />
                                            </ListItem>
                                        </Grid>}


                                        {data && data.app && <Grid item xs={12} md={4} lg={4} sx={{ cursor: "pointer" }}>
                                            {data && data.app.deploymentTime && <ListItem sx={{ borderRadius: "12px", p: 1, pl: 0 }} >
                                                <ListItemIcon sx={{ p: 0, m: 0 }}>
                                                    <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "0px" }}><BiSolidTimeFive /></IconButton>
                                                </ListItemIcon>
                                                <ListItemText primary="Deployed in" secondary={<span>{data && data.app.deploymentTime && data && <>{formatTime(data.app.deploymentTime)}</>}</span>} />
                                            </ListItem>}
                                        </Grid>}
                                        <Grid item xs={12} md={4} lg={4}>

                                        </Grid>


                                        <Grid item xs={4}>
                                            <Typography sx={{ my: 1 }}>
                                                Type
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography>
                                                {data.app.imageName.includes("mysql") && <img style={{ width: 100, height: 50 }} src={"https://upload.wikimedia.org/wikipedia/fr/6/62/MySQL.svg"} />}
                                                {data.app.imageName.includes("postgres") && <img style={{ width: 100, height: 50 }} src={"https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg"} />}
                                                {data.app.imageName.includes("mongo") && <img style={{ width: 100, height: 50 }} src={"https://upload.wikimedia.org/wikipedia/en/5/5a/MongoDB_Fores-Green.svg"} />}
                                                {data.app.imageName.includes("maria") && <img style={{ width: 100, height: 50 }} src={"https://upload.wikimedia.org/wikipedia/commons/c/ca/MariaDB_colour_logo.svg"} />}
                                                {data.app.imageName.includes("redis") && <img style={{ width: 100, height: 50 }} src={"https://upload.wikimedia.org/wikipedia/commons/6/64/Logo-redis.svg"} />}

                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography>
                                                Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography sx={{ fontWeight: "normal", color: "grey", my: 0.5 }} variant='subtitle2'>
                                                {match.params.id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTooltip title={"Click to copy Name"}>
                                                <IconButton onClick={() => handleCopy(match.params.id)}><MdContentCopy /></IconButton>
                                            </CustomTooltip>
                                        </Grid>


                                        <Grid item xs={4}>
                                            <Typography>
                                                Server IP
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography sx={{ fontWeight: "normal", color: "grey", my: 0.5 }} variant='subtitle2'>
                                                {urldata && urldata.getElbUrl.elbUrl}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTooltip title={"Click to copy Server IP"}>
                                                <IconButton onClick={() => handleCopy(urldata && urldata.getElbUrl.elbUrl)}><MdContentCopy /></IconButton>
                                            </CustomTooltip>
                                        </Grid>


                                        <Grid item xs={4}>
                                            <Typography sx={{ mb: 2 }}>
                                                Port
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography sx={{ fontWeight: "normal", color: "grey", my: 0.5 }} variant='subtitle2'>
                                                {data.app.config.definition.services[0].internal_port}
                                            </Typography>
                                        </Grid>


                                        {!data.app.imageName.includes("redis") && <Grid item xs={4}>
                                            <Typography>
                                                Root User Name
                                            </Typography>
                                        </Grid>}
                                        {!data.app.imageName.includes("redis") && <Grid item xs={7}>
                                            {data && <Typography sx={{ fontWeight: "normal", color: "grey", my: 0.5 }} variant='subtitle2'>
                                                {showUserName ? <>
                                                    {data.app.imageName.includes("mysql") && "root"}
                                                    {data.app.imageName.includes("maria") && "root"}
                                                    {data.app.imageName.includes("mongo") && JSON.parse(data.app.envArgs)?.MONGO_INITDB_ROOT_USERNAME}
                                                    {data.app.imageName.includes("postgres") && "postgres"}
                                                </> : "****************"}

                                            </Typography>}
                                        </Grid>}
                                        {!data.app.imageName.includes("redis") && <Grid item xs={1}>

                                            <CustomTooltip title={showUserName ? "Click to hide Password" : "Click to show Password"}>
                                                <IconButton onClick={() => setshowUserName(!showUserName)}> {showUserName ? <VisibilityOff /> : <Visibility />}</IconButton>
                                            </CustomTooltip>
                                        </Grid>}

                                        <Grid item xs={4}>
                                            <Typography>
                                                Root Password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>

                                            {data && <Typography sx={{ fontWeight: "normal", color: "grey", my: 0.5 }} variant='subtitle2'>

                                                {showPassword ? <>{getDatabasePassword(data)}</> : "****************"}
                                            </Typography>}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomTooltip title={showPassword ? "Click to hide Password" : "Click to show Password"}>
                                                <IconButton onClick={() => setshowPassword(!showPassword)}> {showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                            </CustomTooltip>
                                        </Grid>



                                        {/* <Grid item xs={4}>
                                        <Typography>
                                            Volume
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography sx={{ fontWeight: "normal",color:"grey", my: 0.5 }} variant='subtitle2'>
                                            2Gb
                                        </Typography>
                                    </Grid> */}




                                    </Grid>
                                </Paper>}
                            </Box>


                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ my: 4 }}>
                                {data && data.app.status === "Active" ?


                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="400px" width="300px" xmlns="http://www.w3.org/2000/svg">
                                        <path style={{ filter: "drop-shadow(rgba(0, 204, 102, 0.5) 0px 0px 0.7px)", color: "#00cc66" }} d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1"></path>
                                        <path style={{ filter: "drop-shadow(rgba(0, 204, 102, 0.5) 0px 0px 0.7px)", color: "#00cc66" }} d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905"></path>
                                        <path style={{ color: "#008800" }} d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514"></path>

                                    </svg>


                                    : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="400px" width="300px" xmlns="http://www.w3.org/2000/svg">
                                        <path style={{ filter: "drop-shadow(rgba(190, 190, 190, 0.5) 0px 0px 0.7px)", color: "rgb(190, 190, 190)" }} d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1"></path>
                                        <path style={{ filter: "drop-shadow(rgba(190, 190, 190, 0.5) 0px 0px 0.7px)", color: "rgb(190, 190, 190)" }} d="M2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.51 4.51 0 0 0-4.815 1.843A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777C2.875 8.755 2 8.007 2 7m6.257 3.998L8 11c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V10c0 1.007.875 1.755 1.904 2.223C4.978 12.711 6.427 13 8 13h.027a4.55 4.55 0 0 1 .23-2.002m-.002 3L8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-1.3-1.905"></path>
                                        <path style={{ color: "rgb(140, 140, 140)" }} d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708">
                                        </path>
                                    </svg>}

                            </Box>
                        </Grid>

                    </Grid>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box sx={{ m: 2 }}>
                        <FolderList isDatabase={true} />
                    </Box>

                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box sx={{ m: 2 }}>
                        <Dialog
                            open={open}
                            fullWidth={true}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: "12px",
                                    border: "solid 1px gray",
                                    boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                                }
                            }}
                            onClose={() => setopen(false)}
                            maxWidth={"xs"}
                        >
                            <DeleteApp appId={match.params.id} closeDialog={handleDeleteDialog} />
                        </Dialog>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >

                            <Grid item>
                                <Typography variant='h6' color={"error"} sx={{ fontWeight: "bold" }}>
                                    Danger Zone
                                </Typography>
                                <Typography variant="body2" gutterBottom sx={{ color: "grey" }}>
                                    Deleting your Database and its Regions is irreversible.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button closeDialog={() => setopen(false)} onClick={() => setopen(true)} color="error">Delete this database</Button></Grid>
                        </Grid>

                    </Box>
                </TabPanel>
            </Container>
        </Box>
    )
}
