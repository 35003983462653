import { useLazyQuery } from '@apollo/client';
import { CheckCircle, OpenInNew } from '@mui/icons-material';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Skeleton, TextField, Typography, Zoom } from '@mui/material';
import axios from 'axios';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import FileUploader from '../../../New folder/FileUploader';
import EmptyPageRenderCustom from '../../NewPages/EmptyPageRenderCustom';
import Nav, { USER_DATA } from '../../Settings/Nav';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import UserDetailsTooltip from '../UserDetailsTooltip';
import { encodeAes } from '../../Settings/UserLevelPAT';
import { decodeAes } from '../../Deployments/Services/GithubTimeline';

export default function CostMonitoring() {
    const superb = require('superb')
    const [appName, setappName] = useState("");
    const [open, setopen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [beforeTime, setbeforeTime] = React.useState("")
    const { enqueueSnackbar } = useSnackbar();
    const [time, setTime] = React.useState("")
    const [startLoading, setstartLoading] = React.useState(false);
    const [defaultOrg, setdefaultOrg] = useState("");
    const [selectedUser, setselectedUser] = useState(null);
    const [gcpUrl, setgcpUrl] = useState("");
    const [selectedCloud, setselectedCloud] = useState("AWS");
    const [slackNotificationValue, setslackNotificationValue] = useState("");
    const [showSlackGuide, setshowSlackGuide] = useState(false);
    const [awsCredentials, setAwsCredentials] = useState({
        AWS_ACCOUNT_NAME_ENV_VARIABLE: 'User',
        AWS_ACCESS_KEY_ID: '',
        AWS_SECRET_ACCESS_KEY: '',
        REGION: '*',
    });

    // Handle input changes
    const handleInputChange = (key) => (event) => {
        setAwsCredentials({
            ...awsCredentials,
            [key]: event.target.value,
        });
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleResetAll = () => {
        setappName("")
        setstartLoading(false)
    }


    const [enableWindow, setenableWindow] = useState(false);
    const [isloading, setisloading] = useState(false);

    const handleSubmit = () => {
        postData()

    }

    var CryptoJS = require("crypto-js");
    function encodeAesForPlatus(plaintText) {
        var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
        var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        encryptedData = encryptedData.ciphertext.toString(CryptoJS.enc.Base64);

        return encryptedData

    }


    // var enconfig = encodeAesForPlatus(`{
    //     "aws": [
    //         {
    //             "name": "${awsCredentials.AWS_ACCOUNT_NAME_ENV_VARIABLE}",
    //             "profile": "default",
    //             "source": "",
    //             "aws_access_key_id": "${awsCredentials.AWS_ACCESS_KEY_ID}",
    //             "aws_secret_access_key": "${awsCredentials.AWS_SECRET_ACCESS_KEY}",
    //             "region": "*"
    //         }
    //     ],
    //     "slack": {
    //         "webhook": "${slackNotificationValue}"
    //     }
    // }`)

    const checkButtonState = () => {
        const storedTime = localStorage.getItem('waitforplatus');
        if (storedTime) {
            const deployedAt = new Date(storedTime);
            const enableTime = deployedAt.getTime() + 90 * 1000; // 90 seconds in milliseconds
            const currentTime = new Date().getTime();
            const remainingTime = enableTime - currentTime;

            if (remainingTime > 0) {
                setIsButtonDisabled(true);
                setMessage(`Resolving DNS, dashboard will be enabled in ${Math.ceil(remainingTime / 1000)} seconds`);

                const timeoutId = setTimeout(() => {
                    setIsButtonDisabled(false);
                    setMessage('');
                    localStorage.removeItem('waitforplatus');
                }, remainingTime);

                // Cleanup function to clear timeout if the component unmounts
                return () => clearTimeout(timeoutId);
            } else {
                setIsButtonDisabled(false);
                setMessage('');
                localStorage.removeItem('waitforplatus');
            }
        } else {
            setIsButtonDisabled(false);
            setMessage('');
        }
    };


    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const postData = async () => {
        const url = `https://platus.clb2.nifetency.com/api/update_config?token=${localStorage.getItem("token")}`;

        var config = encodeAesForPlatus(`[[aws]]
            name = "${awsCredentials.AWS_ACCOUNT_NAME_ENV_VARIABLE}"
            profile = "default"
            source = ""
            accesskey = "${awsCredentials.AWS_ACCESS_KEY_ID}"
            accesstoken = "${awsCredentials.AWS_SECRET_ACCESS_KEY}"
            region = "*"
            [slack]
            webhook = "${slackNotificationValue}"
            `)

        const data = {
            config: config,
            emailid: email
        };

        try {
            const response = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });


            localStorage.setItem('waitforplatus', new Date().toISOString());
            setIsButtonDisabled(true);
            setMessage('Button will be enabled shortly...');
        



            setenableWindow(false);
            setisDashboardActive(true)
            enqueueSnackbar("Enabled!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })


            setTimeout(() => checkButtonState(), 100);

            setgcpUrl("");

        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar("Something went wrong!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }

    };

    const handleDelete = () => {
        postDelete()

    }
    const postDelete = async () => {
        const url = 'https://platus.clb2.nifetency.com/user_delete/' + email;

        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });
            setenableWindow(true);
            setisDashboardActive(false)
            enqueueSnackbar("Removed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })


            setopen(false)
            setgcpUrl("");
        } catch (error) {
            console.error('Error:', error);
            enqueueSnackbar("Something went wrong!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    };


    const [email, setemail] = useState(null);
    const [isDashboardActive, setisDashboardActive] = useState(false);
    const [fetchUserDetails] = useLazyQuery(USER_DATA, {
        onCompleted: (data) => {
            if (data && data.getUserById.email) {
                setselectedUser(data.getUserById)
                setemail(data.getUserById.email)
                var fullName = data.getUserById.firstName + " " + data.getUserById.lastName
                setAwsCredentials({
                    ...awsCredentials,
                    AWS_ACCOUNT_NAME_ENV_VARIABLE: fullName.replace(/[^a-zA-Z0-9]/g, " "),
                });

                handleCheckCostmonetory(data.getUserById.email)
            }
        }

    });

    const handleCheckCostmonetory = async (email) => {
        // Start by setting the loading state to true.
        setisloading(true);

        try {
            // Make a POST request to the specified URL with the given email.
            const response = await fetch(
                "https://platus.clb2.nifetency.com/api/set_config",
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify({ emailid: email }), // Send the email in the request body.
                }
            );

            // Check if the response is not okay, indicating a failed request.
            if (!response.ok) {
                throw new Error("Failed to send email. Please try again.");
            }
            // Parse the response as JSON to access the content.
            const data = await response.json();
            // Set the dashboard active state based on the 'exists' property in the response.
            if (data.exists === "false") {

                setisDashboardActive(false); // Only set active if 'exists' is true.
            } else {
                setisDashboardActive(true); // Optionally, handle the 'false' case.
            }
        } catch (err) {
            console.error("Error:", err.message); // Log any errors that occur during the fetch or processing.
        } finally {
            // Regardless of success or error, set loading to false to stop showing the loader.
            setisloading(false);
        }
    };


    useEffect(() => {
        fetchUserDetails();
        const intervalId = setInterval(() => {
            checkButtonState();
        }, 1000); // Update every 1 second

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    if (isloading) return (<>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
    </>)

    return (
        <div style={{
            flexGrow: 1,
            overflow: 'auto',
        }}>
            <Nav />

            <Container maxWidth={false}>



                {(isDashboardActive || enableWindow) && <Typography variant='body2' sx={{ fontWeight: "lighter", mb: 1 }} color={"primary"}>Navigating Your Deployment Budgets with Precision</Typography>}


                {isDashboardActive && <Paper variant='outlined' sx={{ p: 5, borderRadius: "12px" }} >
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >

                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <Typography variant='h5' sx={{ my: 2 }} textAlign={"center"}>
                            <AnimatePresence exitBeforeEnter>
                                <motion.div
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    Dashboard is Active
                                </motion.div>
                            </AnimatePresence>
                        </Typography>

                        <Button disabled={isButtonDisabled} variant='contained' endIcon={<OpenInNew />}
                            onClick={() => {
                                window.open(`https://platus.clb2.nifetency.com?token=${localStorage.getItem("token") && localStorage.getItem("token")}`, '_blank')
                            }}> open</Button>

                        {message && <Typography sx={{mt:2}} variant="body1">{message}</Typography>}


                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ m: 1, p: 1 }}
                        >

                            <UserDetailsTooltip data={selectedUser && selectedUser}>

                            </UserDetailsTooltip>

                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Button startIcon={<MdOutlineDeleteOutline />} sx={{ p: 2 }} onClick={() => setopen(true)} color="error">Remove dashboard</Button>
                        </Grid>

                        <Dialog
                            open={open}
                            fullWidth={true}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    borderRadius: "12px",
                                    border: "solid 1px gray",
                                    boxShadow: (false) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                                }
                            }}
                            onClose={() => setopen(false)}
                            maxWidth={"xs"}
                        >
                            <DialogTitle> Are you sure?</DialogTitle>
                            <DialogContent>
                                <Typography component={"p"} sx={{ color: "grey" }}>  Deleting this dashboard cannot be revert back, please be certain.</Typography>
                            </DialogContent>

                            <DialogActions>
                                <Button disableElevation onClick={() => handleDelete()} fullWidth variant='contained' color='error'>Yes, Delete forver</Button>
                                <Button onClick={() => setopen(false)} fullWidth color='primary'>No, cancel</Button>
                            </DialogActions>
                        </Dialog>





                    </Grid>
                </Paper>}


                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"

                >


                    {enableWindow && !isDashboardActive &&


                        <Grid column container justifyContent="center" alignItems="flex-start" spacing={2}>
                            {/* <Grid item xs={12} sm={2} md={2}>
                                <img src="http://platus.clb2.nifetency.com/assets/img/others/PlatusLight.svg"/>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Paper variant='outlined' sx={{ minHeight: "500px", p: 3, mt: 1, mb: 5, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    {selectedCloud === "AWS" && <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >

                                        <img style={{ width: 60, height: 40 }} src={"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"} />
                                        <span style={{ marginTop: 5, fontSize: "12px" }}>Amazon Web <br />Services</span>
                                        <Zoom in={selectedCloud === "AWS" ? true : false}>
                                            <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                        </Zoom>

                                    </Grid>}

                                    {selectedCloud === "GCP" && <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >

                                        <img style={{ width: 80, height: 40 }} src={"https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg"} />
                                        <span style={{ marginTop: 5, fontSize: "12px" }}>Google Cloud <br />Platform</span>
                                        <Zoom in={selectedCloud === "GCP" ? true : false}>
                                            <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                        </Zoom>

                                    </Grid>}

                                    {selectedCloud === "Azure" && <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >

                                        <img style={{ width: 80, height: 40, marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"} />
                                        <span style={{ marginTop: 5, fontSize: "12px" }}>Microsoft Azure<br /> Cloud</span>
                                        <Zoom in={selectedCloud === "Azure" ? true : false}>
                                            <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                        </Zoom>

                                    </Grid>}

                                    {selectedCloud === "Slack" && <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >

                                        <img style={{ width: 80, height: "auto", marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/b/b9/Slack_Technologies_Logo.svg"} />
                                        <span style={{ marginTop: 5, fontSize: "12px" }}>Slack notification</span>
                                        <Zoom in={selectedCloud === "Slack" ? true : false}>
                                            <IconButton sx={{ mt: 1, p: 0 }}> <CheckCircle color='primary' /> </IconButton>
                                        </Zoom>

                                    </Grid>}


                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant='h6' sx={{ fontWeight: "bold" }}>
                                        {selectedCloud === "AWS" && "Add details"}
                                        {selectedCloud === "GCP" && "Upload file"}
                                        {selectedCloud === "Slack" && "Add slack notification"}
                                        {selectedCloud === "Azure" && "Coming Soon"}  </Typography>
                                    {selectedCloud === "AWS" && <Box>
                                        <Typography sx={{ mb: 2, color: "grey" }} variant='body2'>Please add details of your {selectedCloud && selectedCloud} account to enable cost monitoring.</Typography>
                                        <TextField
                                            size='small'
                                            label="AWS ACCOUNT NAME"
                                            fullWidth
                                            value={awsCredentials.AWS_ACCOUNT_NAME_ENV_VARIABLE}
                                            onChange={handleInputChange('AWS_ACCOUNT_NAME_ENV_VARIABLE')}
                                            margin="normal"
                                        />
                                        <TextField
                                            size='small'
                                            required
                                            label="AWS ACCESS KEY ID"
                                            fullWidth
                                            value={awsCredentials.AWS_ACCESS_KEY_ID}
                                            onChange={handleInputChange('AWS_ACCESS_KEY_ID')}
                                            margin="normal"
                                        />
                                        <TextField
                                            size='small'
                                            required
                                            label="AWS SECRET ACCESS KEY"
                                            fullWidth
                                            type='password'
                                            value={awsCredentials.AWS_SECRET_ACCESS_KEY}
                                            onChange={handleInputChange('AWS_SECRET_ACCESS_KEY')}
                                            margin="normal"
                                        />
                                    </Box>}
                                    {selectedCloud === "GCP" && <Box>

                                        {gcpUrl ? <div>

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >

                                                <CheckCircle style={{ color: "green", fontSize: "40px" }} />
                                                <Typography sx={{ py: 1 }}>File uploaded successfully</Typography> </Grid>
                                        </div> :
                                            <Box>
                                                <Typography variant='body2' sx={{ py: 1 }}>Create and download the Service Account as JSON from the GCP console and upload below</Typography>
                                                <FileUploader setgcpUrl={setgcpUrl} maxSizeInBytes={104857600} />
                                            </Box>}
                                    </Box>}
                                    {selectedCloud === "Slack" && <Box>
                                        <div>
                                            <br />
                                            <TextField fullWidth size='small' onChange={(e) => setslackNotificationValue(e.target.value)} value={slackNotificationValue} label="Enter Slack webhook" />


                                            {showSlackGuide ? <Typography onClick={() => setshowSlackGuide(false)} sx={{ textAlign: "center", m: 1, fontWeight: "bold", color: "blue" }}>Click here to hide</Typography> :
                                                <Typography onClick={() => setshowSlackGuide(true)} sx={{ fontSize: "12px", mt: 1 }}>How to get slack webhook url? <b style={{ color: "blue" }}>Show steps</b></Typography>}

                                            {showSlackGuide && <div>
                                                <Typography paragraph>
                                                    To receive Slack notifications, you need to set up an incoming webhook. Follow these steps:
                                                </Typography>

                                                <ol>
                                                    <li>
                                                        <strong>Sign in to Slack:</strong> If you don't have an account, create one on{' '}
                                                        <a href="https://slack.com/get-started" target="_blank" rel="noopener noreferrer">
                                                            Slack's website
                                                        </a>
                                                        .
                                                    </li>
                                                    <li>
                                                        <strong>Create a Slack App:</strong> Go to the{' '}
                                                        <a href="https://api.slack.com/apps" target="_blank" rel="noopener noreferrer">
                                                            Slack App Management
                                                        </a>{' '}
                                                        page and click on "Create New App."
                                                    </li>
                                                    <li>
                                                        <strong>Fill in App Details:</strong> Enter a name for your app, select your workspace, and click on "Create App."
                                                    </li>
                                                    <li>
                                                        <strong>Set Up Incoming Webhooks:</strong> In the left sidebar, navigate to "Incoming Webhooks" under the "Features" section.
                                                    </li>
                                                    <li>
                                                        <strong>Activate Incoming Webhooks:</strong> Toggle the switch to activate incoming webhooks.
                                                    </li>
                                                    <li>
                                                        <strong>Add a New Webhook:</strong> Scroll down to the "Webhooks" section and click on "Add New Webhook to Workspace."
                                                    </li>
                                                    <li>
                                                        <strong>Authorize the App:</strong> Authorize your app to access the selected workspace and choose a channel.
                                                    </li>
                                                    <li>
                                                        <strong>Copy the Webhook URL:</strong> After authorizing, you'll get a webhook URL. Copy it and paste here.
                                                    </li>
                                                </ol>
                                            </div>}
                                        </div>
                                    </Box>}
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                {selectedCloud !== "AWS" && <Paper variant='outlined' onClick={() => setselectedCloud("AWS")} sx={{ minHeight: "50px", p: 3, cursor: "pointer", mt: 1, mb: 2, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >


                                        <img style={{ width: 50, height: 40 }} src={"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"} /> <br />
                                        {(awsCredentials.REGION === "" || awsCredentials.AWS_ACCESS_KEY_ID === "" || awsCredentials.AWS_SECRET_ACCESS_KEY === "" || awsCredentials.AWS_ACCOUNT_NAME_ENV_VARIABLE === "") ? "Add for AWS" :
                                            <div style={{ marginTop: "5px" }}><IconButton sx={{ p: 0 }}>
                                                <CheckCircle style={{ color: "green" }} />
                                            </IconButton> Added</div>}
                                    </Grid>
                                </Paper>}
                                {selectedCloud !== "GCP" && <Paper variant='outlined' onClick={() => setselectedCloud("GCP")} sx={{ minHeight: "50px", p: 3, cursor: "pointer", mt: 1, mb: 2, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img style={{ width: 80, height: 40 }} src={"https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg"} /> <br />
                                        {(gcpUrl) ?
                                            <div style={{ marginTop: "5px" }}><IconButton sx={{ p: 0 }}>
                                                <CheckCircle style={{ color: "green" }} />
                                            </IconButton> Added</div> : "Add for GCP"}
                                    </Grid>
                                </Paper>}
                                {selectedCloud !== "Azure" && <Paper variant='outlined' onClick={() => setselectedCloud("Azure")} sx={{ minHeight: "50px", p: 3, cursor: "pointer", mt: 1, mb: 2, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img style={{ width: 40, height: 40, marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"} /> <br />
                                        Add for Azure
                                    </Grid>
                                </Paper>}

                                {selectedCloud !== "Slack" && <Paper variant='outlined' onClick={() => setselectedCloud("Slack")} sx={{ minHeight: "50px", p: 3, cursor: "pointer", mt: 1, mb: 2, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img style={{ width: 100, height: "auto", marginRight: "5px" }} src={"https://upload.wikimedia.org/wikipedia/commons/b/b9/Slack_Technologies_Logo.svg"} /> <br />
                                        {(slackNotificationValue) ?
                                            <div style={{ marginTop: "5px" }}><IconButton sx={{ p: 0 }}>
                                                <CheckCircle style={{ color: "green" }} />
                                            </IconButton> Added</div> : "Add notification"}

                                    </Grid>
                                </Paper>}

                                <Paper variant='outlined' sx={{ minHeight: "50px", px: 3, cursor: "pointer", mt: 1, mb: 1, borderRadius: "12px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}>
                                    <Button sx={{ mt: 0.8 }} fullWidth onClick={() => { setenableWindow(false); setgcpUrl(""); }} startIcon={<RiArrowGoBackFill />}>Go back</Button>
                                </Paper>

                                <Button sx={{ minHeight: "50px", borderRadius: "12px", my: 1, boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }}
                                    // disabled={awsCredentials.REGION === "" || awsCredentials.AWS_ACCESS_KEY_ID === "" || awsCredentials.AWS_SECRET_ACCESS_KEY === "" || awsCredentials.AWS_ACCOUNT_NAME_ENV_VARIABLE === ""}
                                    fullWidth variant="contained" color="primary" onClick={handleSubmit}>
                                    enable
                                </Button>
                            </Grid>
                        </Grid>}
                </Grid>
                <Box>

                    {!enableWindow && !isDashboardActive && <EmptyPageRenderCustom
                        mainTitle={<>Enable cost monitoring</>}
                        primaryButtonText={"Enable now"}
                        primaryButtonAction={() => setenableWindow(true)}
                        secondaryTitle="Navigating Your Deployment Budgets with Precision."
                        learnMoreLink="https://docs.nife.io/docs/Cloud-Cost-Monitoring/Cost-Monitoring"
                        iframeLink="https://www.youtube.com/embed/EY-Qdy9saHE?si=zJDd-UfMujuwHEM_"
                        imageListTitle={"Supported clouds"}
                        imageList={[{ "name": "AWS", "image": "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg" }, { "name": "GCP", "image": "https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg" }, { "name": "Azure", "image": "https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg" }]}
                    />}
                </Box>
            </Container>


        </ div>
    )
}
