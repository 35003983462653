import { createTheme } from '@mui/material/styles'
import { CONFIG_APP_PRIMARY_DARK_COLOR, CONFIG_APP_PRIMARY_LIGHT_COLOR } from '../src/configApp';


const currentMode = localStorage.getItem("mode");
export const theme = createTheme(
  {
    palette:
    {
      mode: currentMode ? currentMode : "light",
      primary: { main: currentMode === "dark" ? CONFIG_APP_PRIMARY_DARK_COLOR : CONFIG_APP_PRIMARY_LIGHT_COLOR}, //
      secondary: { main: "#ffcc80" },
    },
    typography: {
      fontFamily: "Rubik",
      color:"#111",
    },
    shape: {
      borderRadius: 4,
      borderBottomRightRadius: 0,
    },
  }
);

