import { gql } from '@apollo/client';
import { Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { FaExpand } from 'react-icons/fa';
import ExpandMetrics from '../Deployments/Metrics/ExpandMetrics';
import { CONFIG_APP_METRICS_BASE_URL } from '../../configApp';

// Define GraphQL query
export const ORG_SLUG_FETCH = gql`
  query OrgSlugQuery($name: String!) {
    app(name: $name) {
      id
      organization {
        id
        name
        slug
      }
    }
  }
`;

const list = [
  {
    "type": "cpu",
    "title": "total CPU Usage ",
    "desc": "Total CPU usage percentage across all processes and containers.",
    "panelId": 1
  },
  {
    "type": "cpu",
    "title": "CPU Usage per pod",
    "desc": "CPU usage per pod shows the percentage of CPU resources used by each pod.",
    "panelId": 13
  },
  {
    "type": "cpu",
    "title": "CPU Usage avg per pod",
    "desc": "Average CPU usage percentage per pod over a specified period.",
    "panelId": 8
  },

  {
    "type": "memory",
    "title": "Total Memory Ussage",
    "desc": "Total amount of memory percentage across all processes and containers.",
    "panelId": 2
  },
  {
    "type": "memory",
    "title": "Memory Usage per pod",
    "desc": "Memory usage by each individual pod, measured in bytes.",
    "panelId": 14
  },
  {
    "type": "memory",
    "title": "Memory Usage avg per pod",
    "desc": "Average memory usage per pod over a specified period, measured in bytes.",
    "panelId": 9
  },
  {
    "type": "others",
    "title": "Number of pod",
    "desc": "Shows pod count trends over time, highlighting scaling events and fluctuations in the cluster's activity.",
    "panelId": 7
  }
]


// Define Iframe component
const IframeComponent = ({org, panelId, timeRange,title, desc }) => {
  const theme = useTheme();
  const { from, to } = timeRange;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; //http://a177eee961d034c5cb46016301185a94-1450881619.ap-southeast-1.elb.amazonaws.com

 const iframeSrc = `${CONFIG_APP_METRICS_BASE_URL}/d-solo/nife-pod/kubernetes-app-metrics-namespace?orgId=1&var-namespace=${org.toString().toLowerCase()}&var-container=test-kickass-app-3018&from=${from}&to=${to}&theme=${theme ? theme.palette.mode : "light"}&panelId=${panelId}`;
 


  return (
     <>
      {open ?
        <>
          <ExpandMetrics
            title={title}
            desc={desc}
            open={open}
            onClose={handleClose}
          >
            <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="80%" title={`panel-${panelId}`}></iframe>
          </ExpandMetrics>

        </> :
        <>
          <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant='h6' sx={{ textTransform: "capitalize", mt: 1 }}>{title}</Typography>

            <IconButton variant="outlined" onClick={handleClickOpen}>
              <FaExpand />
            </IconButton>
          </Grid>


          <Typography style={{ color: "grey", marginBottom: "10px" }} variant='body2'>{desc}</Typography>

          <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="200" title={`panel-${panelId}`}></iframe>


        </>
      }

    </>
  );
};

// Time range options
const timeOptions = [
  { label: 'Last 30 minutes', value: 30 * 60 * 1000 },
  { label: 'Last 1 hour', value: 60 * 60 * 1000 },
  { label: 'Last 6 hours', value: 6 * 60 * 60 * 1000 },
  { label: 'Last 12 hours', value: 12 * 60 * 60 * 1000 },
  { label: 'Last 24 hours', value: 24 * 60 * 60 * 1000 },
  { label: 'Last 7 days', value: 7 * 24 * 60 * 60 * 1000 },
  { label: 'Last 30 days', value: 30 * 24 * 60 * 60 * 1000 },
];

// Define GrafanaOrgDisplay component
export default function GrafanaOrgDisplay({org}) {

//   const [org, setOrgId] = useState(null);
  const [filterValue, setfilterValue] = useState("cpu");
  const [timeRange, setTimeRange] = useState({ from: Date.now() - (30 * 60 * 1000), to: Date.now() });

//   const [fetchOrgSlug] = useLazyQuery(ORG_SLUG_FETCH, {
//     variables: { name: match.params.id },
//     onCompleted: (data) => {
//       if (data && data.app.organization) {
//         setOrgId(data.app.organization.slug);
//       }
//     },
//   });

  useEffect(() => {
    // fetchOrgSlug();
  }, [org]);

console.log(org.slug)
  // Handle time range change
  const handleTimeRangeChange = (event) => {
    const selectedValue = event.target.value;
    setTimeRange({
      from: Date.now() - selectedValue,
      to: Date.now(),
    });
  };

  if (org && org.slug === null) {
    return 'Fetching...';
  }

  return (
    <div>

      <Grid container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5" sx={{ pb:2 }} component="h1" >Organization System Performance</Typography>
        </Grid>
        <Grid item>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Time Range</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              label="Time Range"
              value={timeRange.to - timeRange.from}
              onChange={handleTimeRangeChange}
            >
              {timeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ mb: 2 }}
      >
        <Grid item>
          {org.slug === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "" ? "filled" : "outlined"} label="Show All" color="primary" onClick={() => setfilterValue("")} />}
        </Grid>
        <Grid item>
          {org.slug === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "cpu" ? "filled" : "outlined"} label="CPU (3 Charts) " color="primary" onClick={() => setfilterValue("cpu")} />}
        </Grid>
        <Grid item>
          {org.slug === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "memory" ? "filled" : "outlined"} label="Memory (3 Charts) " color="primary" onClick={() => setfilterValue("memory")} />}
        </Grid>
        <Grid item>
          {org.slug === null ? <Skeleton variant='rounded' width={90} height={30} style={{ borderRadius: "24px" }} /> : <Chip variant={filterValue === "others" ? "filled" : "outlined"} label="Others (1 Charts) " color="primary" onClick={() => setfilterValue("others")} />}
        </Grid>

      </Grid>

      <Grid container spacing={2}>
        {org.slug && list.map((e) => {
          if (e.type === filterValue || filterValue === "") {
            return (
              <Grid item xs={12} sm={12} md={12}>
                <IframeComponent
                  key={e.panelId}
                  org={org.slug}
                  title={e.title}
                  desc={e.desc}
                  panelId={e.panelId}
                  timeRange={timeRange}
                />
              </Grid>
            )
          }
        })}

      </Grid>
    </div>
  );
}
