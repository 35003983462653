import {
  ApolloClient, ApolloProvider, createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Button, Grid } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from 'notistack';
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from 'react-error-boundary';
import Favicon from 'react-favicon';
import { Provider } from "react-redux";
import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import App from './App';
import LogTrigger from './LogTrigger';
import store from "./store";
import { theme } from "./theme";
import * as Sentry from "@sentry/react";
import { CONFIG_APP_BASE_API } from './configApp';

function ErrorFallback({ error, resetErrorBoundary }) {
  let history = useHistory();
  const location = useLocation();
  const el = document.querySelector(".logoApp");
  if (el) {
    el.remove();
  }
  LogTrigger(error.message, "error", location.pathname, "Not Found")
  return (

    <Grid container spacing={0} style={{ position: "absolute", top: "40%", left: "10%", width: "50%" }}>
      <Grid item xs={12} md={8}>
        <p style={{ margin: "0px", fontFamily: "Rubik" }}>We are still sharpening our kNifes!</p>
        <p style={{ marginBottom: "10px", fontSize: "14px", color: "grey" }}> Oops, Something Went Wrong </p>


      </Grid>

      <Grid item xs={8}>
        <Button size="small" style={{ marginRight: "10px", marginTop: "10px" }} variant="contained" onClick={resetErrorBoundary}>Try again</Button>
        <Button size="small" style={{ marginRight: "10px", marginTop: "10px" }} variant="outlined" onClick={() => history.goBack()}>Go back</Button>
      </Grid>
    </Grid>

  )
}

Sentry.init({
  dsn: "https://a30dbad4456a81bdb2ac679ce8169230@sentry.clb2.nifetency.com/6",
  
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://launch.nife.io","https://launchdev.nife.io"],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const httpLink = createHttpLink({
  uri: `${CONFIG_APP_BASE_API}/graphql`
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


ReactDOM.render(
  <>
    <Favicon url="favicon.png" />
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
             
                <SnackbarProvider>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <App />
                  </ErrorBoundary>
                </SnackbarProvider>
            
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>

  </>,
  document.getElementById("root"))


