import { Delete, DeleteForever, MoreVert } from '@mui/icons-material'
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DELETE_SITE, SITES_LIST } from './SitesTable';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { MdDelete, MdOutlineReplay } from 'react-icons/md';
import { FiRepeat } from 'react-icons/fi';
import RevertSite from './Site/RevertSite';
import CreateNewSite from './CreateNewSite';

export default function SiteActions({ appName,orgId }) {
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [revertValue, setrevertValue] = useState(false)
    const [redeployValue, setredeployValue] = useState(false)
    const [openCreateNewSite, setopenCreateNewSite] = React.useState(false);
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    const role = localStorage.getItem("role")
    const { enqueueSnackbar } = useSnackbar();

const handleRevert = () => {
    setrevertValue(true)
}

const handleRedeploy = () => {
    setredeployValue(true)
    setopenCreateNewSite(true)
}
    const handleDelete = () => {
        deleteSite({ variables: { s3AppName: appName } })
        setOpenDeleteDialog(false);
    };

    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleClose = () => {
        setOpenDeleteDialog(false)
    }
    const [deleteSite] = useMutation(DELETE_SITE, {
        refetchQueries: [SITES_LIST],
        onError: (error) => {
            enqueueSnackbar(error.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            console.log(error)
        }
        ,
        onCompleted: (data) => {
            enqueueSnackbar("Removed!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    })
    return (
        <div>
            <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                maxWidth={"xs"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting your site is irreversible, please be certain.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button fullWidth disableElevation variant='contained' onClick={handleDelete} color="error" >
                        Delete Forever
                    </Button>
                </DialogActions>
            </Dialog>
            {revertValue && <RevertSite s3AppName={appName} id={"123"} isAction={true}/>}
            {redeployValue && openCreateNewSite && <CreateNewSite redeployOrg={orgId} redeploy={true} redeploySiteName={appName} hideButton={true} openCreateNewSite={openCreateNewSite} setopenCreateNewSite={setopenCreateNewSite} />}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {permissions[0].delete &&
                    <MenuItem color="error" onClick={() => {
                        handleCloseMenu();
                        setOpenDeleteDialog(true)
                    }}>
                        <ListItemIcon>
                            <MdDelete />
                        </ListItemIcon>
                        Delete site</MenuItem>}

                <MenuItem color="error" onClick={() => {
                    handleCloseMenu();
                    handleRevert();
                }}>
                 <ListItemIcon>
                            <MdOutlineReplay />
                        </ListItemIcon>
                        
                        Instant revert</MenuItem>

                <MenuItem color="error" onClick={() => {
                    handleCloseMenu();
                    handleRedeploy()
                }}>
                 <ListItemIcon>
                            <FiRepeat />
                        </ListItemIcon>
                        Redeploy</MenuItem>
            </Menu>

            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                disabled={role === "Viewer"}
                onClick={handleClickOpenMenu}
                size="large">
                <MoreVert />
            </IconButton>
        </div>
    )
}
