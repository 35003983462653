import { IconButton } from '@mui/material'
import React from 'react'
import { FaLock } from 'react-icons/fa'
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp'

export default function UpgradeToUnlock() {
  return (
    <div><IconButton sx={{my:1,}}>

    <FaLock style={{fill: CONFIG_APP_BASE_COMPANY === "nife" ? `linear-gradient(to right, #4dfce0, #a977f1)` : `linear-gradient(to right, #C59C9A, #D1A64D)
`}}/>
    </IconButton> Upgrade to Unlock</div>
  )
}
