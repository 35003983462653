import { gql, useLazyQuery, useQuery } from '@apollo/client';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import Nav from "../../Settings/Nav";
import Activity from '../Activity/Main';
import DeleteApp from "../AppSettings/DeleteApp";
import AppSettings from '../AppSettings/Main';
import Deploy from '../Deploy/Main';
import Logs from '../Logs/Main';
import Metrics from '../Metrics/Main';
import Services from '../Services/Main';
import "./OverviewGlobal.css";


import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { FaChartBar, FaTools } from "react-icons/fa";
import { RiFileListFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import CreateNewApp from "../../Home/CreateNewApp/Main";
import { HISTORY } from "../Activity/ActivityTable";

import { CloudOff } from '@mui/icons-material';
import { Dialog, Divider, ListItemIcon } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import ReactCountryFlag from 'react-country-flag';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiExpandVertical, BiSolidTimeFive } from 'react-icons/bi';
import { GoCheckCircleFill } from 'react-icons/go';
import { IoIosArrowForward, IoIosPeople, IoMdSettings } from 'react-icons/io';
import { MdOutlineTimelapse } from 'react-icons/md';
import { TiLocation } from 'react-icons/ti';
import UpgradePlan from '../../../Containers/UpgradePlan';
import LogTrigger from '../../../LogTrigger';
import GitHubTimeline from '../Services/GithubTimeline';
import ShowWorkload from './ShowWorkload';


export const APP_NAME_LIST = gql`
query{
  apps(type: "", first:62, region:"" , orgSlug:""){
    nodes{
      name
      deployed
      builtinType
    }
  }
}`

export const APP_DEPLOYED = gql`
query appDeployed($name:String){
  app(name: $name){
    id
    name
    status
    deployed
    }
  
}`


export const APPS_WORKLOAD = gql`
query AppSWorkloadQuery($name: String, $organiztionId: String){
  appsWorkload(name: $name, organiztionId: $organiztionId) {
    nodes {
      id
      name
      status
      deployed
      hostname
      appUrl
      version
      imageName
      port
      secretRegistryId
      instanceId
      dockerId
      hostId
      tenantId
      envArgs
      deployType
      containerId
      builtinType
      workloadManagementId
      WorkloadManagementName
      WorkloadManagementEndPoint
      organizationId
      organizationName
      subOrganizationId
      subOrganizationName
      businessUnitId
      businessUnitName
    }
  }
}`




const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  maintext: {
    color: theme.palette.text.secondary,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    paddingLeft: "5%",
    margin: "0px",
  },
  drawerPaper: {
    background: "linear-gradient(0deg,#4e73df 10%,#618aff 100%)",
    color: "#fff",
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'auto',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
  container: {
    paddingLeft: theme.spacing(0),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: "auto",
  },
  header: {
    borderRadius: "12px",
    margin: "10px 20px 20px 20px",
    boxShadow: "rgba(17, 12, 46, 0.05) 0px 48px 100px 0px",
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  activeText: {
    margin: 0,
    paddingLeft: 10,
    fontWeght: "bold",
  },
  inactiveText: {
    margin: 0,
    paddingLeft: 10,
    color: "#939393",
  },
  link: {
    display: 'flex',
    textDecoration: "none",
    color: '#5d86f9'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  headline: {
    fontSize: "18px",
    margin: "10px"
  },
  graphpaper: {
    padding: "10px",
    borderRadius: "12px",
    boxShadow: "0 .15rem 1.75rem 0 rgba(58,59,69,.15)",
  }
}));


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function WorkloadNavBar() {
  const match = useRouteMatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [fetchApps, { loading, data }] = useLazyQuery(APP_NAME_LIST);
  const {  data: datawl, refetch: wlrefetch } = useQuery(APP, { variables: { name: match.params.id } });
  const { data: appawldata } = useQuery(APPS_WORKLOAD, { variables: { name: match.params.id.substr(match.params.id.indexOf("-") + 1), organiztionId: datawl ? (datawl.app.organization && datawl.app.organization.id) : "" } });
  useEffect(() => {
    fetchApps();
    wlrefetch()
  }, [datawl, appawldata, data]);
  if (loading) return "Loading.."
  return (
    <div>


      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"

      >
        {datawl && datawl.app && datawl.app.workloadManagementId ?
          <ShowWorkload data={appawldata && appawldata} name={match.params.id} appdata={datawl && datawl.app} />
          : <p style={{ margin: "0px" }}>
            {"  "}{match.params.id}
          </p>}

        {data && data.apps.nodes && <Box>
          <IconButton
            sx={{ ml: 2 }}
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          ><BiExpandVertical /></IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 148 * 4.5,
                borderRadius: "12px",
              },
            }}
          >
            <MenuItem onClick={() => history.push("/apps")}>Apps</MenuItem>
            <Divider />
            {data && data.apps.nodes && data.apps.nodes.filter((e) => {
              return e.builtinType !== "DB" && e.builtinType !== "FinOps"
            }).map((option) => (
              <MenuItem key={option} selected={option.name === match.params.id}
                onClick={() => {
                  handleClose();
                  history.push(`/deployments/overview/${option.name}`);
                }}>
                <Tooltip title={option.deployed ? "App is deployed" : "App is not deployed"}>
                  <IconButton>{option.deployed ? <GoCheckCircleFill style={{ fill: "#7ac142" }} /> : <AiFillCloseCircle style={{ fill: "#bdbdbd" }} />}
                  </IconButton> </Tooltip>
                <Typography sx={{ textTransform: "capitalize", fontWeight: option.name === match.params.id ? "bold" : "normal", color: option.name === match.params.id ? "primary" : "undefined" }}>
                  {option.name}
                </Typography>

              </MenuItem>
            ))}
          </Menu>
        </Box>}



      </Grid>

    </div>
  )
}

export function Header(props) {
  const match = useRouteMatch();
  const appName = match.params.id
  const [value, setValue] = React.useState(0);
  const [repoUrl, setrepoUrl] = React.useState("");
  const { loading: loadAppDeployed, error: errAppDeployed, data: dataAppDeployed } = useQuery(APP_DEPLOYED, { variables: { name: match.params.id } });

  const { data: data, error: apperr } = useQuery(APP, {
    variables: {
        name: match.params.id
    },
    onCompleted: (data) => {
        if (data) {
            setrepoUrl((data.app.sourceUrl && data.app.sourceUrl.startsWith("https://github.com/")) ? data.app.sourceUrl.split("archive")[0] : "")
        }
    }
})

  useEffect(() => {

    if(props.wantGithubURL && repoUrl){
      props.setGithubUrl(repoUrl)
    }
    var tempval = match.url.split("/")

    if (tempval[2] == "overview") {
      setValue(0)
    }
    if (tempval[2] == "service") {
      setValue(1)
    }
    if (tempval[2] == "deploy") {
      if(repoUrl){
        setValue(3)
      }
      else{
           setValue(2)
      }
   
    }
    if (tempval[2] == "metrics") {
      if(repoUrl){
        setValue(4)
      }
      else{
           setValue(3)
      }
    }
    if (tempval[2] == "activity") {
      if(repoUrl){
        setValue(5)
      }
      else{
           setValue(4)
      }
    }
    if (tempval[2] == "logs") {
      if(repoUrl){
        setValue(6)
      }
      else{
           setValue(5)
      }
    }
    if (tempval[2] == "settings") {
      if(repoUrl){
        setValue(7)
      }
      else{
           setValue(6)
      }
    }

  }, [match]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  if (errAppDeployed) {
    LogTrigger(errAppDeployed.message, "error", window.location.href, "Overview")
  }

  if (dataAppDeployed) {
    LogTrigger("App overview loaded", "info", window.location.href, "Overview")
  }

  return <>
    <Nav />

    {dataAppDeployed && dataAppDeployed.app.deployed && <Grid container spacing={2} >
      <Grid item xs={12} lg={12}>


        <Tabs
          style={{ marginLeft: "20px" }}
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          allowScrollButtonsMobile>
          <Tab label="Overview" component={Link} to={`/deployments/overview/${appName}`} style={{ minWidth: "5px" }} />
          <Tab label="Configuration" component={Link} to={`/deployments/service/${appName}`} style={{ minWidth: "5px" }} />
          {repoUrl &&  <Tab label="Tools" component={Link} to={`/deployments/tools/${appName}`} style={{ minWidth: "5px" }} />}
          <Tab label="Scale" component={Link} to={`/deployments/deploy/${appName}`} style={{ minWidth: "5px" }} />
          <Tab label="Metrics" component={Link} to={`/deployments/metrics/${appName}`} style={{ minWidth: "5px" }} />
          <Tab label="Activity" component={Link} to={`/deployments/activity/${appName}`} style={{ minWidth: "5px" }} />
          <Tab label="Logs" component={Link} to={`/deployments/logs/${appName}`} style={{ minWidth: "5px" }} />
          <Tab label="Settings" component={Link} to={`/deployments/settings/${appName}`} style={{ minWidth: "5px" }} />
        </Tabs>



      </Grid>

    </Grid>}
  </>;
}

export const WL_APP_LIST = gql`
query WlQuery($workloadId: String!){
  getWorkloadMangementByWlId(workloadId: $workloadId){
    id
    environmentName
    environmentEndpoint
    organizationId
    subOrganizationId
    businessUnitId
    userId
    createdAt
    apps{
      nodes{
        id
        name
        status
        deployed
        hostname
        appUrl
        workloadManagementId
        config{
          definition
          build{
            image
            builder
            builtin
          } 
        }
        version
        regions{
          name
        }
        imageName
        port
        instanceId
        deployType
      }
     
    }
    
  }
}`


export const APP = gql`
query AppQuery($name: String!){
  app(name: $name){
    id
    name
    status
    deployed
    hostname
    appUrl
    deploymentTime
    buildTime
    replicas
    sourceUrl
    organization{
      id
      name
    }
    workloadManagementId
    secretRegistryId
    config{
      definition
      build{
        image
        builder
        builtin
      } 
    }
    version
    regions{
      name
    }
    backupRegions{
      name
    }
    imageName
    volumes{
      nodes{
        id
      }
    }
    userDetails {
      id
      email
      firstName
      lastName
      profileImageUrl 
      roleId
      userProfileCreated
     }
    envArgs
    port
    instanceId
    deployType
    createdAt
  }
}`
export function formatTime(input) {
  // Check if input ends with ms
  if (input.endsWith('ms')) {
    return 'Less than a minute';
  }

  // Use regular expressions to extract minutes and seconds
  const minuteMatch = input.match(/(\d+)m/);
  const secondMatch = input.match(/(\d+(?:\.\d+)?)s/);

  const minutes = minuteMatch ? parseInt(minuteMatch[1], 10) : 0;
  const seconds = secondMatch ? parseFloat(secondMatch[1]) : 0;

  // Convert seconds to whole number
  const wholeSeconds = Math.floor(seconds);

  // Construct the output string
  if (minutes === 0 && wholeSeconds === 0) {
    return 'Less than a second';
  } else {
    const parts = [];
    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }
    if (wholeSeconds > 0) {
      parts.push(`${wholeSeconds} second${wholeSeconds > 1 ? 's' : ''}`);
    }
    return parts.join(' and ');
  }
}



function Overview() {
  const match = useRouteMatch();
  const classes = useStyles();
  const historyfun = useHistory()
  const [open, setopen] = React.useState(false);
  const { loading, error, data } = useQuery(APP, { variables: { name: match.params.id } });
  const { loading: load, error: err, data: history } = useQuery(HISTORY, { variables: { name: match.params.id } });




  useEffect(() => {
  }, []);

  let imageName = history && history.app.releases.nodes && history.app.releases.nodes.find(o => o.status === "active")

  if (loading || load) {
    return (
      <div>
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (error) return `Error! ${error.message}`;
  if (err) return `Error! ${err.message}`;
  return (

    <div variant='outlined' style={{ minWidth: "300px", margin: "0px 20px 20px 20px", borderRadius: "12px", marginTop: "20px" }}>
      <div style={{ margin: "0px" }}>
        <div className={classes.margin}>
          {data && data.app.deployed && <Paper variant='outlined' sx={{ mb: "10px", pb: "10px", borderRadius: "12px" }} >
            <br />

            <div>

              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant='h5' textAlign={"center"}>
                  {data && data.app.status === "Active" && <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>}
                  {data && data.app.status === "Suspended" && <IconButton><CloudOff /></IconButton>}
                  {data && data.app.status === "Suspended" && <br />}
                  <AnimatePresence exitBeforeEnter>
                    <motion.div
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      Your App is Deployed and {data && data.app.status}
                    </motion.div>
                  </AnimatePresence>
                </Typography>

                <Button endIcon={<OpenInNewIcon />} onClick={() => {
                  imageName && imageName.image.startsWith("mysql") || imageName.image.startsWith("postgres") ? historyfun.push(`/deployments/service/${match.params.id}`) :
                    window.open(data.app.hostname, '_blank')
                }} className="btn btn-primary">Open App</Button>


              </Grid>

              <Grid container sx={{ p: 2 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={12} md={(data && data.app.buildTime) ? 4 : 6} lg={(data && data.app.buildTime) ? 4 : 6} sx={{ cursor: "pointer" }}>
                  <ListItem sx={{ borderRadius: "12px", p: 1 }} onClick={() => historyfun.push(`/orgs?o=${data && data.app.organization && data && data.app.organization.name}`)} >
                    <ListItemIcon>
                      <IconButton style={{ maxWidth: "40px", background: "#f5ecff", color: "rgb(133, 27, 192)", borderRadius: "12px", margin: "10px" }}><IoIosPeople /></IconButton>
                    </ListItemIcon>
                    <ListItemText sx={{ textDecoration: "capitalize" }} primary="Organization" secondary={<span>{data && data.app.organization && data && data.app.organization.name}</span>} />
                  </ListItem>
                </Grid>

                <Grid item xs={12} md={(data && data.app.buildTime) ? 4 : 6} lg={(data && data.app.buildTime) ? 4 : 6} sx={{ cursor: "pointer" }}>
                  {data && data.app.deploymentTime && <ListItem sx={{ borderRadius: "12px", p: 1 }} onClick={() => historyfun.push(`/deployments/service/${match.params.id}`)} >
                    <ListItemIcon>
                      <IconButton style={{ maxWidth: "40px", background: "#e3f2fd", color: "#5d86f9", borderRadius: "12px", margin: "10px" }}><BiSolidTimeFive /></IconButton>
                    </ListItemIcon>
                    <ListItemText primary="Deployed in" secondary={<span>{data && data.app.deploymentTime && data && <>{formatTime(data.app.deploymentTime)}</>}</span>} />
                  </ListItem>}
                </Grid>

                {data && data.app.buildTime && <Grid item xs={12} md={4} lg={4} sx={{ cursor: "pointer" }}>
                  <ListItem sx={{ borderRadius: "12px", p: 1 }} onClick={() => historyfun.push(`/deployments/service/${match.params.id}`)} >
                    <ListItemIcon>
                      <IconButton style={{ maxWidth: "40px", background: "#f6f7fb", borderRadius: "12px", margin: "10px" }}><MdOutlineTimelapse /></IconButton>
                    </ListItemIcon>
                    <ListItemText primary="Build in" secondary={<span>{data && data.app.buildTime && data && <>{formatTime(data.app.buildTime)}</>}</span>} />
                  </ListItem>
                </Grid>}



                <Grid item xs={12} md={6} lg={6} style={{ cursor: "pointer" }}>
                  <ListItem onClick={() => historyfun.push(`/deployments/service/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete" sx={{ '&:hover': { color: "blue" } }}>
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <FaTools color='primary' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Configuration" secondary={<span>Version {imageName && imageName.version}, Image: {imageName && imageName.image}</span>} />
                  </ListItem>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <ListItem onClick={() => historyfun.push(`/deployments/deploy/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <TiLocation />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Scale"
                      secondary={<span>
                        {data && data.app.regions && data.app.regions.map((item) => (
                          <span>
                            <ReactCountryFlag
                              countryCode={item.name.slice(0, 2)}
                              style={{
                                width: '1em',
                                height: '1em',
                                marginRight: "5px"
                              }}
                              title={item.name}
                            />
                            {item.name},</span>
                        ))}
                        {data && data.app.backupRegions && data.app.backupRegions.map((item) => (
                          <span>
                            <ReactCountryFlag
                              countryCode={item.name.slice(0, 2)}
                              style={{
                                width: '1em',
                                height: '1em',
                                margin: "5px"
                              }}
                              title={item.name}
                            />
                            {item.name}(backup),</span>
                        ))}
                      </span>} />
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ListItem onClick={() => historyfun.push(`/deployments/metrics/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <FaChartBar />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Metrics" secondary="New Visitors, CPU, Memory Resources and More" />
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ListItem onClick={() => historyfun.push(`/deployments/activity/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <ShowChartIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Activity" secondary="Activity" />
                  </ListItem>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <ListItem onClick={() => historyfun.push(`/deployments/logs/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <RiFileListFill />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Logs" secondary="Click to View Logs" />
                  </ListItem>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <ListItem onClick={() => historyfun.push(`/deployments/settings/${match.params.id}`)} secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <IoIosArrowForward />
                    </IconButton>
                  }>
                    <ListItemAvatar>
                      <Avatar>
                        <IoMdSettings />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Settings" secondary="Move, Suspend and Delete App" />
                  </ListItem>
                </Grid>
              </Grid>
            </div>
          </Paper>}




          {!data.app.deployed &&
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant='h5' textAlign={"center"}>
                    <IconButton><CloudOff /></IconButton>
                    <AnimatePresence exitBeforeEnter>
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        Your App is not Deployed
                      </motion.div>
                    </AnimatePresence>
                  </Typography>



                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "100px", padding: "20px" }}
                >


                  <span style={{ color: "grey", marginBottom: "20px" }}>Your App is not configured, click below to configure and deploy.</span>

                  <CreateNewApp steptwo={true} appName={match.params.id} />


                </Grid>

              </Grid>
              <Grid item xs={12} md={12} lg={12}>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "100px", padding: "20px" }}
                >
                  <span style={{ color: "grey", marginBottom: "20px" }}>To delete this app click below.</span>
                  <Button onClick={() => setopen(true)} color="error">Delete this app</Button>
                  <Dialog
                    open={open}
                    fullWidth={true}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "12px",
                        border: "solid 1px gray",
                        boxShadow: (true) ? "#160044 5px 5px, #160044 10px 10px" : "undefined"

                      }
                    }}
                    onClose={() => setopen(false)}
                    maxWidth={"xs"}
                  >
                    <DeleteApp appId={match.params.id} closeDialog={() => setopen(false)} />
                  </Dialog>


                </Grid>
              </Grid>

            </Grid>
          }

        </div>



      </div>
    </div>
  )
}


export default function Main(props) {
  const [ghRepoUrl, setghRepoUrl] = React.useState("");
  const handlesetGithubUrl =(value)=>{
    setghRepoUrl(value)
  }
  useEffect(() => {
    document.title = "Overview"
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>

        <Container className={classes.container} maxWidth={false}>

          <Switch>
            <Route path="/deployments/overview/:id">
              <Header />
              <Overview />
            </Route>
            <Route path="/deployments/service/:id">
              <Header />
              <Services />
            </Route>
            <Route path="/deployments/tools/:id">
              <Header />
              <GitHubTimeline />
            </Route>
            <Route path="/deployments/deploy/:id">
              <Header wantGithubURL={true} setGithubUrl={handlesetGithubUrl}/>
              <Deploy ghRepoUrl={ghRepoUrl}/>
            </Route>
            <Route path="/deployments/metrics/:id">
              <Header />
              {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).monitoringPlatform) ?
                <Metrics />
                : <UpgradePlan />}
            </Route>

            <Route path="/deployments/logs/:id" >
              <Header />
              {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).auditLogs) ?
                <Logs />
                : <UpgradePlan />}
            </Route>

            <Route path="/deployments/activity/:id">
              <Header />
              {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).versionControlPanel) ?
                <Activity />
                : <UpgradePlan />}
            </Route>

            <Route path="/deployments/settings/:id">
              <Header />
              <AppSettings />
            </Route>

          </Switch>

        </Container>
      </main>
    </div>
  );
}