import { gql, useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, Fade, Skeleton, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import { StyledEngineProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { DropzoneArea } from 'material-ui-dropzone';
import Moment from "moment";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { CgDatabase } from "react-icons/cg";
import { SiGooglecloud, SiMicrosoftazure } from "react-icons/si";
import { ReactComponent as MySvgFile } from '../../Images/addInstances.svg';
import { ORG_LIST } from '../Home/SettingsTab';

import { FaAws } from 'react-icons/fa';
import { MdAdd } from "react-icons/md";
import { USER_ACTIVITY } from './Nav';
import EmptyPageRenderCustom from '../NewPages/EmptyPageRenderCustom';
import { CONFIG_APP_BASE_API } from '../../configApp';




export const ORG_REGISTRY_TYPE = gql`
query{
    organizationRegistryType{
    
        id
        name
        slug
        isActive
  }
}
`

export const INSTANCE_LIST = gql`
query SecretsList($orgId: String!){
        getHost(orgId: $orgId) {
            id
            orgId
            orgName
            type
            serviceAccountURL
            status
            zone
            instanceName
            createdBy
            createdAt
            InstanceActivity {
              Id
              Type
              Activities
              Message
              createdAt
            }
        }
      
}
`
export const ADD_INSTANCE = gql`
mutation addInstanceMutation($type: String!,$serviceAccountURL: String, $orgId: String!,$zone: String!, $instanceName: String,$accessKey: String, $secretKey: String, $instanceId : String, $subscriptionId: String, $resourceGroupName: String, $clientId: String, $clientSecret: String, $tenantId: String ){
    createHost(input:{
      type:$type
      orgId:$orgId
      zone:$zone
      instanceName:$instanceName
      instanceId: $instanceId
      accessKey: $accessKey
      secretKey: $secretKey
      serviceAccountURL: $serviceAccountURL 
      subscriptionId: $subscriptionId
      resourceGroupName: $resourceGroupName
      tenantId: $tenantId
      clientId: $clientId
      clientSecret : $clientSecret
       
    }){
        id
    }
}`

export const UPDATE_SECRETS = gql`
mutation editSecret($name: String!,$registryType: String!,$userName:String!,$password: String!, $url: String ){
    updateOrganizationSecret(name:$name, input:{
       registryType:$registryType
      registryInfo:{
        userName:$userName
        passWord:$password
        url:$url
      }    
    }){
      message    
    }
}`

export const NODE_ACTION = gql`
mutation NodeActionMutation($instanceName: String!,$action: String){
    NodeAction(input: {instanceName:$instanceName, action: $action})
    {
        message
    }
}
`

export const NODE_ACTION_AWS = gql`
mutation NodeAction2Mutation($instanceName: String!,$action: String){
    NodeActionAWS(input: {instanceName:$instanceName, action: $action})
    {
        message
    }
}
`

export const NODE_ACTION_AZURE = gql`
mutation NodeActionForeAzure($instanceName: String!,$action: String){
    NodeActionAzure(input: {instanceName:$instanceName, action: $action})
    {
        message
    }
}
`






function ManageInstances(props) {
    const [regType, setregType] = useState("")
    const [name, setname] = useState("")
    const [accesskey, setaccesskey] = useState("")

    const [regTypeupdate] = useState("")
    const [nameupdate, setnameupdate] = useState("")
    const [accesskeyupdate, setaccesskeyupdate] = useState("")
    const [secretkeyupdate, setsecretkeyupdate] = useState("")
    const [urlupdate] = useState("")
    // const [isPrivateRegTypeupdate, setisPrivateRegTypeupdate] = useState(false)
    const [selectdeletesecretupdate] = useState("")

    const [orgid, setorgid] = useState("")
    const [url, seturl] = useState("")
    const [open, setOpen] = React.useState(false);
    const [openupdate, setOpenupdate] = React.useState(false);
    const [selectdeletesecret, setselectdeletesecret] = useState("")
    const [isUploaded, setisUploaded] = useState(true)

    const [OpenSnack, setOpenSnack] = useState(false)

    const [openNew, setopenNew] = useState(false);
    const [rows, setRows] = React.useState([])
    const [instanceId, setinstanceId] = useState("")

    const [instanceIdA, setinstanceIdA] = useState("")
    const [instanceIdS, setinstanceIdS] = useState("")

    //for Azure

    const [subscriptionId, setsubscriptionId] = useState("");
    const [resourceGroupName, setresourceGroupName] = useState("");
    const [tenantId, settenantId] = useState("");
    const [clientSecret, setclientSecret] = useState("");
    const [clientId, setclientId] = useState("");


    const { enqueueSnackbar } = useSnackbar();
    const [searched, setSearched] = React.useState("")

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal)
        const filteredRows = datalist.getHost.filter((row) => {
            return row.instanceName.includes(searchedVal);
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch("");
    };



    const columns = [

        {
            field: "instanceName",
            headerName: "Name",
            align: "left",
            width: 150,
            renderCell: (cellValues) => {

                return (<span style={{ textTransform: "capitalize" }}>
                    <IconButton style={{ padding: "0px", margin: "0px 5px 0px 0px" }} aria-label="delete" size="small">
                        <CgDatabase style={{ color: "gray", margin: "0px" }} />
                    </IconButton>
                    {cellValues.value}</span>)

            }
        },
        {
            field: "status",
            headerName: "Status",
            align: "center",
            renderCell: (cellValues) => {
                if (cellValues.value === "stopped") {
                    return (<div style={{ color: "#5d6a7c", background: "#e8eef3", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
                }

                if (cellValues.value === "running") {
                    return (<div style={{ color: "#028102", background: "#e5fceb", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
                }
            }
        },
        {
            field: "type", headerName: "Type", width: 100, align: "center", renderCell: (cellValues) => {
                return (<span><Tooltip title={cellValues.value}><IconButton>{cellValues.value === "AWS" ? <FaAws /> : (cellValues.value === "Azure" ? <SiMicrosoftazure /> : <SiGooglecloud />)}</IconButton></Tooltip>{cellValues.value}</span>)
            }
        },


        { field: "orgName", width: 150, headerName: "Organization", valueFormatter: ({ value }) => value.slug, align: "center" },
        {
            field: "zone", width: 150, headerName: "Zone", align: "center"
        },

        {
            field: "action",
            headerName: "Action",

            align: "center",
            renderCell: (cellValues) => {

                return (
                    <Button size='small'  color={cellValues.row.status === "running" ? "error" : "info"} onClick={() => handleClickOpen(cellValues.row)}>
                        {cellValues.row.status === "running" ? "Stop" : "Start"}
                    </Button>


                );
            }
        },
        {
            field: "createdAt", headerName: "Created", width: 150, align: "right", renderCell: (cellValues) => {
                var date = Moment(new Date(cellValues.row.createdAt), "YYYY MM DD hh:mm:ss").fromNow();
                return <div style={{ color: "grey" }}> {date}</div>
            }
        },

    ];




    const handleClickOpen = (e) => {
        setselectdeletesecret(e)
        setOpen(true);
    };



    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseNew = () => {
        setopenNew(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    }

    const handleCloseupdate = () => {
        setOpenupdate(false);
    };

    const handleChangeRegTypes = (e) => {
       
        setregType(e.target.value)

     
    }



    const handleChangeOrg = (e) => {
        setorgid(e.target.value)
    }

    const handleChangeName = (e) => {
        setname(e.target.value)
    }

    const handleChangeAccess = (e) => {
        setaccesskey(e.target.value)
    }



    const handleChangeNameupdate = (e) => {
        setnameupdate(e.target.value)
    }

    const handleChangeAccessupdate = (e) => {
        setaccesskeyupdate(e.target.value)
    }

  
    const handleChangeSecretupdate = (e) => {
        setsecretkeyupdate(e.target.value)
    }

    const handleChangeA = (e) => {
        setinstanceIdA(e.target.value)
    }
    const handleChangeS = (e) => {
        setinstanceIdS(e.target.value)
    }
    const handleChangeI = (e) => {
        setinstanceId(e.target.value)
    }

    const handleupdate = (e) => {
        e.preventDefault()
        updateSecret({
            variables: {
                name: nameupdate,
                registryType: regTypeupdate,
                userName: accesskeyupdate,
                password: secretkeyupdate,
                url: urlupdate,
            }
        });
    }

    const uploadFunction = async (e) => {

        if (e[0]) {
            await axios
                .post(`${CONFIG_APP_BASE_API}/api/v1/instanceKey/${name}`, e[0], { headers: { "Content-Type": e.type } })
                .then((response) => {
                    setisUploaded(false)
                    seturl(response.data.s3Link)
                })
                .catch((error) => {
                });
        }
    };


    const handleDeleteSecret = (e) => {
        var temp
        if (selectdeletesecret.status === "running") {
            temp = "stop"
        }
        else {
            temp = "start"
        }


        if (selectdeletesecret.type === "aws" || selectdeletesecret.type === "AWS") {

            nodeActionAWS({ variables: { instanceName: selectdeletesecret.instanceName, action: temp } })
        }
        if (selectdeletesecret.type === "azure" || selectdeletesecret.type === "Azure") {

            nodeActionAzure({ variables: { instanceName: selectdeletesecret.instanceName, action: temp } })
        }
        else {

            nodeAction({ variables: { instanceName: selectdeletesecret.instanceName, action: temp } })
        }

    }

    const handleReset = () => {
        setopenNew(!openNew)
        setname("")
        setaccesskey("")
  
        setregType("")
        setorgid("")
        setclientId("")
        settenantId("")
        setclientSecret("")
        setresourceGroupName("")
        setsubscriptionId("")
        setinstanceId("")
        setisUploaded(true)

    }
    const handlesubmit = (e) => {
        e.preventDefault()

        addIntance({
            variables: {
                type: regType,
                orgId: orgid,
                zone: accesskey,
                instanceName: name,
                instanceId: instanceId,
                accessKey: instanceIdA,
                secretKey: instanceIdS,
                subscriptionId: subscriptionId,
                resourceGroupName: resourceGroupName,
                clientSecret: clientSecret,
                clientId: clientId,
                tenantId: tenantId,
                serviceAccountURL: url
            }
        });



    }
    const { loading: loadorg, data: orgdata, refetch: refetchOrg } = useQuery(ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    });

    const { loading: loadregtype } = useQuery(ORG_REGISTRY_TYPE);

    const { loading: loadlist, error: errlist, data: datalist, refetch } = useQuery(INSTANCE_LIST, {
        variables: { orgId: "" },
    });

    const [addIntance, {  loading: addLoad, error: addErr }] = useMutation(ADD_INSTANCE, {
        refetchQueries: [INSTANCE_LIST],
        onCompleted: () => {
            setOpenSnack(true)
            handleCloseNew()
            setname("")
            setaccesskey("")
         
            setregType("")
            setorgid("")
            setclientId("")
            settenantId("")
            setclientSecret("")
            setresourceGroupName("")
            setsubscriptionId("")
            setinstanceId("")
            setisUploaded(false)

        },
        onError: (err) => {
            enqueueSnackbar(err ? err.message : "Internal server error!", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            setname("")
            setaccesskey("")
          
            setregType("")
            setorgid("")
        }
    });


    const [updateSecret] = useMutation(UPDATE_SECRETS, {
        refetchQueries: [INSTANCE_LIST],
        onCompleted: () => {

            setOpenupdate(false)
        }
    });
    const [nodeAction, {  loading: nodeLoad }] = useMutation(NODE_ACTION, {
        refetchQueries: [INSTANCE_LIST, USER_ACTIVITY],
        onCompleted: () => {
            setOpen(false)
            enqueueSnackbar(addErr ? addErr.message : "Successfully Updated!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });

    const [nodeActionAWS] = useMutation(NODE_ACTION_AWS, {
        refetchQueries: [INSTANCE_LIST, USER_ACTIVITY],
        onCompleted: () => {
            setOpen(false)
            enqueueSnackbar(addErr ? addErr.message : "Successfully Updated!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onError: (err) => {
            setOpen(false)
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });

    const [nodeActionAzure] = useMutation(NODE_ACTION_AZURE, {
        refetchQueries: [INSTANCE_LIST, USER_ACTIVITY],
        onCompleted: () => {
            setOpen(false)
            enqueueSnackbar("Successfully Updated!", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        },
        onError: (err) => {
            setOpen(false)
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })

        }
    });




    useEffect(() => {
        refetch()
        refetchOrg()
        setRows([])
        if (!errlist && !loadlist) {
            setRows(
                datalist.getHost
            );
        }
    }, [datalist]);


    if (loadlist) {
        return <div>
            <Skeleton variant="rectangular" height={30} width="30%" style={{ margin: "10px", borderRadius: "5px" }} />
            <Skeleton style={{ margin: "10px" }} />
            <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
        </div>
    }

    return (
        <div>

            <div>
                <Grid container>

                    <Dialog
                        open={openNew}
                        onClose={handleCloseNew}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle>
                            Add Instance
                        </DialogTitle>
                        <Paper style={{ padding: "0px 20px 20px 20px" }} elevation={1} spacing={1}>

                            <Grid container spacing={2}>

                                <Grid item xs={6}>
                                    {!loadregtype &&
                                        <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                            <InputLabel id="demo-simple-select-filled-label">Choose Type</InputLabel>
                                            <Select
                                                size={"small"}
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                label="Choose Type"
                                                onChange={(e) => handleChangeRegTypes(e)}
                                                value={regType}
                                            >
                                                <MenuItem> None </MenuItem>
                                                {[{ 'type': 'GCP', id: 1 }, { 'type': "AWS", id: 2 }, { 'type': 'Azure', id: 3 }].map((value) => (
                                                    <MenuItem key={value.id} value={value.type}>{value.type}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>}


                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={name}
                                        name="internalPort"
                                        label="Instance Name"
                                        onChange={handleChangeName}
                                    />
                                </Grid>


                                <Grid item xs={6}>
                                    {!loadorg &&
                                        <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >
                                            <InputLabel id="demo-simple-select-filled-label">Choose Organization</InputLabel>
                                            <Select
                                                size={"small"}
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                label="Choose Organization"
                                                onChange={(e) => handleChangeOrg(e)}
                                                value={orgid}
                                            >
                                                <MenuItem> None </MenuItem>
                                                {orgdata && orgdata.organizations.nodes && orgdata.organizations.nodes.map((value) => (
                                                    <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>}
                                </Grid>


                                {regType !== "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={accesskey}
                                        name="internalPort"
                                        label="Zone"
                                        onChange={handleChangeAccess}
                                    />
                                </Grid>}

                                {regType === "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={resourceGroupName}
                                        name="internalPort"
                                        label="Resource Group Name"
                                        onChange={(e) => setresourceGroupName(e.target.value)}
                                    />
                                </Grid>}
                                {regType === "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={subscriptionId}
                                        name="internalPort"
                                        label="Subscription Id"
                                        onChange={(e) => setsubscriptionId(e.target.value)}
                                    />
                                </Grid>}

                                {regType === "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={tenantId}
                                        name="internalPort"
                                        label="Tenant Id"
                                        onChange={(e) => settenantId(e.target.value)}
                                    />
                                </Grid>}

                                {regType === "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={clientSecret}
                                        name="internalPort"
                                        label="Client Secret"
                                        onChange={(e) => setclientSecret(e.target.value)}
                                    />
                                </Grid>}

                                {regType === "Azure" && <Grid item xs={6}>
                                    <TextField
                                        required
                                        size={"small"}
                                        variant="outlined"
                                        fullWidth
                                        value={clientId}
                                        name="internalPort"
                                        label="Client Id"
                                        onChange={(e) => setclientId(e.target.value)}
                                    />
                                </Grid>}

                                {regType === "AWS" &&
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            size={"small"}
                                            variant="outlined"
                                            fullWidth
                                            value={instanceIdA}
                                            name="internalPort"
                                            label="Access key"
                                            onChange={handleChangeA}
                                        />
                                    </Grid>
                                }



                                {regType === "AWS" &&
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            size={"small"}
                                            variant="outlined"
                                            fullWidth
                                            value={instanceIdS}
                                            name="internalPort"
                                            label="Secret key"
                                            onChange={handleChangeS}
                                        />
                                    </Grid>
                                }

                                {regType === "AWS" &&
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            size={"small"}
                                            variant="outlined"
                                            fullWidth
                                            value={instanceId}
                                            name="internalPort"
                                            label="Instance Id"
                                            onChange={handleChangeI}
                                        />
                                    </Grid>
                                }


                                {name !== "" && regType === "GCP" &&

                                    <Grid item xs={12}>

                                        {isUploaded ?

                                            <StyledEngineProvider injectFirst>
                                               
                                                    <DropzoneArea
                                                        clearOnUnmount={false}
                                                        maxFileSize={100000000}
                                                        showAlerts={false}
                                                        filesLimit={10}
                                                        showFileNamesInPreview={false}
                                                        acceptedFiles={['application/*']}
                                                        useChipsForPreview={false}
                                                        showPreviewsInDropzone={false}
                                                        dropzoneText={"Drag 'n' drop Your File here, or click to select"}
                                                        onChange={(e) => { uploadFunction(e) }}
                                                        style={{ marginBottom: "10px", color: "grey" }}
                                                    />
                                                
                                            </StyledEngineProvider>


                                            : <div style={{ textAlign: "center" }}>  <IconButton><CheckCircleIcon color="success" /></IconButton> Uploaded Successfully
                                                <br />
                                                <Button onClick={() => setisUploaded(!isUploaded)}>Upload Another file</Button>

                                            </div>}

                                    </Grid>}

                            </Grid>



                            <DialogActions>
                                {regType === "Azure" ? <Button size={"small"} disabled={!orgid ? true : name === "" ? true : subscriptionId=== "" ? true : !regType ? true : resourceGroupName === "" ? true : clientId === "" ? true : clientSecret === "" ? true : tenantId === "" ? true : false} color="primary" style={{ margin: "10px 0px 10px 0px" }} onClick={handlesubmit} disableElevation variant="contained">  {addLoad ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</Button>
                                    : <Button size={"small"} disabled={!orgid ? true : name === "" ? true : accesskey === "" ? true : !regType ? true : false} color="primary" style={{ margin: "10px 0px 10px 0px" }} onClick={handlesubmit} disableElevation variant="contained">  {addLoad ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</Button>}
                                <Button size={"small"} disableElevation style={{ margin: "10px 0px 10px 10px" }} variant="outlined" onClick={handleReset}>Cancel</Button>
                            </DialogActions>
                        </Paper>
                    </Dialog>


                </Grid>

            </div>


            {datalist && datalist.getHost ?

                <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        sx={{ mb: 2 }}
                    >
                        <TextField
                            value={searched}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searched !== "" &&
                                            <IconButton onClick={cancelSearch}>
                                                <CloseIcon />
                                            </IconButton>}
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            variant="outlined"
                            size={"small"}
                            // style={{ marginBottom: "10px", marginTop: "10px" }}
                            placeholder='Search for Instances'
                            onChange={(e) => requestSearch(e.target.value)}
                        />
                        <div>
                            <Button startIcon={<MdAdd />} variant='outlined' style={{ borderRadius: "24px", marginLeft: "10px", boxShadow: "none" }} disableElevation onClick={() => {setregType("GCP"); setopenNew(!openNew);}}>GCP</Button>
                            <Button startIcon={<MdAdd />} variant='outlined' style={{ borderRadius: "24px", marginLeft: "10px", boxShadow: "none" }} disableElevation onClick={() => {setregType("AWS"); setopenNew(!openNew);}}>AWS</Button>
                            <Button startIcon={<MdAdd />} variant='outlined' style={{ borderRadius: "24px", marginLeft: "10px", boxShadow: "none" }} disableElevation onClick={() => {setregType("Azure"); setopenNew(!openNew);}}>Azure</Button>
                          
                        </div>

                    </Grid>
                    <Fade in={true}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 500 } : {})}>
                        <div><Paper variant='outlined' style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", borderRadius: "12px" }}>

                            {datalist && datalist.getHost.length > 0 &&
                                <div style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "0px 5px 0px 5px" }}>
                                    {datalist && datalist.getHost && <DataGrid
                                        rowHeight={70}
                                        autoHeight={true}
                                        density={"compact"}
                                        rows={rows}

                                        columns={columns}
                                        pageSize={10}
                                        style={{ border: "2px" }}
                                    />
                                    }

                                </div>
                            }

                        </Paper>
                        </div>
                    </Fade>
                </div> : 
                <Box>
                            <EmptyPageRenderCustom
                                mainTitle="Add an Instance"
                                secondaryTitle="You can add here your Instances and Manage them from one panel."
                                learnMoreLink="#"
                                primaryButtonText={"Add Instance"}
                                primaryButtonAction={() => {setopenNew(!openNew);}}
                                iframeLink="https://nife.portal.trainn.co/share/9ca82195-1f46-4731-81a4-c1e0df15631d/embed"
                                imageListTitle={"Supported clouds"}
                                imageList={[{"name":"AWS","image":"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"},{"name":"GPC","image":"https://brandeps.com/logo-download/G/Google-Cloud-logo-vector-01.svg"},{"name":"Azure","image":"https://upload.wikimedia.org/wikipedia/commons/f/fa/Microsoft_Azure.svg"}]}
                            />
                        </Box>
                }





            <Dialog
                open={open}

                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {nodeLoad && <LinearProgress />}
                <DialogTitle id="alert-dialog-title">{nodeLoad ? <div> Please wait..<CircularProgress size="18px" /> </div> : "Are You Sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to <b> turn   {selectdeletesecret.InstanceActivity && selectdeletesecret.status === "running" ? "off" : "on"} {selectdeletesecret.instanceName}</b> Instance, Please be certain.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={nodeLoad} onClick={handleDeleteSecret} color="error">
                        Yes
                    </Button>
                    <Button disabled={nodeLoad} onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openupdate}
                fullWidth={true}
                maxWidth={"sm"}
                onClose={handleCloseupdate}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{selectdeletesecretupdate.name}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>

                        <Grid item xs>
                            <TextField
                                required
                                disabled={true}
                                variant="outlined"
                                fullWidth
                                value={nameupdate}
                                name="internalPort"
                                label="Name"
                                onChange={handleChangeNameupdate}
                            />
                        </Grid>
                        <Grid item xs>
                            {!loadregtype &&


                                <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px" }} >

                                    <TextField disabled={true} variant='outlined' label="Registry Type" value={regTypeupdate} />
                                </FormControl>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                value={accesskeyupdate}
                                name="internalPort"
                                label="Access Key"
                                onChange={handleChangeAccessupdate}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth

                                value={secretkeyupdate}
                                name="internalPort"
                                label="Secret Key"
                                onChange={handleChangeSecretupdate}
                            />
                        </Grid>
                        {/* {isPrivateRegTypeupdate && <Grid item xs>
                            <TextField
                                required
                                variant="outlined"
                                fullWidth
                                value={urlupdate}
                                name="internalPort"
                                label="URL"
                                onChange={handleChangeUrlupdate}
                            />
                        </Grid>} */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseupdate} color="primary" autoFocus>
                        Close
                    </Button>
                    <Button onClick={handleupdate} color="primary">
                        Save
                    </Button>

                </DialogActions>
            </Dialog>

            <Snackbar anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
                open={OpenSnack} autoHideDuration={3000} onClose={handleCloseSnack}>

                <Alert onClose={handleCloseSnack} severity="success"> Created Successfully</Alert>
            </Snackbar>



        </div>
    );

}

export default ManageInstances;