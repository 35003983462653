import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, CircularProgress, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Link, Pagination, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, FormControlLabel, StepContent, Grid, Paper, Box, Skeleton, Divider, Chip } from '@mui/material';
import { Octokit } from '@octokit/rest';
import { VscGitCommit } from 'react-icons/vsc';
import moment from 'moment';
import { MdFileDownload, MdOpenInNew } from 'react-icons/md';
import { RiExpandUpDownLine } from 'react-icons/ri';
import { BiCollapseVertical } from 'react-icons/bi';
import { BsFileZipFill } from 'react-icons/bs';
import { CustomTooltip } from '../../Settings/Organization';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import { APP } from '../Overview/Main';
import { FETCH_USER_PAT } from '../../Home/CreateNewApp/StepTwo';
import CodeScan from '../../../Tools/CodeScan';
import { FaGithub, FaTag } from 'react-icons/fa';
import GitHubActions from '../../../Tools/GithubActions';

var CryptoJS = require("crypto-js");
export function decodeAes(encryptedDataStr) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
    var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8)
    return decryptedData

}

const GitHubTimeline = () => {
    const [timelineItems, setTimelineItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [expandedItems, setExpandedItems] = useState({});
    const [repoUrl, setrepoUrl] = useState("");
    const [token, settoken] = useState("");
    const [selectedShow, setselectedShow] = useState("All");
    const itemsPerPage = 10;

    const match = useRouteMatch();
    const { enqueueSnackbar } = useSnackbar();




    const { data: data, error: apperr, loading: appload } = useQuery(APP, {
        variables: {
            name: match.params.id
        },
        onCompleted: (data) => {
            if (data) {
                setrepoUrl((data.app.sourceUrl && data.app.sourceUrl.startsWith("https://github.com/")) ? data.app.sourceUrl.split("archive")[0] : "")
            }
        }
    })

    const { loading: loadlist, error: errlist, data: datalist } = useQuery(FETCH_USER_PAT, {
        onCompleted: (data) => {
            if (data && data.getUserPAT && data.getUserPAT[0]) {
                settoken(decodeAes(data.getUserPAT[0].patToken));
            }
        }
    });

    useEffect(() => {
        const fetchRepoData = async () => {
            try {
                const octokit = new Octokit({
                    auth: token,
                });

                const [owner, repo] = repoUrl.replace('https://github.com/', '').split('/');

                // Fetch releases
                const releasesResponse = await octokit.repos.listReleases({ owner, repo });

                // Process releases
                const releases = await Promise.all(
                    releasesResponse.data.map(async (release) => {
                        try {
                            // Fetch commit data for the tag associated with the release
                            const commitResponse = await octokit.repos.getCommit({
                                owner,
                                repo,
                                ref: release.tag_name,
                            });

                            const commitData = commitResponse.data.commit;

                            return {
                                type: 'release',
                                name: release.name,
                                tagName: release.tag_name,
                                date: commitData.committer?.date || commitData.author?.date, // Use commit date as release date
                                committer: commitData.committer?.name || commitData.author?.name,
                                message: commitData.message,
                                url: release.html_url,
                                description: release.body,
                                assets: release.assets,
                            };
                        } catch (err) {
                            console.error(`Error fetching commit data for release ${release.tag_name}: ${err}`);
                            return {
                                type: 'release',
                                name: release.name,
                                tagName: release.tag_name,
                                date: release.published_at, // Fallback to release date if commit fetch fails
                                url: release.html_url,
                                description: release.body,
                                assets: release.assets,
                            };
                        }
                    })
                );

                // Sort releases by date and filter out items with null dates
                const sortedReleases = releases
                    .filter((release) => release.date)
                    .sort((a, b) => new Date(b.date) - new Date(a.date));

                // Update the state with the sorted timeline items
                setTimelineItems(sortedReleases);
                setFilteredItems(sortedReleases);

            } catch (err) {
                // Handle errors by setting an error message
                setError(err.message);
            } finally {
                // Ensure loading state is set to false after completion
                setLoading(false);
            }
        };




        if (repoUrl && token) {
            fetchRepoData();
        }


    }, [repoUrl, token]);



    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const toggleAssetsVisibility = (index) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const startIndex = (page - 1) * itemsPerPage;
    const displayedItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

    if (loading) return <Box sx={{ px: 2 }}>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"

            sx={{ m: 2 }}
        >
            <Skeleton variant='rounded' width={90} height={30} sx={{ mr: 2, borderRadius: "24px" }} />
            <Skeleton variant='rounded' width={90} height={30} sx={{ mr: 2, borderRadius: "24px" }} />
            <Skeleton variant='rounded' width={90} height={30} sx={{ mr: 2, borderRadius: "24px" }} />
            <Skeleton variant='rounded' width={90} height={30} sx={{ mr: 2, borderRadius: "24px" }} />
        </Grid>

        <Skeleton sx={{mx:2}} height={30} variant='rounded' />
        <Skeleton sx={{mx:2}} height={60} />
    </Box>;


    return (
        <Box sx={{ px: 4, pt: 2 }}>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"

            >

                <Chip sx={{ mr: 1 }} onClick={() => setselectedShow("All")} color={selectedShow === "All" ? "primary" : undefined} label="Show All Tools" />
                {/* <Chip sx={{ mr: 1 }} onClick={() => setselectedShow("Code Scan")} color={selectedShow === "Code Scan" ? "primary" : undefined} label="Code Scan" /> */}
                <Chip sx={{ mr: 1 }} onClick={() => setselectedShow("Workflow Runs")} color={selectedShow === "Workflow Runs" ? "primary" : undefined} label="Workflow Runs" />
                <Chip sx={{ mr: 1 }} onClick={() => setselectedShow("Release Management")} color={selectedShow === "Release Management" ? "primary" : undefined} label="Release Management" />
            </Grid>

            {/* {(selectedShow === "Code Scan" || selectedShow === "All") && <CodeScan githubUrl={repoUrl} />} */}

            {selectedShow === "All" && <Divider sx={{ mt: 2 }} />}
            {(selectedShow === "Workflow Runs" || selectedShow === "All") && <GitHubActions githubUrl={repoUrl} token={token ? token : null} />}
            {selectedShow === "All" && <Divider sx={{ mt: 2 }} />}
            {(selectedShow === "Release Management" || selectedShow === "All") && <Box>
                <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Release Management</Typography>
                <Typography variant='body2' sx={{ color: "grey" }}>   A release is a specific point in the project's history that is marked as significant, usually associated with the distribution of software to users. Releases are often tied to version numbers and may include compiled binaries, source code, and release notes.
                </Typography>{error && <Box sx={{ px: 0 }}>
                    <Typography sx={{ py: 2 }}>{error === "Not Found" ? <>
                        <i>This repo does not have release yet</i>
                    </> : error} </Typography>
                    <FaGithub /> Url: {repoUrl}</Box>}


                {!error && <Box sx={{ p: 2 }}>


                    <Stepper orientation="vertical">
                        {displayedItems.map((item, index) => (
                            <Step key={index} active={true}>
                                <StepLabel icon={item.type === 'tag' ? <FaTag style={{ fontSize: "20px" }} /> : <VscGitCommit style={{ fontSize: "24px" }} />}>
                                    <Typography variant="body2">
                                        {item.date && <>{moment(item.date).fromNow()}</>}

                                    </Typography>
                                </StepLabel>
                                <Typography variant='h6' sx={{ fontWeight: "bold", fontSize: "1rem" }}>{item.name}  </Typography>

                                <StepContent>
                                    <div style={{ padding: '10px' }}>
                                        {item.tagName && <Typography variant="body2">Tag Created by GitHub <Chip color='default' sx={{ mx: 1 }} label={item.tagName} size="small" icon={<FaTag style={{ fontSize: "12px", paddingLeft: "2px" }} />} /></Typography>}
                                        {item.type === 'tag' && item.message && <Typography variant="body2">Message: {item.message}</Typography>}
                                        {item.type === 'release' && item.description && <Typography variant="body2">Description: {item.description}</Typography>}
                                        {item.type === 'release' && item.assets && item.assets.length > 0 && (
                                            <div>
                                                <Button
                                                    startIcon={<BsFileZipFill />}
                                                    color="primary"
                                                    variant='contained'
                                                    size='small'
                                                    sx={{ textTransform: "capitalize", mt: 1 }}

                                                    endIcon={expandedItems[index] ? <BiCollapseVertical /> : <RiExpandUpDownLine />}
                                                    onClick={() => toggleAssetsVisibility(index)}
                                                >
                                                    {expandedItems[index] ? 'Hide Assets' : `${item.assets.length} assets - Click to expand`}
                                                </Button>
                                                {expandedItems[index] && (
                                                    <Paper variant='outlined' sx={{ mt: 1 }}>
                                                        <List dense={true}>
                                                            {item.assets.map((asset) => (
                                                                <ListItem key={asset.id}>
                                                                    <ListItemText
                                                                        primary={asset.name}
                                                                        secondary={`Size: ${(asset.size / 1024).toFixed(2)} KB`}
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <CustomTooltip title={"Click to download"}>
                                                                            <Link href={asset.browser_download_url} target="_blank" rel="noopener noreferrer">
                                                                                <MdFileDownload style={{ fontSize: "20px" }} />
                                                                            </Link>
                                                                        </CustomTooltip>

                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Paper>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    <Pagination
                        count={Math.ceil(filteredItems.length / itemsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        style={{ marginTop: '20px' }}
                    />
                </Box>}
            </Box>}
        </Box>
    );
};

export default GitHubTimeline;
