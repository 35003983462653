import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import jwt_decode from "jwt-decode";
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import Activity from './Components/Actiity';
import ComingSoon from "./Components/ComingSoon";
import CompleteProfile from './Components/CompleteProfile';
import Deploy from './Components/Deployments/Deploy/Main';
import DepOverview from './Components/Deployments/Overview/Main';
import ServiceDialogSlide from './Components/Deployments/Services/ServiceDialogSlide';
import ForgetPassword from './Components/ForgetPassword';
import GoogleReg from "./Components/GoogleReg";
import Libraries from './Components/Home/Libraries';
import Login from './Components/Login';
import NewPassword from "./Components/NewPassword";
import NotFound from "./Components/NotFound";
import OrgApps from "./Components/Org/index";
import PlanAndPricing from './Components/PlanAndPricing';
import Policies from './Components/Policies/Main';
import Register from './Components/Register';
import BitReg from "./Components/RegisterBitbucket";
import GithubReg from "./Components/RegisterGithub";
import GitlabReg from "./Components/RegisterGitlab";
import VerifyEmail from "./Components/VerifyEmail";
import VerifyGithub from "./Components/VerifyGithub";
import PrivateRoute from "./Containers/PrivateRoute";
import SideBar from './Containers/SideBar';
import TokenExBar from './Containers/TokenExBar';


import { CircularProgress, Grid, Typography } from '@mui/material';
import OverviewDatabase from './Components/Databases/OverviewDatabase';
import CostMonitoring from './Components/Home/CostMonetory';
import DashboardHome from './Components/Home/Dashboard/index';
import BYOCinst from './Components/Home/Instructions/BYOCinst';
import SitesDashboard from './Components/Home/Sites/index';
import Overview from './Components/Home/Sites/Site/Overview';
import { RenderTitle } from './Components/Settings/Nav';
import DeployLink from './Containers/DeployLink';
import DetectIfOffline from './Containers/DetectIfOffline';
import ChangeLog from './Tools/Changelogs';
import GitHubActions from './Tools/GithubActions';
import { CONFIG_APP_BASE_COMPANY } from './configApp';
// import CustomSideBar from './New folder/test';

const OnboardingQuickstart = React.lazy(() => import('./Components/OnboardingQuickstart/index'))
const ViewApp = React.lazy(() => import('./Components/MarketPlace/ViewApp'));
const AllOrgs = React.lazy(() => import('./Components/Org/AllOrgs'));
const WorkloadHub = React.lazy(() => import('./Components/Workload'));
const AllApps = React.lazy(() => import('./Components/Home/AllApps'));
const Databases = React.lazy(() => import('./Components/Databases'));
const Dep = React.lazy(() => import('./Components/Deployments/Main'));
const Analytics = React.lazy(() => import('./Components/Analytics'));
const MarketPlace = React.lazy(() => import('./Components/MarketPlace/index'));
const ShowUsers = React.lazy(() => import('./Components/Org/ShowUsers'));
const SettingsTab = React.lazy(() => import('./Components/Settings/index'));
const Location = React.lazy(() => import('./Components/Location/index'));
const Tools = React.lazy(() => import('./Components/Tools/index'));

const useStyles = makeStyles(() => ({
  light: {
    background: CONFIG_APP_BASE_COMPANY === "nife" ? "#f8f9fd center center" : "#f1e9df center center",
  },
  dark: {
    background: "transparent",
  },
}))

function App() {
  const [isLoading, setLoading] = useState(true);
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [isOffline, setisOffline] = useState(false)
  const [bar, setBar] = React.useState(false);
  const location = useLocation();
  const classes = useStyles();
  let title = window.location.href.split("/")

  useEffect(() => {

    if (localStorage.getItem("token") !== null) {
      if (localStorage.getItem("token") !== "null") {
        let jwt_Token_decoded = jwt_decode(localStorage.getItem("token"))
        let currentTime = Date.now() - 30000
        if (jwt_Token_decoded.exp * 1000 < currentTime) {
          if (bar === false) {
            setBar(true)
          }
        }
      }
    }

    if (!localStorage.getItem("mode")) {
      localStorage.setItem("mode", "light")
    }
    if(localStorage.getItem("mode") === "light"){
      document.body.style.backgroundColor = "#f8f9fd";
    }
    else{
      document.body.style.backgroundColor = "#181818";
    }
    window.addEventListener('online', function (e) {
      setisOffline(false)
    }, false);

    window.addEventListener('offline', function (e) {
      setisOffline(true)
    }, false);

    const el = document.querySelector(".container");
    if (el) {
      el.remove();
      setLoading(!isLoading);
    }


  }, [location]);




  const hideBar = () => {
    setBar(false);
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="App">

      <DetectIfOffline isOffline={isOffline} />

      <div className={isDarkTheme ? classes.dark : classes.light}>


        {
          bar && <TokenExBar fun={hideBar} />
        }


        <Switch>

          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/google-register" component={GoogleReg} />
          <Route exact path="/github-register" component={GithubReg} />
          <Route exact path="/gitlab-register" component={GitlabReg} />
          <Route exact path="/bitbucket-register" component={BitReg} />
          <Route exact path="/plans" component={PlanAndPricing} />
          <Route exact path="/complete-profile" component={CompleteProfile} />
          <Route exact path="/policies/privacy-policy" component={Policies} />
          <Route exact path="/deploy" component={DeployLink} />
          <Route path="/reset-password" component={NewPassword} />
          <Route path="/verify-email" component={VerifyEmail} />
          <Route path="/login/github/callback" component={VerifyGithub} />
          <Route exact path="/forgetpassword" component={ForgetPassword} />
          <Route exact path="/instructions/byoc" component={BYOCinst} />
          <Route exact path="/changelog" component={ChangeLog} />
          <Route exact path="/test" component={GitHubActions}/>


          <SideBar>
            <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <CircularProgress size={15} /> <Typography sx={{ ml: 1, textTransform: "capitalize" }}> Loading <RenderTitle value={title} />... </Typography>
              </Grid>
            </div>}>

             
              <PrivateRoute exact path="/orgs/" component={OrgApps} />
              <PrivateRoute exact path="/organizations" component={AllOrgs} />
              <PrivateRoute exact path="/members" component={ShowUsers} />
              <PrivateRoute exact path="/analytics" component={Analytics} />
              <PrivateRoute exact path="/databases" component={Databases} />
              <PrivateRoute exact path="/database/:id" component={OverviewDatabase} />
              <PrivateRoute exact path="/marketplace" component={MarketPlace} />
              <PrivateRoute exact path="/apps" component={AllApps} />
              <PrivateRoute exact path="/workloads" component={WorkloadHub} />
              <PrivateRoute exact path="/libraries" component={Libraries} />
              <PrivateRoute exact path="/dashboard/deploy/:id" component={Deploy} />
              <PrivateRoute path="/settings/:id" component={SettingsTab} />
              <PrivateRoute path="/tools" component={Tools} />
              
              <PrivateRoute path="/onboarding-quickstart" component={OnboardingQuickstart} />
              <PrivateRoute component={DashboardHome} path="/teamboard" exact />
              <PrivateRoute path="/deployments/services/:id" component={ServiceDialogSlide} />
              <PrivateRoute path="/marketplace/:name" component={ViewApp} />
              <PrivateRoute path="/site/:id" component={Overview} />
              <PrivateRoute path="/deployments/:id" component={DepOverview} />
              <PrivateRoute path="/location/:id/:name" component={Location} />
              <PrivateRoute path="/activity" component={Activity} />
              <PrivateRoute exact path="/location" component={Dep} />
              <PrivateRoute exact path="/sites" component={SitesDashboard} />

              <PrivateRoute path="/Cost-Monitoring" component={CostMonitoring} />
            </Suspense>

          </SideBar>

          <Route path="/comingsoon" component={ComingSoon} />
          <Route component={NotFound} />

        </Switch>


      </div>
    </div>
  );
}

export default App;

