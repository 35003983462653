import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';

const ExpandMetrics = ({ open, onClose, title,desc,  children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenToggle = () => {
    setIsFullscreen(prev => !prev);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle sx={{textTransform:"capitalize", fontWeight:"bold"}}>
        {title}
        <Tooltip title="Close">
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', right: 40 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
      <Typography style={{ color: "grey", marginBottom: "10px" }} variant='body2'>{desc}</Typography>
        {children}
      </DialogContent>
      <DialogActions>
        {/* Add actions if needed */}
      </DialogActions>
    </Dialog>
  );
};

export default ExpandMetrics;
