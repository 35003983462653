import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { CONFIG_APP_BASE_API } from '../configApp';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  image: {
    backgroundSize:"cover", 
    backgroundPosition:"center",
  },
  gridcontainer: {
    backgroundColor:'#fff',
    width:'auto',
    maxWidth:"600px",
    borderRadius:"25px",
    boxShadow:"0 1rem 3rem rgba(0,0,0,.175) !important",
  },
  divcomponent: {
    padding: 30, 
    textAlign:"center",
  },
  textpadding: {
    padding: 10,
    color:" gray",
  },
  link: {
    textAlign:"center",
    margin:"5px",
  },
  hr:{
    marginTop:"10px",
    marginBottom:"10px", 
    color: "#ffe",
    height:0,
  },
}));

const StyledButton = withStyles({
  root: {
    position: 'static',
    background: '#4e73df',
    borderRadius: 25,
    border: 0,
    color: '#fff',
    height: 48,
    padding: '0 30px',
    marginTop: '5px',
    '&:hover': {
      backgroundColor: '#2653d4',

    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `0 0 0 2px`,
      borderColor: '#f33',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStylesCustomizeTextField = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 25,
    height:'48px',
    marginTop:'5px',
    marginBottom: '5px',
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function CustomizeTextField(props) {
  const classes = useStylesCustomizeTextField();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}


function ResetPassword() {
  const classes = useStyles();
  const urlid = useQuery().get("token")

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [msg, setMsg] = React.useState("Enter your New Password")
  const [isError, setIsError] = React.useState("gray")
  const [isLoad, setIsLoad] = React.useState(false)
  const [passwordErrors, setPasswordErrors] = useState('')

  const [PasswordValidation, setPasswordValidation] = React.useState(false)
  const [ConfirmPasswordValidation, setConfirmPasswordValidation] = React.useState(false)
  const [ConfirmPassword, setConfirmPassword] = React.useState("")
  const [isSuccess, setIsSuccess] = React.useState(false)

    const valuevalidatePassword = (e) => {
    const p = e.target.value
    const errors = [];
    if (p.length < 8) {
      errors.push("Your password must be at least 8 characters,");
    }
    if (p.search(/[a-z]/i) < 0) {
      errors.push(" at least one letter");
    }
    if (p.search(/[A-Z]/i) < 0) {
      errors.push(" at least one uppercase letter");
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(" at least one digit");
    }
    if (errors.length > 0) {
      setPasswordErrors(errors.join("\n"))
    }
    setPasswordValidation((errors.length > 0) ? true : false)
  }
  const [Password,setPassword]=React.useState("")

    useEffect(() => {
    axios.get(`${CONFIG_APP_BASE_API}/api/v1/verify_token?token=${urlid}`).then((res) => {
      setIsLoad(true)
      
    } ,(err) =>{
        
            if (err.response){
              setMsg( "Token expired")
              setIsError("red")

            }
            else{
              setMsg("Somthing went worng")
              setIsError("red")
            }

            
        })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Password!==ConfirmPassword){
      setConfirmPasswordValidation(true)
    }
       
  else{ const headers = {
                          'Content-Type': 'application/json'
                        }
        const data = {
          'accessToken':urlid,
           'password': Password
        }
      axios.post(`${CONFIG_APP_BASE_API}/api/v1/reset_password`,data,{headers: headers}).then( (res) => {
          setMsg("Password updated successfully!")
          setIsError("green")
          setIsSuccess(true)

        } ,(err) =>{
          
              setMsg("Somthing went worng")
              setIsError("red")
            

            
        })}
  
  }

  return (
    <Container component="main" style={{width:"600px"}}>
      <CssBaseline />
      <div className={classes.paper}>

      {isLoad ? (
        <Grid container className={classes.gridcontainer}>
           
            <Grid item xs={12} sm={12} md={12} className={classes.divcomponent}>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
               {isSuccess &&  <CheckCircleIcon style={{color:"green",textAlign:"center",marginTop:"20px"}}/> }
               <br/>
              <Typography style={{color:isError,textAlign:"center"}} variant="Button" gutterBottom className={classes.textpadding}>
              {msg}
              </Typography>
              {!isSuccess &&
              (<div>
                <form className={classes.form} onSubmit={e =>handleSubmit(e)}>
                <CustomizeTextField
                    label="New Password"
                    type="password"
                    value={Password}
                    onChange={(e) => {setPassword(e.target.value); valuevalidatePassword(e)}}
                    className={classes.margin}
                    variant="filled"
                    id="text-input" 
                    required={true}
                    error = {PasswordValidation}
                    helperText= {PasswordValidation && passwordErrors}
                    fullWidth
                  />

                  <CustomizeTextField
                    label="Confirm Password"
                    type="password"
                    value={ConfirmPassword}
                    onChange={(e) => {setConfirmPassword(e.target.value); 
                     
                     /* valuevalidateConfirmPassword();*/

                    }}
                    className={classes.margin}
                    variant="filled"
                    id="text-input" 
                    required={true}
                    error = {ConfirmPasswordValidation}
                    helperText= {ConfirmPasswordValidation && "Password not match"}
                    fullWidth
                  />
                <StyledButton type="submit" disabled={PasswordValidation} fullWidth>Reset Password</StyledButton>
                
              </form> </div>)}
              <br/>
              {isSuccess && <Link to="/"><Button variant="inline" style={{color:"#111",textTransform:"none"}} className={classes.link}>
                Login, here!
              </Button></Link>}
              <hr className={classes.hr}/>
              <Grid wrap="wrap" container direction="column">

              <Button style={{color:"#2653d4",textTransform:"none"}} href="/" className={classes.link}>
                If you still need help Contact us on <b>hello@nife.io</b>
              </Button>
              </Grid>
      
            </Grid>
          </Grid>) 
          :  (<Typography style={{color:isError}} variant="body1" gutterBottom className={classes.textpadding}>
              {msg}
              </Typography>)
      }
      </div>
     
    </Container>
  );
}

export default ResetPassword;