import React from 'react'
import CreateNewSite from '../CreateNewSite';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { MdOutlineReplay } from 'react-icons/md';

export default function Redeploy({ siteName, org}) {
    const [openCreateNewSite, setopenCreateNewSite] = React.useState(false);
    return (
        <div>
            <Divider sx={{ mt: 2 }} />
            <h3>Redeploy</h3>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography>
                    Click on the following button to redeploy this site with your new changes.
                </Typography>
                <Button endIcon={<MdOutlineReplay/>} variant='contained' onClick={() => setopenCreateNewSite(true)}>Redeploy</Button>
            </Grid>
            <CreateNewSite redeployOrg={org} redeploy={true} redeploySiteName={siteName} hideButton={true} openCreateNewSite={openCreateNewSite} setopenCreateNewSite={setopenCreateNewSite} />
        </div>
    )
}
