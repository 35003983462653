import React, { useState } from 'react';
import { Button, Paper, TextField, Typography, Grid, Snackbar, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CgBorderStyleDotted } from 'react-icons/cg';
import { IoIosInfinite } from 'react-icons/io';
import { AiFillGithub } from 'react-icons/ai';
import { Edit } from '@mui/icons-material';
import { MdFileCopy } from 'react-icons/md'; // Added missing import
import { useTheme } from '@mui/material/styles';
import { FaArrowLeft } from 'react-icons/fa';
import { CONFIG_APP_BASE_COMPANY } from '../../../../configApp';

// Explanation component to show icons and descriptions
const Explanation = () => (
    <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
            <Paper sx={{ p: 2, textAlign: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Edit sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6">Add Configuration</Typography>
                <Typography sx={{color:"grey"}}>
                    Manually add the configuration to your <code>.github/workflows/main.yml</code> file.
                </Typography>
            </Paper>
        </Grid>
        <Grid item md={4} xs={12}>
            <Paper sx={{ p: 2, textAlign: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <GitHubIcon sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="h6">Commit Changes</Typography>
                <Typography sx={{color:"grey"}}>
                    Commit your changes to the <code>.github/workflows/main.yml</code> file.
                </Typography>
            </Paper>
        </Grid>
        <Grid item md={4} xs={12}>
            <Paper sx={{ p: 2, textAlign: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <img src={CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg"}  style={{ objectFit: "fill", width: "auto", height: "2rem" }} alt="Oikos logo" />
                    <CgBorderStyleDotted style={{ fontSize: "30px", margin: "0px 10px" }} />
                    <IoIosInfinite style={{ fontSize: "40px" }} />
                    <CgBorderStyleDotted style={{ fontSize: "30px", margin: "0px 10px" }} />
                    <AiFillGithub style={{ fontSize: "40px" }} />
                </Grid>
        
                <Typography sx={{mt:2}} variant="h6">Automatic Deployment</Typography>
                <Typography sx={{color:"grey"}}>
                    On every push, the GitHub Action will run <code>synckai-actions</code> to redeploy your site.
                </Typography>
            </Paper>
        </Grid>
    </Grid>
);

// ConfigurationView component to show steps and a text field
const ConfigurationView = ({ onCopy, onBack }) => {
    const [code, setCode] = useState(`name: sync s3
on: [push]
jobs:
  deploy:
    name: sync proxy
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v2
      - uses: nifetency/synckai-actions@1.0 
        env:
          AWS_ACCESS_KEY_ID: \${{ secrets.AWS_ACCESS_KEY_ID }}
          AWS_SECRET_ACCESS_KEY: \${{ secrets.AWS_SECRET_ACCESS_KEY }}
          AWS_REGION: \${{ secrets.AWS_REGION }}
        with:
          args: " -f \${{ secrets.FOLDER }} -b \${{ secrets.BUCKET }}"`);

    const handleCopyToken = () => {
        navigator.clipboard.writeText('your-nife-access-token'); // Replace with actual token if necessary
        alert('Token copied to clipboard');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
                <Typography variant="h6" sx={{ mb: 0 }}>
                    Let's get started
                </Typography>
                <Button startIcon={<FaArrowLeft/>} variant="outlined" color="primary" onClick={onBack}>
                    Back
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>Step 1: Commit main.yaml</Typography>
                    <Typography variant='body2'>Copy the code in main.yaml and commit it to .github/workflows/main.yaml in your repository.</Typography>
                    <Button 
                        onClick={() => {
                            navigator.clipboard.writeText(code);
                            alert('Code copied to clipboard');
                        }} 
                        variant='contained' 
                        disableElevation 
                        size='small' 
                        sx={{ mt: 1, textTransform: "capitalize" }} 
                        startIcon={<MdFileCopy />}
                    >
                        Copy code of main.yaml
                    </Button>
                    <br />
                    <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>Step 2: Set Secrets</Typography>
                    <Typography sx={{ mb: 1 }} variant='body2'>Set the AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY and AWS_REGION in your repository.</Typography>
                    <br />
                    <Typography variant='h6' sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>Step 3: Set token</Typography>
                    <Typography variant='body2'>Copy the nife access token and save it as a secret named 'NIFE_ACCESS_TOKEN' in your repository.</Typography>
                    <Button 
                        onClick={handleCopyToken} 
                        variant='contained' 
                        disableElevation 
                        size='small' 
                        sx={{ mt: 1, textTransform: "capitalize" }} 
                        startIcon={<MdFileCopy />}
                    >
                        Copy token
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography sx={{ pb: 0.5 }} variant='body2'>{"</> Contents of main.yaml (step 1)"}</Typography>
                    <TextField 
                        fullWidth 
                        value={code} 
                        onChange={(e) => setCode(e.target.value)} 
                        multiline 
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

// Main CICDComponent
const CICDComponent = () => {
    const [enabled, setEnabled] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleToggle = () => {
        setEnabled(!enabled);
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setSnackbarMessage(`Copied "${text}" to clipboard!`);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleBack = () => {
        setEnabled(false);
    };

    return (
        <div style={{ padding: '16px' }}>
            {!enabled ? (
                <>
                    <Typography variant="h6" sx={{ textAlign: 'left' }}>
                        How it works?
                    </Typography>
                    <Typography sx={{ mb: 2,color:"grey" }} variant='body2'>
                        Here's a quick guide on setting up CI/CD with GitHub Actions for automatic deployment.
                    </Typography>
                    <Explanation />
                    <Grid container justifyContent="center" sx={{ mt: 4 }}>
                        <Grid item>
                            <Button 
                                onClick={handleToggle} 
                                variant='contained' 
                                size='large' 
                                sx={{ textTransform: "capitalize", fontWeight: "bold", mt: 1 }}
                            >
                                Integrate CI/CD with GitHub
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <ConfigurationView onCopy={handleCopy} onBack={handleBack} />
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </div>
    );
};

export default CICDComponent;
