import { gql, useMutation, useQuery } from '@apollo/client';
import { Info } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, Collapse, Fade, IconButton, InputAdornment, LinearProgress, Paper, Skeleton, Tooltip, tooltipClasses } from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { IoIosPeople } from 'react-icons/io';
import { RiAddLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import UpgradePlan from '../../Containers/UpgradePlan';
import LogTrigger from '../../LogTrigger';
import { AvailabilityCluster } from "../Deployments/AppSettings/MoveApp";
import { ADD_ORG } from '../Home/SettingsTab';
import OrgDailogSettings from './OrgDailogSettings';
import { styled } from '@mui/material/styles';
import { MdOutlineFilterList } from 'react-icons/md';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    opacity: 1,
    fontSize: "15px",
    backgroundColor: theme.palette.common.black,
  },
}));


export const MAKE_DEFAULT = gql`
mutation updateOrganizationMutation($org: String!, $defaulttype: Boolean! ){
  updateOrganization(org: $org, defaulttype : $defaulttype)}
`
export const ADD_SUB_ORG = gql`
mutation createSubOrganizationMutation($name: String!, $parentOrgId: String! ){
  createSubOrganization(input: {name: $name, parentOrgId : $parentOrgId}){
    organization {
      id
      name
      slug
      type
     
    }
  }}
`

export const ADD_BUSINESS_UNIT = gql`
mutation createBusinessUnitnMutation($name: String!, $orgId: String!, $subOrg: String! ){
  createBusinessUnit(input: {name: $name, orgId : $orgId, subOrg: $subOrg})
}
`

export const ORG_LIST = gql`
query{
  organizations{
    nodes{
      id
      name
      slug
      type
      parentId
      region{
        regCode
        isDefault
        regionName
        clusterType
      }
      subOrg{
        id
        name
        slug
      }
    }
  }
}

`

export const ALL_ORG_LIST = gql`
query{
  organizationsandBusinessUnit{
    nodes {
      id
      parentId
      parentOrgName
      name
      slug
      type      
      isActive
      region{
        regCode
        isDefault
        regionName
      }
      subOrg{
        id
        name
        slug
        region{
          regCode
          isDefault
        }
      }
    }

    businessUnit {
      id
      orgId
      orgName
      subOrgId
      subOrgName
      name
    }
  }
}

`



export const DELETE_ORG = gql`
mutation DeleteOrgMutaion($organizationId :  String!){
  deleteOrganization(input:{
    organizationId:$organizationId
  }){
    deletedOrganizationId
  }
}`


export const UPDATE_REGION = gql`
mutation UpdateRegionMutaion($organizationId : String!, $region: [String], $isDefault: Boolean){
  mutipleRegion(input:{
    organizationId:$organizationId,
    region: $region,
    isDefault: $isDefault,
  }){
    region
    isDefault
  }
}`

export const MIGRATE_ORG = gql`
mutation migrateOrgMutaion($organizationIdFrom : String!, $organizationIdTo: String!){
  migrateOrganization(input:{
    organizationIdFrom:$organizationIdFrom,
    organizationIdTo: $organizationIdTo
  })
}`



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orange: {
    width: "100px",
    height: "100px",
    margin: "10px",
  },
  maintext: {
    color: theme.palette.text.secondary
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));




function Organization(props) {
  const classes = useStyles();
  let history = useHistory();
  const role = localStorage.getItem("role")
  // const permissions = JSON.parse(localStorage.getItem('permissions'))
  const [selectedOrg, setselectedOrg] = React.useState(null)
  const [defaultOrgName, setdefaultOrgName] = useState(null);
  const [showApply, setshowApply] = useState(false);
  const [openDefaultOrg, setOpenDefaultOrg] = React.useState(false);
  const [openMigrateOrg, setopenMigrateOrg] = useState(false);
  const [openDeleteOrg, setOpenDeleteOrg] = React.useState(false);
  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [defaultRegName, setdefaultRegName] = useState(null);
  const [confirmDelete, setconfirmDelete] = useState("");
  const [selectdeleteOrg, setselectdeleteOrg] = useState(null);

  const [openorg, setOpenorg] = React.useState(false);
  const [opensuborg, setOpensuborg] = React.useState(false);
  const [newOrgName, setnewOrgName] = React.useState("")
  const [isProgress, setisProgress] = React.useState(false)
  const [dailogtype, settype] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const handleClickOpenDefaultOrg = (e) => {
    setOpenDefaultOrg(true);

    if (orgdata) {
      let obj = orgdata.organizations.nodes.find(o => o.name === e)

      if (obj) {
        setselectedOrg(obj)
        let reg = obj.region.filter(o => o.isDefault === true)
        setInputValue(reg)
        let regName = datareg.getAvailabilityCluster.nodes.find(o => o.regionCode == reg.regCode)
        setdefaultRegName(regName)
      }

    }

  };



  const handleClose = () => {
    setOpenorg(false);
  };

  const handleCloseSub = () => {
    setOpensuborg(false);
    settype("")
  };

  const handleOpenSub = (e) => {
    settype("suborg")
    setOpensuborg(true);
    setAge(e)
    setnewOrgName("")
  };

  const handleOpenBU = (e) => {
    settype("bu")
    setOpensuborg(true);
    setAge(e)
    setnewOrgName("")
  };

  const handleCreateNewOrg = (e) => {
    e.preventDefault()
    setisProgress(true)
    createOrg({ variables: { name: newOrgName } });
  }

  const handleCreateNewSubOrg = (e) => {
    e.preventDefault()
    setisProgress(true)
    let obj = orgdata.organizations.nodes.find(o => o.name === age)
    if (dailogtype === "suborg") {
      createSubOrg({ variables: { name: newOrgName, parentOrgId: obj.id } });
    }

    if (dailogtype === "bu") {
      createBusinessUnit({ variables: { name: newOrgName, orgId: obj.id, subOrg: "" } })
    }
  }


  // const handleCreateNewBusinessUnit = (e) => {
  //   e.preventDefault()
  //   setisProgress(true)
  //   let obj = orgdata.organizations.nodes.find(o => o.name === age)
  //   createBusinessUnit({ variables: { name: newOrgName, orgId: obj.id, subOrg: "" } })

  // }




  const [validOrgName, setvalidOrgName] = React.useState(true)
  const [makeDefaultTypeTrue, setmakeDefaultTypeTrue] = useState(true);

  const handleOrgName = (e) => {
    setnewOrgName(e.target.value)
    setvalidOrgName(/[a-zA-Z]/.test(e.target.value));
  }

  const handleCloseMsg = () => {
    setisSuccess(false)
  }
  const [openWarning, setOpenWarning] = React.useState(false);

  const [row, setrow] = useState([]);

  const handleChange = () => {
    setOpenWarning(true);
  };


  const handleCloseDefaultOrg = () => {
    setOpenDefaultOrg(false);
  };

  const handleCloseMigrateOrg = () => {
    setopenMigrateOrg(false)
    setmigrateFrom("")
    setmigrateTo("")
  }

  const handleSubmitMigrate = () => {
    if (orgdata && orgdata.organizations.nodes) {
      migrateOrganization({
        variables: {
          organizationIdFrom: orgdata.organizations.nodes.find(o => o.name === migrateFrom).id,
          organizationIdTo: orgdata.organizations.nodes.find(o => o.name === migrateTo).id,
        }
      })
    }

  }


  const handleMigrateOrganization = (value) => {
    setopenMigrateOrg(true)
    setmigrateFrom(value)
  }

  const handleCloseDeleteOrg = () => {
    setconfirmDelete("")
    setOpenDeleteOrg(false);
    setselectdeleteOrg(null)
  };

  // const handleSelectOrg = (e) => {
  //   setselectedOrg(e)
  // }

  const handleClickOpen = () => {
    setnewOrgName("")
    setOpenorg(true);
  };

  const handleDeleteOrg = (e) => {

    orgdata.organizations.nodes.forEach(element => {
      if (element.type === "true") {
        // setOpenWarning(true)
      }
      else {
        if (element.name === e) {
          setselectdeleteOrg(element)
        }
      }
    });
    setOpenDeleteOrg(true)
  }



  const handleUpdateRegion = (reason, detail) => {
    if (reason === "removeOption") {
      updateRegion({
        variables: {
          organizationId: selectedOrg.id,
          region: [detail.option.regCode],
          isDefault: false
        }
      })
    }
    if (reason === "selectOption") {
      updateRegion({
        variables: {
          organizationId: selectedOrg.id,
          region: [detail.option.regCode],
          isDefault: true
        }
      })
    }

  }

  const handleNewDefaultOrg = (e) => {
    makeDefault({
      variables: {
        org: selectedOrg.slug,
        defaulttype: true,
      }
    })
  }


  const { loading: loadorg, error: errorg, data: orgdata, refetch } = useQuery(ORG_LIST, {
    variables: { userId: localStorage.getItem("userId") },
  });

  const [createOrg, { error: errororg }] = useMutation(ADD_ORG, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      setisProgress(false)
      setnewOrgName("")
      if (err.message.includes("Duplicate entry")) {
        enqueueSnackbar("Organization name not unique, try diffrent name", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }
      else {
        enqueueSnackbar(err.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }



    },
    onCompleted: () => {
      handleClose()
      enqueueSnackbar("Successfully Created!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setOpenorg(false)
      setisProgress(false)
    }
  });


  const [createSubOrg, { error: errorsuborg }] = useMutation(ADD_SUB_ORG, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      setisProgress(false)
      setnewOrgName("")
      enqueueSnackbar(err.message === "You've reached your maximum limit of current plan. Please upgrade your plan to create Sub-Organization" ? "You've reached your maximum limit of current plan. Please upgrade your plan to create Sub-Organization" : err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    onCompleted: () => {

      enqueueSnackbar("Successfully Created!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setOpensuborg(false)
      setisProgress(false)
    }
  });

  const [createBusinessUnit, { error: errorbunit }] = useMutation(ADD_BUSINESS_UNIT, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      setisProgress(false)
      setnewOrgName("")
      if (errorbunit && errorbunit.message !== "You've reached your maximum limit of current plan. Please upgrade your plan to create Business Unit") {
        enqueueSnackbar("Organization already exist!", {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }

    },
    onCompleted: () => {

      enqueueSnackbar("Successfully Created!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      setOpensuborg(false)
      setisProgress(false)
    }
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 280,
      align: "left",
      renderCell: (cellValues) => {

        return (<Typography sx={{ textTransform: "capitalize" }}> <IconButton style={{ padding: "0px 0px 0px 0px", marginRight: "10px" }} ><IoIosPeople /></IconButton>
          {cellValues.value.toString().toLowerCase()}{cellValues.value === defaultOrgName &&
            <b style={{ color: "rgb(33, 150, 243)", border: "1px solid rgb(33, 150, 243)", background: "rgb(227, 242, 253)", marginLeft: "5px", padding: "2px 10px 2px 10px", borderRadius: "12px", fontSize: "12px" }}>Default</b>}
        </Typography>)
      }
    },
    {
      field: "region",
      headerName: "Regions",
      align: "center",
      renderCell: (cellValues) => {

        return <CustomTooltip style={{ cursor: "pointer" }} arrow title={cellValues.value && cellValues.value.map((e) => `${e.regionName} (${e.clusterType === "k8s" ? "Nife" : e.clusterType})`).join(', ').replace(/, ([^,]*)$/, ' and $1')}>
          {cellValues.value.length}
        </CustomTooltip>
      }
    },
    {
      field: "actions",
      headerName: "Actions",

      align: "center",
      renderCell: (cellValues) => {
        return (<OrgDailogSettings
          subOrg={handleOpenSub}
          bu={handleOpenBU}
          migrateOrganization={handleMigrateOrganization}
          value={cellValues.row.name}
          deleteOrg={handleDeleteOrg}
          editOrg={handleClickOpenDefaultOrg}
          isSubOrg={false}
          isBU={false}
          isDefault={cellValues.row.name === defaultOrgName}
          datalen={row && row.length}


        />);
      }
    },

  ];



  const { data: datareg } = useQuery(AvailabilityCluster);

  const [deleteOrg, { loading: deleteloading, error: deleteerror }] = useMutation(DELETE_ORG, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      handleCloseDeleteOrg()
      // handleOrgDeleteError()

    },
    onCompleted: () => {
      enqueueSnackbar("Successfully Deleted!", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      openDeleteOrg(false)
      handleCloseDeleteOrg()
    }
  });

  const [updateRegion, { loading: regloading, error: regerror }] = useMutation(UPDATE_REGION, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      setshowApply(false)
    },
    onCompleted: (data) => {
      if (data.mutipleRegion) {
        enqueueSnackbar(`Successfully updated`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      }

    }
  });

  const [migrateOrganization, { loading: migrateload }] = useMutation(MIGRATE_ORG, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    onCompleted: () => {
      enqueueSnackbar("Successfully migrated", {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
      handleCloseMigrateOrg()
    }
  });

  const [makeDefault, { error: addErr }] = useMutation(MAKE_DEFAULT, {
    refetchQueries: [ORG_LIST, ALL_ORG_LIST],
    onCompleted: () => {
      setisSuccess(true)
      setselectedOrg(null)
      setOpenDefaultOrg(false)
      // if (makeDefaultTypeTrue) {
      //   makeDefault({
      //     variables: {
      //       org: selectedOrg.name.trim().toLowerCase(),
      //       defaulttype: true,
      //     }
      //   })
      // }
      setmakeDefaultTypeTrue(false)

    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    }
  });

  const [age, setAge] = React.useState('');
  const [migrateTo, setmigrateTo] = useState(null);
  const [migrateFrom, setmigrateFrom] = useState(null);
  const [inputValue, setInputValue] = React.useState([]);


  const requestSearch = (searchedVal) => {
    setSearched(searchedVal)
    const filteredRows = orgdata.organizations.nodes.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal);
    });
    setrow(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  const [searched, setSearched] = React.useState("")
  const [showMessage, setShowMessage] = React.useState(true);

  useEffect(() => {
    setrow([])
    refetch()
    if (orgdata && orgdata.organizations.nodes) {

      orgdata.organizations.nodes.forEach(element => {
        if (element.parentId === "") {
          setrow(row => [...row, element])
        }
        if (element.type === "true") {
          setdefaultOrgName(element.name)
        }
      });
    }

    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 5000);

    return () => clearTimeout(timer);

  }, [orgdata]);




  if (addErr) {
    LogTrigger(addErr.message, "error", window.location.href, "Organizations")
  }
  if (errororg) {
    LogTrigger(errororg.message, "error", window.location.href, "Organizations")
  }
  if (regerror) {
    LogTrigger(regerror.message, "error", window.location.href, "Organizations")
  }
  if (errorg) {
    LogTrigger(errorg.message, "error", window.location.href, "Organizations")
  }

  if (deleteerror) {
    LogTrigger(deleteerror.message, "error", window.location.href, "Organizations")
  }

  if (loadorg) {
    return (
      <div>

        <Skeleton variant="rectangular" height={30} width="30%" style={{ margin: "10px", borderRadius: "5px" }} />
        <Skeleton style={{ margin: "10px" }} />
        <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      </div>
    );
  }
  if (migrateload) {
    return (


      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="inherit" />

        <Typography >This might take few mintues</Typography>

        <Typography sx={{ color: "grey" }}>Please do not refresh this page</Typography>
      </Grid>

    )
  }
  return (
    <>

      <Grid item>
        <Collapse in={true}>
          <div>
            <Typography variant='body2' sx={{ fontWeight: "lighter" }} color={"primary"}>Club multiple application, workload, manage region and migrate organizations.</Typography>
          </div>
        </Collapse>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        sx={{ mb: 0 }}
      >



        <Grid item>

          <div>
            <TextField
              value={searched}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched !== "" &&
                      <IconButton onClick={cancelSearch}>
                        <CloseIcon />
                      </IconButton>}
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}

              size={"small"}
              sx={{ mb: 2 }}
              placeholder='Search for Organization'
              onChange={(e) => setSearched(e.target.value)}
            />
            {/* <Button sx={{ mx: 1 }} startIcon={<MdOutlineFilterList />} color='primary'>filter</Button> */}
          </div>

        </Grid>







        <Button onClick={handleClickOpen} disabled={role === "Viewer"} startIcon={<RiAddLine />} variant="contained" color="primary" disableElevation style={{ boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px", borderRadius: "24px", marginRight: "10px" }}>Create Organization</Button>

      </Grid>

      <Paper variant="outlined" sx={{ borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px" }}>
        <Fade in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...(true ? { timeout: 500 } : {})}>
          <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "10px" }}>
            {orgdata && orgdata.organizations.nodes && <DataGrid
              dense
              autoHeight={true}
              density={"compact"}
              scrollbarSize={1}
              rowHeight={70}
              className={classes.root}
              rows={row.filter((row) => {
                return row.name.toLowerCase().includes(searched) || row.region.toString().includes(searched);
              })}
              disableColumnFilter={true}
              columns={columns}
              loading={loadorg}
              pageSize={10}
              onCellClick={(param) => {
                if (param.colDef.field !== "actions") {
                  history.push(`/orgs?o=${param.row.name}`)
                }
              }}
              style={{ border: "2px" }}
            />
            }
          </Box>
        </Fade>



        <Dialog
          open={openDefaultOrg}
          maxWidth={"sm"}
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "12px",
              border: "solid 1px gray",
              boxShadow: ((inputValue.length < 1) ? true : false) ? "#000 5px 5px, #000 10px 10px" : "undefined"

            }
          }}
          onClose={handleCloseDefaultOrg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Edit <b>Organization</b></DialogTitle>
          <Collapse in={regloading}>
            <LinearProgress />
          </Collapse>
          {showApply && <div style={{ background: "#E4FBE6", color: "#3EAC47", borderRadius: "12px", margin: "0px 10px 0px 10px", padding: "0px 10px 0px 10px" }}><IconButton><CheckCircleIcon style={{ color: "green" }} /></IconButton> It can take upto 2 mintues to upgrade your organization</div>}
          <DialogContent>
            {row.length > 1 && selectedOrg && <div>
              Default Organization<br />
              <Switch checked={defaultOrgName === selectedOrg.name} onChange={handleNewDefaultOrg} />
              {defaultOrgName === selectedOrg.name ? <>Remove <b>{selectedOrg.name} as Default</b> Organization</> : <>Set <b>{selectedOrg.name} as Default</b> Organization</>}
              <br />
              <br />
            </div>}
            Default Region {role && role === "User" && "(You don't have permissions)"}<br />
            {datareg && selectedOrg &&
              <Autocomplete
                id="combo-box"
                size='small'
                multiple
                disabled={role && role === "User"}
                getOptionDisabled={(option) => {

                  if (inputValue && inputValue.length === 0) {
                    return null
                  }
                  if (inputValue && inputValue[0].clusterType === "byoh") {
                    return option.clusterType === "k8s" || option.clusterType === "byoh" || option.clusterType === "byoc"
                  } else {
                    return option.clusterType === "byoh"
                  }
                }}
                options={selectedOrg.region}
                getOptionLabel={(option) => option.regionName}
                onChange={(event, list, reason, detail) => {

                  setInputValue(list);
                  handleUpdateRegion(reason, detail)

                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.regionName}<span style={{ color: "#5d86f9", marginLeft: "10px", fontSize: "12px" }}> {option.clusterType === "k8s" ? "Nife" : option.clusterType}</span>
                  </li>
                )}
                value={inputValue}
                renderInput={(params) => <TextField required {...params} placeholder="Default Region" variant="outlined" />}
              />}
            {inputValue.length < 1 && <Typography variant="caption" display="block" gutterBottom color={"error"}>Default region cannot be empty</Typography>}
            {inputValue && inputValue[0] && inputValue[0].clusterType === "byoh" && <Typography sx={{ background: "#ebebeb", marginY: "10px", borderRadius: "2px" }} variant="caption" display="block" gutterBottom ><IconButton><Info sx={{ fontSize: "5px" }} /></IconButton> BYOH regions can only have one default region, remove it to add other locations</Typography>}
            <br />

          </DialogContent>
        </Dialog>

        <Dialog
          open={openMigrateOrg}
          maxWidth={"sm"}
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "12px",
              border: "solid 1px gray",
              boxShadow: (migrateTo === "" ? true : false) ? "#000 5px 5px, #000 10px 10px" : "undefined"

            }
          }}
          onClose={handleCloseMigrateOrg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{migrateTo ? "Are you sure?" : `Migrate Organization`}</DialogTitle>

          {migrateTo ?
            <DialogContent>
              <Typography>Migrate <b>{migrateFrom && migrateFrom}</b> to <b>{migrateTo && migrateTo}</b></Typography>
            </DialogContent>
            :
            <DialogContent>
              <Typography> Migrating your organization will move every <b>apps, instances and workload</b> from <b style={{ color: "#5d86f9" }}>{migrateFrom && migrateFrom}</b> to selected organization.</Typography>
              <br />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Organization</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={migrateTo}
                  label="Select Organization"
                  onChange={(e) => setmigrateTo(e.target.value)}
                >
                  {orgdata && orgdata.organizations.nodes && row.map((e, i) =>
                    (migrateFrom && migrateFrom !== e.name) && <MenuItem key={e.name} value={e.name}>{e.name}</MenuItem>
                  )}

                </Select>
              </FormControl>
            </DialogContent>}


          {migrateTo ? <DialogActions>
            <Button variant='contained' disableElevation fullWidth onClick={handleSubmitMigrate} color="error" autoFocus>
              Migrate organization
            </Button>
            <Button fullWidth onClick={() => setmigrateTo("")} color="primary" autoFocus>
              cancel
            </Button>
          </DialogActions>
            :
            <DialogActions>
              <Button onClick={handleCloseMigrateOrg} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>}
        </Dialog>


        <Dialog
          open={openDeleteOrg}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "12px",
              border: "solid 1px gray",
              boxShadow: !(selectdeleteOrg && selectdeleteOrg.name === confirmDelete) ? "#000 5px 5px, #000 10px 10px" : "undefined"

            }
          }}
          onClose={handleCloseDeleteOrg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle id="alert-dialog-title">{(selectdeleteOrg && selectdeleteOrg.name !== defaultOrgName) ? <>
            Do you want to  <b> Delete {selectdeleteOrg && selectdeleteOrg.name}</b> Organization?
          </> : "Cannot delete default Organization"}</DialogTitle>
          <DialogContent>
            {(selectdeleteOrg && selectdeleteOrg.name !== defaultOrgName) ?
              <>
                <DialogContentText id="alert-dialog-description">
                  Deleting your Organization is irreversible and will <Typography color={"primary"} component={'span'} sx={{fontWeight:"bold"}}>delete all the apps under {selectdeleteOrg && selectdeleteOrg.name} </Typography> Organization, please be certain.
                  <br />
                  <p>Please type {selectdeleteOrg && selectdeleteOrg.name} to confirm. </p>
                </DialogContentText>
                <TextField disabled={deleteloading} fullWidth id="outlined-basic" value={confirmDelete} onChange={(e) => setconfirmDelete(e.target.value)} label="Organization Name" variant="outlined" />
              </> : <DialogContentText id="alert-dialog-description"> To delete this organization first set any other organization to default organization then try to delete</DialogContentText>}
          </DialogContent>

          <Collapse in={(selectdeleteOrg && selectdeleteOrg.name === confirmDelete) ? true : false}>
            <DialogActions>

              <Button disabled={deleteloading} fullWidth onClick={handleCloseDeleteOrg} color="primary" autoFocus>
                No, Cancel
              </Button>
              <Button color={"error"} variant='contained' fullWidth disableElevation disabled={selectdeleteOrg && selectdeleteOrg.name === confirmDelete ? false : true} onClick={() => deleteOrg({ variables: { organizationId: selectdeleteOrg.id } })}>
                {deleteloading ? <CircularProgress size={20} /> : "Yes, Delete it"}
              </Button>
            </DialogActions>
          </Collapse>
        </Dialog>


        <Dialog
          open={openWarning}
          onClose={handleChange}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">  <b style={{ color: "rgb(233, 90, 90)" }}> Cannot delete default organization </b></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Deleting your Organization is irreversible and will <b style={{ color: "black" }}>delete all the apps under  </b>

            </DialogContentText>

            {selectedOrg && <Switch checked={defaultOrgName === selectedOrg.name} onChange={handleNewDefaultOrg} />}
            <TextField fullWidth id="outlined-basic" value={confirmDelete} onChange={(e) => setconfirmDelete(e.target.value)} label="Organization Name" variant="outlined" />
          </DialogContent>
          <DialogActions>
            <Button disabled={selectdeleteOrg && selectdeleteOrg.name === confirmDelete ? false : true} onClick={() => deleteOrg({ variables: { organizationId: selectdeleteOrg.id } })}>
              Yes, Delete it
            </Button>
            <Button onClick={handleCloseDeleteOrg} color="primary" autoFocus>
              No, Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',

          }}
          open={isSuccess} autoHideDuration={6000} onClose={handleCloseMsg}>
          <Alert onClose={handleCloseMsg} severity={isError ? "error" : "success"}>
            {isError ? "Somethign went worng, try again" : "Successfully Updated Default Organization!"}
          </Alert>
        </Snackbar>



        <Dialog
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "12px",
              border: "solid 1px gray",
              boxShadow: (newOrgName === "" ? true : false) ? `${theme.palette.primary.main}  5px 5px, ${theme.palette.primary.main} 10px 10px` : "undefined"

            }
          }}

          style={{ borderRadius: "25px" }} open={openorg} maxWidth={"xs"} fullWidth onClose={handleClose} aria-labelledby="form-dialog-title">
          <Grid container>

            <Grid item sm={12} xs={12}>
              {row && localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).organizationCount <= row.length ?
                <DialogContent>
                  <div style={{ marginTop: "10px" }}>
                    <Typography style={{ margin: "10px 0px" }} variant="h6" component="h6">
                      You have reached Organization limit</Typography>
                    <p style={{ color: "grey" }}> Please upgrade your plan to create Organization</p>

                    <UpgradePlan invite={true} /></div>
                </DialogContent> :
                <div>
                  <DialogTitle style={{ paddingBottom: "0px" }}>Create <b>New Organization</b></DialogTitle>
                  <div>
                    <DialogContent>


                      <TextField
                        variant='outlined'
                        label="Enter Organization name"
                        value={newOrgName}
                        fullWidth={true}
                        size='small'
                        onChange={handleOrgName}
                        error={!validOrgName}
                        helperText={!validOrgName && "Invalid Organization name"}
                        autoFocus />

                    </DialogContent>
                  </div>
                  <Collapse in={newOrgName === "" ? false : true}>
                    <DialogActions>
                      <Button fullWidth onClick={handleClose}> Cancel </Button>
                      <Button
                        disabled={!validOrgName}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleCreateNewOrg}
                        disableElevation>{isProgress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Create"}</Button>
                    </DialogActions>
                  </Collapse>
                </div>}
            </Grid>
          </Grid>


        </Dialog>



        <Dialog
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "12px",
              border: "solid 1px gray",
              boxShadow: (newOrgName === "" ? true : false) ? "#000 5px 5px, #000 10px 10px" : "undefined"

            }
          }}
          style={{ borderRadius: "25px" }} open={opensuborg} fullWidth maxWidth={"xs"} onClose={handleCloseSub} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ paddingBottom: "0px" }}>
            {isProgress ? <> Creating <b>{dailogtype === "suborg" ? "Sub-Organization" : "Business Unit"} {newOrgName}...</b><CircularProgress size="15px" /></>
              :
              <>


                Create <b>{dailogtype === "suborg" ? "Sub-Organization" : "Business Unit"}</b> inside <b>{age}</b>          </>}</DialogTitle>


          <Grid container>
            <Grid item sm={12} xs={12}>

              {(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).subOrganizationCount === 0) ?
                <UpgradePlan /> :


                <div>
                  {(errorbunit && errorbunit.message === "You've reached your maximum limit of current plan. Please upgrade your plan to create Business Unit") || (errorsuborg && errorsuborg.message === "You've reached your maximum limit of current plan. Please upgrade your plan to create Sub-Organization") ?
                    <div><></>
                      <span style={{ color: "grey" }}>You've reached your maximum limit of current plan. Please upgrade your plan to create {dailogtype === "suborg" ? "Sub-Organization" : "Business Unit"}</span>
                      <UpgradePlan invite={true} />

                    </div> :
                    <div>
                      <DialogContent>

                        <TextField
                          variant='outlined'
                          label={dailogtype === "suborg" ? "Sub-Organization Name" : "Business Unit Name"}
                          placeholder={dailogtype === "suborg" ? "Enter Sub-Organization Name" : "Enter Business Unit Name"}
                          value={newOrgName}
                          fullWidth={true}
                          size="small"

                          disabled={isProgress}
                          style={{ marginTop: "10px" }}
                          onChange={handleOrgName}
                          error={!validOrgName}
                          helperText={!validOrgName && (dailogtype === "suborg" ? "Invalid Sub-Organization Name" : "Invalid Business Unit Name")}
                        />


                      </DialogContent>

                      <Collapse in={newOrgName === "" ? false : true}>
                        <DialogActions>
                          <Button disabled={isProgress} fullWidth onClick={handleCloseSub}> Cancel </Button>
                          <Button
                            disabled={!validOrgName || isProgress}
                            fullWidth

                            variant="contained"
                            color="primary"

                            onClick={handleCreateNewSubOrg}
                            disableElevation>{"Create"}</Button>
                        </DialogActions>
                      </Collapse>
                    </div>}
                </div>}
            </Grid>
          </Grid>

        </Dialog>
      </Paper>
    </>
  );
}

export default Organization;