import NorthWestIcon from '@mui/icons-material/NorthWest';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";

import { useRouteMatch } from "react-router-dom";

import { useMutation, useQuery } from '@apollo/client';
import { Chip, Collapse, useTheme , InputLabel, LinearProgress, MenuItem, Select, Skeleton, Slide, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { FaCheckCircle, FaSlack } from 'react-icons/fa';
import { GoCheck, GoDotFill } from 'react-icons/go';
import { IoIosRocket, IoMdColorFilter } from 'react-icons/io';
import { TiDelete, TiLocationArrow } from 'react-icons/ti';
import Nav, { USER_ACTIVITY } from '../Settings/Nav';
import { MARK_READ } from '../Settings/Notification';
import { BsDot } from 'react-icons/bs';
import { Box } from '@mui/system';
import { VscError } from 'react-icons/vsc';
import { Check, Delete, MarkChatReadOutlined } from '@mui/icons-material';
import { MdFilterList } from 'react-icons/md';
import SlackNotification from '../SlackNotification';
import ExpiredPlanAlert from '../../Tools/ExpiredPlanAlert';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: 3,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        paddingLeft: "10%",
        padding: 5,
        paddingTop: "3%",
    },
    subtitle: {
        flexGrow: 1,
        paddingLeft: "10%",
        padding: 5,
    },
    content: {
        flexGrow: 1,
        height: '99vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: "auto",
    },
    header: {
        height: 150,
    },
    card: {
        margin: "0px",
    },
    header: {
        borderRadius: "12px",
        margin: "10px 24px 24px 24px",
        boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px",
        paddingRight: "10px",
        paddingLeft: "10px",
    },
    headline: {

        fontSize: "18px",
        margin: "10px"
    },
    link: {
        display: 'flex',
        textDecoration: "none",
        color: '#5d86f9'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },


}));

export const selectIcon = (value) => {
    switch (value) {
        case "SCALED": return <NorthWestIcon />;
        case "TERMINATED": return <TiDelete />;
        case "DELETED": return <Delete />;
        case "DEPLOYED": return <FaCheckCircle />;
        case "REDEPLOYED": return <FaCheckCircle />;
        case "RUNNING": return <IoIosRocket />;
        case "MOVED": return <TiLocationArrow />;
        case "RESUMED": return <GoCheck />;
        case "stopping": return <TiDelete />;
        case "pending": return <GoDotFill />;
        case "SUSPENDED": return <TiDelete />;
        case "CREATED": return <Check />;
        default: return null
    }
}

export const selectTypeColor = (value) => {
    switch (value) {
        case "APP": return "#e3f2fd";
        case "APP-ERROR": return "#FDF6F6";
        case "SITE": return "#fab14d85";
        case "ORGANIZATION": return "#dcbafd";
        case "SUB ORGANIZATION": return "#bcbafd";
        case "BUSINESSUNIT": return "#f5bafd";
        case "INSTANCE": return "#bafdc8";
        case "SECRET": return "#fde3e3";
        case "WORKLOAD": return "#fdc7ba";
        case "BYOC REGION": return "#ffefb1"
        default: return null
    }
}

export default function Activity() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedFilterActivity, setselectedFilterActivity] = React.useState(0);
    const [value, setValue] = React.useState(0);

    const [showFilter, setshowFilter] = React.useState(true);




    const { loading: loadact, error: erract, data: userdataact, refetch: refetchaact } = useQuery(USER_ACTIVITY, {

        variables: { first: 100 },
        onCompleted: () => {
        }
    });
    const [markRead, { loading: markloading }] = useMutation(MARK_READ, {
        refetchQueries: [USER_ACTIVITY],
        onError: (err) => {
        },
    });




    const handleRead = () => {
        var ids = []
        if (userdataact) {
            userdataact.userActivities.forEach(element => {
                ids.push(element.id)
            });
        }

        markRead({ variables: { id: ids } })
    }

    useEffect(() => {
        document.title = "Activity"
        refetchaact()
    }, [value, selectedFilterActivity]);



    if ( localStorage.getItem("expiredaccount")) {
        return (<>
            <Nav />
            <ExpiredPlanAlert />
        </>)
      }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Nav />
                <Container maxWidth={false} className={classes.container}>






                    <Typography variant='body2' sx={{ fontWeight: "lighter", mb: 1 }} color={"primary"}>Notifications of user activity </Typography>





                    {!loadact && <div>


                        {userdataact && userdataact.userActivities.length === 0 ? <span style={{ margin: "20px" }} >
                            <b style={{ color: "black" }}>No data </b> <br /> <span style={{ marginLeft: "20px", paddingBottom: "20px" }}>Please do some activity and come back</span>
                        </span> :

                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <div>
                                  

                               <SlackNotification/>
                                </div>


                                <div>

                                <Button sx={{mx:1}} onClick={() => { setshowFilter(!showFilter); setValue(0); setselectedFilterActivity(0) }} startIcon={<MdFilterList />} >{showFilter ? "Close filter" : "Filter"}</Button>


                                    <Button startIcon={<MarkChatReadOutlined />} variant='contained' sx={{ mx: 1, borderRadius: "24px", boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px" }} onClick={handleRead}>Mark All as Read</Button>


                                </div>

                            </Grid>}
                    </div>}

                    <Collapse in={markloading}>
                        <LinearProgress />
                    </Collapse>

                    <Grid container spacing={2}>
                        <Grid item xs={showFilter ? 8 : 12}>
                            <Paper variant='outlined' style={{ marginTop: "10px", borderRadius: "12px" }} >

                                {loadact && <div>
                                    <Skeleton style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px", opacity: "50%" }} />
                                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px", opacity: "20%" }} />
                                </div>}

                                {userdataact && userdataact.userActivities.filter((e, i, arr) => {
                                    if (value !== 0) {
                                        return e.type === value
                                    }
                                    else {
                                        return arr
                                    }

                                }).length === 0 && <>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            sx={{ m: 2 }}
                                        >

                                            <IconButton><VscError style={{ fill: "#160044" }} /></IconButton>

                                            <Typography sx={{ m: 2 }}>No recent activities</Typography>
                                        </Grid>
                                    </>}

                                {userdataact && userdataact.userActivities.filter((e, i, arr) => {

                                    if (value !== 0 && selectedFilterActivity === 0) {
                                        return e.type === value
                                    }
                                    if (value !== 0) {
                                        return e.type === value && e.activities === selectedFilterActivity
                                    }
                                    if (value === 0 && selectedFilterActivity === 0) {
                                        // returns all
                                        return arr;
                                    }

                                    else {
                                        return e.type && e.activities === selectedFilterActivity
                                    }

                                }).map((e, i) => (
                                    <div key={e.id}>
                                        <ListItem alignItems="flex-start" style={{ marginLeft: "5px" }}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {e.message.slice(0, 1)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                secondary={<React.Fragment>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="end"
                                                    >


                                                        <Box sx={{ mt: "5px" }}>
                                                            <Chip size='small' sx={{ background: selectTypeColor(e.type), color: '#000000', marginRight: "10px" }} label={e.type} />

                                                            <Chip size='small' variant="outlined" label={e.activities}

                                                                icon={selectIcon(e.activities)}



                                                            />


                                                        </Box>
                                                        {!e.isRead &&
                                                            <Tooltip title="Unread message">
                                                                <BsDot style={{ fontSize: "42px", color: "#2196f3" }} />

                                                            </Tooltip>}
                                                    </Grid>
                                                </React.Fragment>}
                                                primary={
                                                    <span>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="end"
                                                        >

                                                            <Typography wrap>{e.message}</Typography>


                                                            <span style={{ color: "grey", fontSize: "13px" }}>

                                                                {moment(new Date(e.createdAt), "YYYY MM DD hh:mm:ss").fromNow()}
                                                            </span>
                                                        </Grid>
                                                    </span>

                                                }
                                            />

                                        </ListItem>
                                        <Divider />
                                    </div>
                                ))}


                            </Paper>
                        </Grid>
                        {showFilter && <Slide in={showFilter} direction="left" timeout={500}>
                            <Grid item xs={showFilter ? 4 : 0}>
                                <Paper variant='outlined' style={{ marginTop: "10px", borderRadius: "12px", padding: "16px" }} >
                                    <Typography sx={{ my: 1 }}>
                                        Filter by type
                                    </Typography>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                        sx={{ mb: 2 }}
                                    >
                                        <Grid item> <Button size='small' variant={value === 0 ? "contained" : "text"} onClick={() => setValue(0)}>Show All</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "APP" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("APP"); }}>App</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "SITE" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("SITE"); }}>SITE</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "ORGANIZATION" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("ORGANIZATION"); }}>Organization</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "SECRET" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("SECRET"); }}>Secret</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "WORKLOAD" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("WORKLOAD"); }}>Workload</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "BYOC REGION" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("BYOC REGION"); }}>BYOC Region</Button></Grid>
                                        <Grid item> <Button size='small' variant={value === "APP-ERROR" ? "contained" : "text"} onClick={() => { setselectedFilterActivity(0); setValue("APP-ERROR"); }}>App Error</Button></Grid>
                                    </Grid>
                                    <Divider />
                                    <Typography sx={{ my: 1 }}>
                                        Filter by activity
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item><Button variant={selectedFilterActivity === 0 ? "contained" : "text"} onClick={() => setselectedFilterActivity(0)} size='small'>show all</Button></Grid>
                                        {Array.from(new Set(userdataact && userdataact.userActivities.filter((e) => { return e.type === value }).map(item => item.activities))).map((e) => {
                                            return <Grid item><Button variant={selectedFilterActivity === e ? "contained" : "text"} onClick={() => setselectedFilterActivity(e)} size='small'>{e}</Button></Grid>
                                        })}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Slide>}
                    </Grid>

                </Container>
            </main>
        </div>
    );
}