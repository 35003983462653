// comapnay name goes here
export const CONFIG_APP_BASE_COMPANY = 'nife';
export const CONFIG_APP_IS_PRODUCTION = true;

export const CONFIG_APP_METRICS_BASE_URL = "https://metrics.nife.clb2.nifetency.com";
export const CONFIG_APP_PRIMARY_LIGHT_COLOR = CONFIG_APP_BASE_COMPANY === "nife" ? "#160044" : '#89553e';
export const CONFIG_APP_PRIMARY_DARK_COLOR = CONFIG_APP_BASE_COMPANY === "nife" ? "#e3f2fd" :  '#C59C9A';


export const CONFIG_APP_BASE_API = CONFIG_APP_IS_PRODUCTION ? 'https://api.nife.io' : 'https://devapi.nife.io';
export const CONFIG_APP_SUPABASE_CLIENT = 'https://oswanyebdtidlhzomuds.supabase.co';
export const CONFIG_APP_SUPABASE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9zd2FueWViZHRpZGxoem9tdWRzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDU1MzExMTIsImV4cCI6MTk2MTEwNzExMn0.4HL4HKLc_7uje9p8Cl2ORUIZwRvWDO0Udp0jQSzTGzY';
export const CONFIG_APP_GOOGLE_LOGIN_CLIENT = "570713914591-fhpqlkq1bobjhp438ispf4bgdvbvtqg7.apps.googleusercontent.com";
