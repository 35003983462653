import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from "axios"
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { StyledButton } from './Login';
import { Paper } from '@mui/material';
import { CONFIG_APP_BASE_API } from '../configApp';
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "25px",
    borderTopLeftRadius: "25px",
    aspectRatio: "1/1",
  },
  gridcontainer: {
    backgroundColor: 'transparent',
    width: 'auto',
    borderRadius: "25px",
    boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",
  },
  divcomponent: {
    padding: 30,
    textAlign: "center",
  },
  textpadding: {
    padding: 10,
    color: " gray",
  },
  link: {
    textAlign: "center",
    margin: "5px",
  },
  hr: {
    marginTop: "10px",
    marginBottom: "50px",
    color: "#ffe",
    height: 0,
  },
}));


const useStylesCustomizeTextField = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 25,
    height: '48px',
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: 'transparent',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

export function CustomizeTextField(props) {
  const classes = useStylesCustomizeTextField();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}


function ForgetPassword() {
  const classes = useStyles();
  const [emailValidation, setEmailValidation] = React.useState(false)
  const [isSuccess, serIsSuccess] = React.useState(false)
  const [isError, setIsError] = React.useState("gray")
  const [isProgress, setIsProgress] = React.useState(false)
  const [msg, setMsg] = React.useState("We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!")
  const valuevalidateEmail = (e) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const checkvalue = re.test(e.target.value.toLowerCase())
    if (checkvalue.length > 0) {
      setEmailValidation(false)
    }
    setEmailValidation((checkvalue) ? false : true)
  }
  const [email, setEmail] = React.useState("")
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProgress(true)
    const headers = {
      'Content-Type': 'application/json'
    }
    const data = {
      'email': email
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/forget_password`, data, { headers: headers }).then((res) => {
      setMsg("We have sent you a magic link to reset your password!")
      setIsError("green")
      serIsSuccess(true)
      setIsProgress(false)

    }, (err) => {

      if (err.response) {
        setMsg("Email does not exist!")
        setIsError("red")
        setIsProgress(false)
      }
      else {
        setMsg("Somthing went worng")
        setIsError("red")
        setIsProgress(false)
      }


    })

  }
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />



      <div className={classes.paper}>
        <Paper variant='outlined' sx={{ borderRadius: "24px" }}>
          <Grid container className={classes.gridcontainer}>
            <Grid component={Box} item xs={4} sm={6} md={6} display={{ xs: "none", lg: "block" }}>
              <input type="image" alt='no image' src={"https://res.cloudinary.com/dfxupfsej/image/upload/v1624941928/photo-1517519014922-8fc06b814a0e_aydc1f.jpg"} height="100%" width="100%" className={classes.image} />

            </Grid>
            <Grid item xs={null} sm={6} md={6} className={classes.divcomponent}>

              <Typography component="h1" variant="h5">
                Forgot Your Password?
              </Typography>
              {isSuccess && <Box sx={{ marginTop: "20px" }} size="large">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </Box>}
              <Typography variant="body1" style={{ color: isError }} gutterBottom className={classes.textpadding}>

                {msg}
              </Typography>{!isSuccess &&
                (<div>
                  <form className={classes.form} onSubmit={e => handleSubmit(e)}>
                    <CustomizeTextField
                      label="Enter Email Address"
                      name="email"
                      value={email}
                      onChange={(e) => { setEmail(e.target.value); valuevalidateEmail(e) }}
                      className={classes.margin}
                      variant="filled"
                      id="text-input"
                      required={true}
                      error={emailValidation}
                      helperText={emailValidation && "Invalid email"}
                      fullWidth
                    />
                    <Button variant='contained' fullWidth sx={{ borderRadius: "24px", height: 48, my: 2 }} disableElevation type="submit" disabled={emailValidation}>{isProgress && <CircularProgress style={{ color: "white" }} size="20px" />}Reset Password</Button>

                  </form>  </div>)}


              <hr className={classes.hr} />
              <Grid wrap="wrap" container direction="column">
                <Button component={Link} to="/register" size='medium' sx={{ textTransform: "inherit", mb: 2 }} color='primary' >
                  Create an Account!
                </Button>
                {/* <Button style={{ color: "#000", textTransform: "none" }} >
                  <Link style={{ textTransform: "none" }} to="/register" className={classes.link}>
                    Create an Account!
                  </Link>
                </Button> */}
                <Button component={Link} to="/" size='medium' sx={{ textTransform: "inherit", mb: 2 }} color='primary' >
                  Already have an account? Login!
                </Button>
                {/* <Button style={{ color: "#2653d4", textTransform: "none" }} className={classes.link}>
                  <Link style={{ textTransform: "none" }} to="/" className={classes.link}>
                    Already have an account? Login!
                  </Link>
                </Button> */}
              </Grid>



            </Grid>
          </Grid>
        </Paper>
      </div>


    </Container>
  );
}

export default ForgetPassword;