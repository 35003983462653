import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCloudUpload } from 'react-icons/md';
import { useTheme } from '@mui/material/styles';
import { generateUUID } from '../Components/Home/BYOClocations';
import axios from 'axios';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { CONFIG_APP_BASE_API } from '../configApp';

const FileUploader = ({ maxSizeInBytes, setgcpUrl }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setuploading] = useState(false);
  const theme = useTheme();

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    setIsDragging(false);

    if (rejectedFiles.length > 0) {
      console.error('Invalid file type or exceeds size limit.');
      return;
    }

    const validFiles = acceptedFiles.filter(
      (file) => file.size <= maxSizeInBytes
    );

    if (validFiles.length === 0) {
      console.error('All files exceed size limit.');
      return;
    }

    // Read and display some information about the first file
    const file = validFiles[0];
    const fileInfo = await readFileInfo(file);
    uploadFunction(validFiles)
    setUploadedFiles([{ ...fileInfo, file }]);
  }, [maxSizeInBytes]);


  const readFileInfo = async (file) => {
    return {
      name: file.name,
      size: file.size,
    };
  };

  const uploadFunction = async (e) => {
    setuploading(true)
    if (e[0]) {
      await axios
        .post(`${CONFIG_APP_BASE_API}/api/v1/finOpsGcpKey/${generateUUID()}`, e[0], { headers: { "Content-Type": e.type } })
        .then((response) => {
          setgcpUrl(response.data.s3Link)
          setuploading(false)
        })
        .catch((error) => {
          setuploading(false)
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.zip', '.tar'],
    maxSize: maxSizeInBytes,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
  });

  return (
    <div>
       {uploading ?
      <Box sx={{textAlign:"center"}}>
      <MdCloudUpload style={{ fontSize: '6em', color: theme.palette.primary.main }} />
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={20}/>
          <Typography sx={{ fontWeight: "bold", fontSize: "15px",ml:1 }}>  Uploading...</Typography>
        </Grid> 
        </Box>
:
      <div {...getRootProps()} style={{ ...dropzoneStyles, borderColor: isDragging ? 'green' : '#cccccc', boxShadow: isDragging ? "rgb(89, 255, 132) 0px 30px 50px 5px" : "none" }}>
        <input {...getInputProps()} />
        <div>
          <span role="img" aria-label="Upload Icon" style={{ fontSize: '3em' }}>
            <MdCloudUpload style={{ fontSize: '2em', color: theme.palette.primary.main }} />
          </span>
        </div>
        <p>
          {isDragging ? 'Release your mouse to upload the file' : 'Drag & drop files here, or click to select files'}
        </p>
      </div>}

   
       




    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'border 0.3s ease-in-out',
};

export default FileUploader;
