import { Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { FaExpand } from 'react-icons/fa';
import { useRouteMatch } from 'react-router-dom';
import ExpandMetrics from '../../../Deployments/Metrics/ExpandMetrics';
import { CONFIG_APP_METRICS_BASE_URL } from '../../../../configApp';


const list = [
    {
        "type": "http",
        "title": "http_response_count_total ",
        "desc": "Total http usage percentage across all processes and containers.",
        "panelId": 7
    },
    {
        "type": "http",
        "title": "http_response_size_bytes ",
        "desc": "Total http usage percentage across all processes and containers.",
        "panelId": 8
    },
    {
        "type": "parse",
        "title": "parse_errors_total ",
        "desc": "Total CPU usage percentage across all processes and containers.",
        "panelId": 9
    },
];

// Define Iframe component
const IframeComponent = ({ appName, panelId, timeRange, title, desc }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { from, to } = timeRange;
    const iframeSrc = `${CONFIG_APP_METRICS_BASE_URL}/d-solo/edzkn04yxem0we/nginx?orgId=1&var-container=${appName}&from=${from}&to=${to}&theme=${theme ? theme.palette.mode : "light"}&panelId=${panelId}`;

    return (
        <>
            {open ? (
                <ExpandMetrics title={title} desc={desc} open={open} onClose={handleClose}>
                    <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="80%" title={`panel-${panelId}`}></iframe>
                </ExpandMetrics>
            ) : (
                <>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant='h6' sx={{ textTransform: "capitalize", mt: 1 }}>{title}</Typography>
                        <IconButton variant="outlined" onClick={handleClickOpen}>
                            <FaExpand />
                        </IconButton>
                    </Grid>
                    <Typography style={{ color: "grey", marginBottom: "10px" }} variant='body2'>{desc}</Typography>
                    <iframe src={iframeSrc} frameBorder={0} style={{ borderRadius: "12px" }} width="99%" height="200" title={`panel-${panelId}`}></iframe>
                </>
            )}
        </>
    );
};

// Define SiteMetricsDisplay component
const SiteMetricsDisplay = ({ appName, from, to, panelId }) => {
    const iframeSrc = `${CONFIG_APP_METRICS_BASE_URL}/d-solo/edzkn04yxem0we/nginx?orgId=1&var-app=${appName}&from=${from}&to=${to}&panelId=${panelId}`;

    return (
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h6' sx={{ textTransform: "capitalize", mt: 1 }}>
                Site Metrics
            </Typography>
            <iframe
                src={iframeSrc}
                width="450"
                height="200"
                frameBorder="0"
                style={{ borderRadius: "12px", marginTop: "10px" }}
                title={`site-metrics-${panelId}`}
            ></iframe>
        </Grid>
    );
};

// Time range options
const timeOptions = [
    { label: 'Last 30 minutes', value: 30 * 60 * 1000 },
    { label: 'Last 1 hour', value: 60 * 60 * 1000 },
    { label: 'Last 6 hours', value: 6 * 60 * 60 * 1000 },
    { label: 'Last 12 hours', value: 12 * 60 * 60 * 1000 },
    { label: 'Last 24 hours', value: 24 * 60 * 60 * 1000 },
    { label: 'Last 7 days', value: 7 * 24 * 60 * 60 * 1000 },
    { label: 'Last 30 days', value: 30 * 24 * 60 * 60 * 1000 },
];

// Define GraphsDisplay component
export default function GraphsDisplaySiteMetrics() {
    const [filterValue, setFilterValue] = useState("http");
    const [timeRange, setTimeRange] = useState({ from: Date.now() - (30 * 60 * 1000), to: Date.now() });
    const match = useRouteMatch();


    const handleTimeRangeChange = (event) => {
        const selectedValue = event.target.value;
        setTimeRange({
            from: Date.now() - selectedValue,
            to: Date.now(),
        });
    };



    return (
        <div>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Typography variant="h4" sx={{ p: 4, pl: 0 }} component="h1">System Performance</Typography>
                </Grid>
                <Grid item>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Time Range</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            label="Time Range"
                            value={timeRange.to - timeRange.from}
                            onChange={handleTimeRangeChange}
                        >
                            {timeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ mb: 2 }}>
                <Grid item>
                    <Chip variant={filterValue === "" ? "filled" : "outlined"} label="Show All" color="primary" onClick={() => setFilterValue("")} />
                </Grid>
                <Grid item>
                    <Chip variant={filterValue === "http" ? "filled" : "outlined"} label="Http (2 Charts)" color="primary" onClick={() => setFilterValue("http")} />
                </Grid>
                <Grid item>
                    <Chip variant={filterValue === "parse" ? "filled" : "outlined"} label="Parse (1 Charts)" color="primary" onClick={() => setFilterValue("parse")} />
                </Grid>
   
            </Grid>

            <Grid container spacing={2}>
                {list.length && list.map((e) => {
                    if (e.type === filterValue || filterValue === "") {
                        return (
                            <Grid item xs={12} sm={12} md={12} key={e.panelId}>
                                <IframeComponent
                                    appName={match.params.id}
                                    title={e.title}
                                    desc={e.desc}
                                    panelId={e.panelId}
                                    timeRange={timeRange}
                                />
                            </Grid>
                        );
                    }
                    return null;
                })}

                {/* Add the SiteMetricsDisplay component */}
                {/* <SiteMetricsDisplay
                    appName="catpajamas_site_6651"  // Example app name
                    from={Date.now() - 3600 * 1000}  // 1 hour ago
                    to={Date.now()}                   // Current time
                    panelId={7}                      // Example panel ID
                /> */}
            </Grid>
        </div>
    );
}
