import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { BiWorld } from 'react-icons/bi';

export default function CustomDomain({ data }) {
    const [showDetails, setShowDetails] = React.useState(false);

    const handleDialogClose = () => {
        setShowDetails(false);
    };

    return (
        <div>
            <h3>Custom Domain</h3>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>
                  
                        Please click on the button to add your custom domain.
            
                </Typography>
                <Button
                    variant='contained'
                    startIcon={<BiWorld />}
                    onClick={() => {
                        setShowDetails(!showDetails);
                    }}
                >
                    {"Add Custom Domain"}
                </Button>
            </Grid>

            <Dialog open={showDetails} onClose={handleDialogClose}>
                <DialogTitle>Custom Domain Details</DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>Please Note:</b> You need to make sure that the following records are added to your domain's DNS records
                    </Typography>
                    <Box sx={{ borderRadius: "12px", bgcolor: "#ededed", color: '#000000', px: 2, py: 0.5, mt: 1 }}>
                        <pre>{`Record Type: CNAME

Name: You can add any name

Value: ${data}

TTL (optional): doesn't matter
`}</pre>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
