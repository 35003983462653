import axios from "axios"
import LogTrigger from "../LogTrigger"
import { LOGIN, REGISTER, REGISTER_ERROR, CENTER, LOGIN_ERROR, LOGOUT, REGENERATE, GOOGLEOBJ, LOGIN_AGAIN, RESET_MSG, GITHUBOBJ } from './types'
import { CONFIG_APP_BASE_API } from "../configApp"




export const login = (data, checked, postlogin) => (dispatch) => {

    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/login`, data, { headers: headers }).then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data.attributes.permissions))
        localStorage.setItem("role", res.data.data.attributes.role)
        localStorage.setItem("access", JSON.stringify(res.data.data.attributes.PlanAndPermission))
        if ((res.data.data.attributes.role === "User" || res.data.data.attributes.role === "Viewer") && res.data.data.attributes.userProfileCreated === false) {
            window.location.assign("/complete-profile")
        }
        if (res.data.data.attributes.FirstTimeUser) {
            localStorage.setItem("showUserGuide", true)
        }
        if (res.data.data.attributes.isUserInvited) {
            localStorage.setItem("expiredaccount", true)
        }
        dispatch({
            type: LOGIN,
            payload: res.data.data.attributes
        })

        localStorage.setItem("refresh", res.data.data.attributes.refresh_token)

        postlogin()
        LogTrigger("User logged in via standard login", "info", window.location.href, "Login")

        billingInformation(res.data.data.attributes.customerStripeId)

    }, (err) => {
        LogTrigger(err.response && err.response.data.message, "error", window.location.href, "Login")
        postlogin()
        if (err.response) {
            if (err.response.data.message === "Email not verified!") {
                sendOTP(parseInt(err.response.data.userId))
                dispatch({
                    type: LOGIN_ERROR,
                })
            }

      
            if (err.response.data.message === "Cannot find customer in stripes with provided email!") {
                window.location.assign("/plans")
                dispatch({
                    type: LOGIN_ERROR,
                    payload: "Cannot find customer in stripes with provided email",
                })
            }
            if (err.response.data.message === "Cannot find customer in payment partner with provided email!" || err.response.data.message === "Cannot find active subscription for the provided email!") {
                localStorage.setItem("userId", err.response.data.UserId)
                window.location.assign("/plans")
                dispatch({
                    type: LOGIN_ERROR,
                })
            }

            if (err.response.data.message === "Invalid User") {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: "Invalid credentials"
                })
            }

            if (err.response.data.message === "sql: no rows in result set") {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: "No account found with that email"

                })


            }
            if (err.response.data.message === "Your 14-day trial period has ended. Please upgrade your plan"){
                window.location.assign("/plans?freetrail=expired")
            }
            if (err.response.data.message === "Cannot find active subscription for the provided email!") {
                window.location.assign("/plans?upgrade=plan")
                dispatch({
                    type: LOGIN_ERROR,
                    payload: "Your account subscription has expired, please renew it "
                })
            }

        }
        else {
            dispatch({
                type: LOGIN_ERROR,
                payload: "Something went wrong, try again later"
            })
        }




    }
    )
}


export const githublogin = (data, handleResponse) => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoLogin`, data, { headers: headers }).then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data.attributes.permissions))
        localStorage.setItem("role", res.data.data.attributes.role)
        localStorage.setItem("access", JSON.stringify(res.data.data.attributes.PlanAndPermission))
        if (res.data.data.attributes.role === "User" && res.data.data.attributes.userProfileCreated === false) {
            // window.location.assign("/complete-profile")
        }
        if (res.data.data.attributes.isUserInvited) {
            localStorage.setItem("expiredaccount", true)
        }

        dispatch({
            type: LOGIN,
            payload: res.data.data.attributes
        })

        LogTrigger("User logged in via github ", "info", window.location.href, "Login")
        billingInformation(res.data.data.attributes.customerStripeId)

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Github Login")
        if (err.response.data.message === "Invalid User") {
            dispatch({ type: GITHUBOBJ, payload: { 'code': data.accessToken, 'email': err.response.data.email, 'name': err.response.data.username } })
            handleResponse("Register")
        }
        if (err.response.data.message === "Cannot find customer in stripes with provided email!") {
            window.location.assign("/plans")

            dispatch({
                type: LOGIN_ERROR,
            })


        }
        if (err.response.data.message === "User haven't subscribe for any plan") {
            window.location.assign("/plans")
            dispatch({
                type: LOGIN_ERROR,
            })
        }
        if (err.response.data.message === "sql: no rows in result set") {
            window.location.assign("/github-register")

            dispatch({
                type: LOGIN_ERROR,
                payload: "No account found with that email"

            })


        }
        if (err.response.data.message === "Please Add the username in the github") {

            dispatch({
                type: LOGIN_ERROR,
                payload: "Please Add the username in your github account"

            })


        }
        if (err.response.data.message === "Your 14-day trial period has ended. Please upgrade your plan"){
            window.location.assign("/plans?freetrail=expired")
        }
        else {

            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
        }

    }
    )
}

export const bitbucketlogin = (data, handleResponse) => (dispatch) => {

    const headers = {
        'Content-Type': 'application/json',
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoLogin`, data, { headers: headers }).then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data.attributes.permissions))
        localStorage.setItem("role", res.data.data.attributes.role)
        localStorage.setItem("access", JSON.stringify(res.data.data.attributes.PlanAndPermission))
        if (res.data.data.attributes.role === "User" && res.data.data.attributes.userProfileCreated === false) {
            // window.location.assign("/complete-profile")
        }
        if (res.data.data.attributes.isUserInvited) {
            localStorage.setItem("expiredaccount", true)
        }
        dispatch({
            type: LOGIN,
            payload: res.data.data.attributes
        })
        LogTrigger("User logged in via bitbucket ", "info", window.location.href, "Login")

        billingInformation(res.data.data.attributes.customerStripeId)

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Github Login")
        if (err.response.data.message === "Invalid User") {
            dispatch({ type: GITHUBOBJ, payload: { 'code': data.accessToken, 'email': err.response.data.email, 'name': err.response.data.username } })
            handleResponse("Register")
        }
        if (err.response.data.message === "Cannot find customer in stripes with provided email!") {
            window.location.assign("/plans")

            dispatch({
                type: LOGIN_ERROR,
            })


        }
        if (err.response.data.message === "sql: no rows in result set") {
            window.location.assign("/bitbucket-register")

            dispatch({
                type: LOGIN_ERROR,
                payload: "No account found with that email"

            })


        }
        if (err.response.data.message === "Please Add the username in the github") {

            dispatch({
                type: LOGIN_ERROR,
                payload: "Please Add the username in your github account"

            })


        }
        if (err.response.data.message === "Your 14-day trial period has ended. Please upgrade your plan"){
            window.location.assign("/plans?freetrail=expired")
        }
        else {

            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
        }

    }
    )
}

export const gitlablogin = (data, handleResponse) => (dispatch) => {

    const headers = {
        'Content-Type': 'application/json',
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoLogin`, data, { headers: headers }).then((res) => {
        localStorage.setItem("permissions", JSON.stringify(res.data.data.attributes.permissions))
        localStorage.setItem("role", res.data.data.attributes.role)
        localStorage.setItem("access", JSON.stringify(res.data.data.attributes.PlanAndPermission))
        if (res.data.data.attributes.role === "User" && res.data.data.attributes.userProfileCreated === false) {
            window.location.assign("/complete-profile")
        }
        if (res.data.data.attributes.isUserInvited) {
            localStorage.setItem("expiredaccount", true)
        }

        dispatch({
            type: LOGIN,
            payload: res.data.data.attributes
        })

        LogTrigger("User logged in via gitlab ", "info", window.location.href, "Login")

        billingInformation(res.data.data.attributes.customerStripeId)

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Gitlab Login")
        if (err.response.data.message === "Invalid User") {
            dispatch({ type: GITHUBOBJ, payload: { 'code': data.accessToken, 'email': err.response.data.email, 'name': err.response.data.username } })
            handleResponse("Register")
        }
        if (err.response.data.message === "Cannot find customer in stripes with provided email!") {
            window.location.assign("/plans")

            dispatch({
                type: LOGIN_ERROR,
            })


        }
        if (err.response.data.message === "sql: no rows in result set") {

            dispatch({
                type: LOGIN_ERROR,
                payload: "No account found with that email"

            })


        }
        if (err.response.data.message === "Please Add the username in the gitlab") {

            dispatch({
                type: LOGIN_ERROR,
                payload: "Please Add the username in your github account"

            })


        }
        if (err.response.data.message === "Your 14-day trial period has ended. Please upgrade your plan"){
            window.location.assign("/plans?freetrail=expired")
        }
        else {

            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
        }

    }
    )
}

export const restMsg = () => (dispatch) => {
    dispatch({
        type: RESET_MSG,
    })
}

const saveUserAsContact = (value) => {
    const headers = {
        'api-token': '1795baa14b413b8fa1ad0704a489c3afc016f24c0d4c4715750ef5078b7fcdf46d1926ae',
    }
    var data = {
        "contact": {
            "email": `${value.email}`,
            "fieldValues": [
                {
                    "field": "6",
                    "value": "2008-01-20"
                }
            ]
        }
    }


    LogTrigger("Saving User as Contact ", "info", window.location.href, "Register")
    axios.post(`https://nife.api-us1.com/api/3/contacts`, data, { headers: headers }).then((res) => {

        LogTrigger("Contact saved", "info", window.location.href, "Register")
    }, (err) => {
        LogTrigger(err, "error", window.location.href, "OTP")
    })

}
const sendOTP = (id) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    var data = { "id": `${id}` }
    LogTrigger("OTP sent for email verification ", "info", window.location.href, "Login")
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/sendVerificationCode`, data, { headers: headers }).then((res) => {
        window.location.assign("/verify-email")

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "OTP")
    })
}
export const register = (data, post_registraion) => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v2/register`, data, { headers: headers }).then((res) => {
        localStorage.setItem("userId", res.data.user_Id)
        sendOTP(res.data.user_Id)
        saveUserAsContact(data)
        dispatch({
            type: REGISTER,
            payload: res.data
        })
    }, (err) => {

        LogTrigger(err.response.data.message, "error", window.location.href, "register")
        post_registraion()
        dispatch({
            type: REGISTER_ERROR,
            payload: err.response.data.message
        })

    }
    )

}

export const logout = (data) => (dispatch) => {
    LogTrigger("User logged out ", "info", window.location.href, "Logour")
    dispatch({ type: LOGOUT, payload: data })
}

export const center = (data) => (dispatch) => {
    dispatch({
        type: CENTER,
        payload: data
    })
}



export const refresh = (data) => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/refreshToken`, data, { headers: headers }).then((res) => {

        LogTrigger("Token Regenerate ", "info", window.location.href, "Login")
        dispatch({
            type: REGENERATE,
            payload: res.data.data.attributes
        })


    }, (err) => {

        LogTrigger(err.response.data.message, "error", window.location.href, "Regenerate token")
        dispatch({
            type: LOGIN_ERROR,
            payload: "Failed"
        })

    }
    )
}



export const googleLogin = (data, handleResponse) => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoLogin`, data, { headers: headers }).then((res) => {

        localStorage.setItem("permissions", JSON.stringify(res.data.data.attributes.permissions))
        localStorage.setItem("access", JSON.stringify(res.data.data.attributes.PlanAndPermission))
        if (res.data.data.attributes.FirstTimeUser) {
            localStorage.setItem("showUserGuide", true)
        }
        if (res.data.data.attributes.isUserInvited) {
            localStorage.setItem("expiredaccount", true)
        }
        dispatch({
            type: LOGIN,
            payload: res.data.data.attributes
        })
        

        LogTrigger("User logged in via google ", "info", window.location.href, "Login")
        handleResponse(true)
    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Google Login")
        if (err.response.data.message === "Invalid User") {
            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
            handleResponse("Register")
        }
        if (err.response.data.message === "sql: no rows in result set") {
            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
            handleResponse("Register")
        }
        if (err.response.data.message === "Cannot find customer in stripes with provided email!") {
            window.location.assign("/plans")
            dispatch({
                type: LOGIN_ERROR,
            })
        }
        if (err.response.data.message === "User haven't subscribe for any plan") {
            window.location.assign("/plans")
            dispatch({
                type: LOGIN_ERROR,
            })
        }
        if (err.response.data.message === "Cannot find customer in payment partner with provided email!" || err.response.data.message === "Cannot find active subscription for the provided email!") {
            localStorage.setItem("userId", err.response.data.UserId)
            window.location.assign("/plans")
            dispatch({
                type: LOGIN_ERROR,
            })
        }
        if (err.response.data.message === "Your 14-day trial period has ended. Please upgrade your plan"){
            window.location.assign("/plans?freetrail=expired")
        }
        if (err.response.data.message === "Cannot find active subscription for the provided email!") {
            window.location.assign("/plans?upgrade=plan")
            dispatch({
                type: LOGIN_ERROR,
                payload: "Your account subscription has expired, please renew it "
            })
        }
        else {
            dispatch({
                type: LOGIN_ERROR,
                payload: ""
            })
        }


    }
    )
}

export const googleobj = (data) => (dispatch) => {
    dispatch({ type: GOOGLEOBJ, payload: data })
}

export const githubobj = (data) => (dispatch) => {
    dispatch({ type: GITHUBOBJ, payload: data })
}

export const openLoginAgain = (data) => (dispatch) => {
    dispatch({ type: LOGIN_AGAIN, payload: data })
}

export const closeLoginAgain = (data) => (dispatch) => {
    dispatch({ type: LOGIN_AGAIN, payload: data })
}




export const googleregister = (data, handleError) => (dispatch) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoSignUp`, data, { headers: headers }).then((res) => {
        localStorage.setItem("customer_Stripe_Id", res.data.customer_Stripe_Id)
        dispatch({
            type: REGISTER,
            payload: res.data
        })

        LogTrigger("User registering via google ", "info", window.location.href, "Google Register")

        window.location.assign("/plans")

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Google Register")
        handleError(err.response.data.message)
        dispatch({
            type: REGISTER_ERROR,
            payload: err.response.data
        })
    }
    )

}

export const githubregister = (data, done) => (dispatch) => {

    const headers = {
        'Content-Type': 'application/json'
    }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/ssoSignUp`, data, { headers: headers }).then((res) => {
        localStorage.setItem("customer_Stripe_Id", res.data.customer_Stripe_Id)
        dispatch({
            type: REGISTER,
            payload: res.data
        })

        LogTrigger("User registering via github ", "info", window.location.href, "Github Register")
        window.location.assign("/plans")

    }, (err) => {
        LogTrigger(err.response.data.message, "error", window.location.href, "Github Register")
        done(err.response.data.message)

        dispatch({
            type: REGISTER_ERROR,
            payload: err.response.data
        })
    }
    )

}







export const billingInformation = (token) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    const data = { "customer": token }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/stripesPortal`, data, { headers: headers }).then((res) => {
        localStorage.setItem("customer_Stripe_Id", res.data.customerPortal)
    })
}