import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdDelete, MdEdit } from 'react-icons/md';
import { ReactComponent as MySvgFile } from '../../Images/pat.svg';
import { FETCH_USER_PAT } from '../Home/CreateNewApp/StepTwo';

export const ADD_USER_PAT = gql`
mutation AddUserPAT($type: String!,$patToken: String!){
    addPAT(input: {type:$type, patToken: $patToken})
}
`

export const UPDATE_USER_PAT = gql`
mutation UpdateUserPAT($type: String!,$patToken: String!){
    updatePAT(input: {type:$type, patToken: $patToken})
}
`

export const DELETE_USER_PAT = gql`
mutation DeleteUserPAT($id: String!){
    deletePAT(id:$id)
}
`


var CryptoJS = require("crypto-js");
function decodeAes(encryptedDataStr) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedDataStr);
    var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    decryptedData = decryptedData.toString(CryptoJS.enc.Utf8)
    return decryptedData

}

export function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    encryptedData = encryptedData.ciphertext.toString();

    return encryptedData

}


export default function UserLevelPAT(props) {
    
    const [userPAT, setuserPAT] = React.useState("Github");
    const [userTokenValue, setuserTokenValue] = React.useState("");
    const [showTutorial, setshowTutorial] = React.useState(false);
    const [isEdit, setisEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const handleChange = (event) => {
        setuserPAT(event.target.value);
    };
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = () => {
        addUserPAT({ variables: { type: userPAT, patToken: encodeAes(userTokenValue) } })
    }

    const handleUpdate = () => {
        updateUserPAT({ variables: { type: userPAT, patToken: encodeAes(userTokenValue) } })
    }


    const [addUserPAT, { loading: loadAdd, error: errAdd }] = useMutation(ADD_USER_PAT,
        {
            refetchQueries: [FETCH_USER_PAT], onCompleted: () => {
                if(props.handleFetchGithubRepos){
                    props.handleFetchGithubRepos(userTokenValue)
                }

                // if (props.refetch) {
                //     props.refetch();
                   
                  
                // }
                enqueueSnackbar("Successfully added!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        }
    );

    const [updateUserPAT, { loading: loadUpdate, error: errUpdate }] = useMutation(UPDATE_USER_PAT,
        {
            refetchQueries: [FETCH_USER_PAT], onCompleted: () => {
                setisEdit(false);
                enqueueSnackbar("Successfully updated!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        }
    );

    const [deleteUserPAT, { loading: loadDelete, error: errDelete }] = useMutation(DELETE_USER_PAT,
        {
            refetchQueries: [FETCH_USER_PAT], onCompleted: () => {
                handleClose()
                enqueueSnackbar("Successfully deleted!", {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        }
    );

    const [fetchUserPAT, { loading: loadlist, error: errlist, data: datalist }] = useLazyQuery(FETCH_USER_PAT);

    useEffect(() => {
        fetchUserPAT();

    }, [datalist]);

    if (loadlist || loadDelete || loadUpdate) {
        return "Loading.."
    }
    return (
        <div style={{ padding: "16px", paddingTop: "0px" }}>

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <Typography>
          delete user level personal access token
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth disableElevation onClick={handleClose}>cancel</Button>
          <Button fullWidth color='error' variant='contained' disableElevation onClick={()=>{ deleteUserPAT({ variables: { id: datalist.getUserPAT[0].id } })}} autoFocus>
            delete
          </Button>
        </DialogActions>
      </Dialog>

            {datalist && datalist.getUserPAT && datalist.getUserPAT[0] ?
                <div>
                    {isEdit ?

                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                        >


                            <Typography variant='h4' sx={{ mt: 3, mb: 1, fontWeight: "lighter" }} color={"primary"}>Update user personal token!</Typography>


                            <TextField value={userTokenValue} onChange={(e) => {
                                setuserTokenValue(e.target.value);
                                    if(props.setfirstTimeGithubToken){
                                        props.setfirstTimeGithubToken(e.target.value)
                                    }
                                    
                                }}
                                label="Personal Access Token" sx={{ mr: 1 }} placeholder='Enter PAT' size='small' />
                            <div>
                                <Button sx={{ my: 1, mr: 1 }} disabled={userTokenValue === "" ? true : false} variant='contained' onClick={handleUpdate} disableElevation>update</Button>
                                <Button variant="outlined" sx={{ my: 1 }} onClick={() => {setisEdit(false); setuserTokenValue("")}} disableElevation>cancel</Button>
                            </div>

                        </Grid>
                        :
                        <Grid>
                            <Grid item xs={12}>
                            <Typography variant='h6' style={{ fontWeight: "bold", margin: "15px 0px" }}> User Personal Access Token </Typography>
                       
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="end"
                                    style={{ marginBottom: "2rem" }}

                                >
                                    <div>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"


                                        >
                                            <AiFillCheckCircle style={{ fill: "#2196f3", marginRight: "5px", fontSize:"20px" }} />
                                            <Typography variant="body2" gutterBottom noWrap={true}>
                                                You have added PAT for {datalist.getUserPAT[0].type}
                                            </Typography>
                                        </Grid>
                                    </div>

                                    <div>
                                        <IconButton variant="outlined" color="primary" onClick={() => { setisEdit(true); setuserTokenValue(decodeAes(datalist.getUserPAT[0].patToken)) }}>
                                            <MdEdit />
                                       
                                        </IconButton>
                                        <IconButton variant="outlined" color="primary" onClick={() => { handleClickOpen();  }} >
                                            <MdDelete />
                                        </IconButton>
                                    </div>

                                </Grid>

                            </Grid>
                        </Grid>}
                </div>
                :
                <Grid container spacing={2} sx={{ pt: 2 }}>
                    <Grid item xs={2}>

                        <MySvgFile style={{ width: "100%", height: "100%", marginTop: "5px" }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='h4' sx={{ mt: 3, mb: 1, fontWeight: "lighter" }} color={"primary"}>Deploy Apps more effortlessly!</Typography>
                        <Typography variant='p' sx={{ my: 2, color: "grey" }}>Add personal access token, select last commited repository and deploy!.</Typography>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{ mt: 4 }}
                        >
                            <FormControl sx={{ mr: 1 }} size="small">
                                <InputLabel id="demo-select-small-label">Select</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={userPAT}
                                    label="Select"
                                    onChange={handleChange}
                                >

                                    <MenuItem value={"Github"}>Github</MenuItem>
                                    <MenuItem value={"Gitlab"}>Gitlab</MenuItem>
                                    <MenuItem value={"Bitbucket"}>Bitbucket</MenuItem>
                                </Select>
                            </FormControl>

                            {userPAT === "Github" ? <>
                                <TextField value={userTokenValue} onChange={(e) => setuserTokenValue(e.target.value)} label="Personal Access Token" sx={{ mr: 1 }} placeholder='Enter PAT' size='small' />

                                <Button sx={{ my: 1 }} disabled={userTokenValue === "" ? true : false} variant='contained' onClick={handleSubmit} disableElevation>Add</Button>






                                <Typography sx={{ my: 2 }} variant='body2'>How to get {userPAT} personal access token? <b style={{ color: "blue", cursor: "pointer" }} onClick={() => setshowTutorial(true)}>Click here for step by step guide.</b></Typography>
                                <Dialog open={showTutorial} onClose={() => setshowTutorial(false)}>
                                    <DialogTitle>
                                        Create <b>GitHub Personal Access Token</b>
                                    </DialogTitle>
                                    <DialogContent>
                                        <b> 1. Sign in to GitHub:</b> Make sure you're signed in to your GitHub account. If you don't have an account, you'll need to create one first.
                                        <p> <b> 2. Access Personal Access Tokens Settings:</b> Click on your profile picture in the upper-right corner of the GitHub page, and then click on "Settings." </p>
                                        <img style={{ maxHeight: "300px" }} src="https://docs.github.com/assets/cb-65929/mw-1440/images/help/settings/userbar-account-settings.webp" />
                                        <p> <b> 3. Access Developer Settings:</b> In the left sidebar, click on "Developer settings."</p>
                                        <p> <b> 4. Access Personal Access Tokens:</b> In the Developer settings menu, click on "Personal access tokens."</p>
                                        <p> <b> 5. Generate a New Token:</b> Click on the "Generate token" button to create a new personal access token.</p>
                                        <p> <b> 6. Configure Token:</b> Configure your personal access token by selecting the scopes (permissions). Make sure to enable the <b>repo scope</b> so that we can list your repositories. Be careful with the permissions you grant, as they determine what the token can access.</p>
                                        <img style={{ width: "100%" }} src="https://storage.googleapis.com/user-profileimage/GitHubRepoInstruction.png" />
                                        <p> <b> 7. Generate Token:</b> Scroll down to the bottom of the page, and click on the "Generate token" button.</p>
                                        <p> <b> 8. Copy Your Token:</b> Once you've generated the token, you will see it displayed on the screen. Be sure to copy the token and store it securely. You won't be able to see the token again.</p>
                                        <img style={{ width: "100%" }} src="https://docs.github.com/assets/cb-17251/mw-1440/images/help/settings/personal-access-tokens.webp" />
                                        <p> <b> 9. Use Your Token:</b> Now paste this personal access token to input and click on add.</p>
                                    </DialogContent>


                                </Dialog>
                            </> : <>
                                Coming soon..
                            </>}

                        </Grid>
                    </Grid>
                 
                </Grid>}
        </div>
    );
}

