import React, { useEffect, useState } from 'react';
import { AiFillGithub } from "react-icons/ai";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { githublogin, googleobj } from '../actions/index';

import { Button, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { supabase } from '../client';
import { CustomTooltip } from './Settings/Organization';
import * as Sentry from "@sentry/react";


function GithubLoginButton(props) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const [user, setUser] = useState(null);
  const [userToken, setuserToken] = useState(null);
  useEffect(() => {
    checkUser();
    window.addEventListener('hashchange', function () {
      checkUser();
    });
  }, [])

  
  var CryptoJS = require("crypto-js");
  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  async function signOut() {
    await supabase.auth.signOut();
    setUser(null);
  }


  let history = useHistory()

  const handleResponse = (res) =>{
    // signOut()
    if(res=="Register"){
       history.push("/github-register")
    }
  }

  const handleLogingithub = () => {
    props.githublogin({"accessToken": encodeAes(supabase.auth.currentSession.provider_token), "ssoType":"github"},handleResponse)
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: supabase.auth.user()?.email,
        id: supabase.auth.user()?.id 
      });
    });
  }

  async function checkUser() {
    const user = supabase.auth.user();
    if (supabase.auth.currentSession && supabase.auth.currentSession.user.app_metadata.provider==="github") {
      setuserToken(supabase.auth.currentSession.provider_token)
      handleLogingithub()
    }
    setUser(user);

  }
  async function signInWithGithub() {

    const { user, session, error } = await supabase.auth.signIn({
      provider: 'github'
    });
  }




  async function signOut() {

    await supabase.auth.signOut();
    setUser(null);
  }

  return (
    <div className="App">
    <CustomTooltip title="Connect With Github">
    <Button size='large' fullWidth variant='outlined' disableElevation onClick={signInWithGithub} sx={{textTransform:"capitalize",borderRadius:"24px",border:"1px solid rgba(0, 0, 0, 0.12)",py:1}}><AiFillGithub style={{fontSize:"35px"}}/></Button>
      {/* <IconButton style={{fontSize:"35px",color:isDarkTheme ? "#fff" : "#000"}} onClick={signInWithGithub}><AiFillGithub/></IconButton> */}
      </CustomTooltip></div>
  );
}

const mapStateToProps =  ( {authReducer} ) => {

    return { authReducer }
}
export default connect(mapStateToProps, { githublogin,googleobj })(GithubLoginButton)