import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import CreateNewApp from '../Components/Home/CreateNewApp/Main';
import { CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import OneClickDeploy from '../Components/MarketPlace/OneClickDeploy';
import { FaStore } from 'react-icons/fa';
import { CONFIG_APP_BASE_COMPANY } from '../configApp';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const DeployLink = (props) => {
    const urlid = useQuery().get("name")
    const { isAuthenticated } = props.authReducer
    const isDarkTheme = useTheme().palette.mode === 'dark';
    let history = useHistory()
    const [value, setvalue] = useState();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.npoint.io/429393ab56ea69fc2446');

                if (response.data) {
                    var temp = response.data.find((o) => o.name === urlid);
                    if (temp) {
                        setvalue(temp)
                    }
                    else {
                        history.push("/")
                        enqueueSnackbar("No such app exist in marketplace", {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right',
                            },
                        })
                    }
                }
            } catch (error) {
                enqueueSnackbar("Error fetching data", {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })

            }
        };
        fetchData();
    }, [value]);

    if (isAuthenticated && urlid) {
        return (
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                {isDarkTheme ?
                    <img alt="Oikos" loading="lazy" src={"/OikosDark.svg"} height="120px" width="140px" /> :
                    <img alt="Oikos" loading="lazy" src={CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg"}  height="120px" width="140px" />}




                <Typography variant='h5' color={"primary"} sx={{ fontWeight: "bold" }}>   <FaStore /> Market Place/ {urlid}</Typography>
                <br />
                <Typography variant='h6' sx={{ mt: 2 }} color="primary">
                    <CircularProgress size={15} sx={{ mx: 0.5 }} /> {value ? "Done" : "Fetching details..."}
                </Typography>

                <br />
                {value && <OneClickDeploy appDetails={value} hideDeployButton={true} />}
                {/* <CreateNewApp isHook={isHook} deploy={urlid} name={"Deploy " + urlid} handlePostUrlDeploy={handlePostUrlDeploy} returnHome={true} /> */}
            </div>

        )
    }
    else {
        localStorage.setItem("deployLink", urlid)
        history.push("/")
        enqueueSnackbar("Unauthenticated user, Please login first!", {
            variant: 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        })
        return null;
    }
}

const mapStateToProps = ({ authReducer }) => {

    return { authReducer }
}

export default connect(mapStateToProps)(DeployLink)