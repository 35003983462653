import { Fade, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import Countdown from "react-countdown";
import { BiTimeFive } from 'react-icons/bi';
import { useHistory } from "react-router-dom";
import { CONFIG_APP_BASE_API } from '../configApp';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}

      {new Date().getFullYear()}{' '}
      <Link href="https://nife.io/">
        nife.io
      </Link>
      {' | '}
      <Link href="/policies/privacy-policy/">
        Privacy Policy
      </Link>{'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: 5,
  },
}));

export default function SignIn() {
  let history = useHistory();
  const [opt, setopt] = useState("")
  const classes = useStyles();
  const [optExpired, setoptExpired] = useState(false)
  const [optIncorrect, setoptIncorrect] = useState(false)
  const [now, setnow] = useState(Date.now() + 600000);
  const handleSubmit = (e) => {
    e.preventDefault()
    const headers = {
      'Content-Type': 'application/json'
    }
    var data = { "userId": localStorage.getItem("userId"), "code": parseInt(opt) }
    axios.post(`${CONFIG_APP_BASE_API}/api/v1/verificationCode`, data, { headers: headers }).then((res) => {
    
      // localStorage.setItem("token", res.data.accessToken)
      history.push("/plans");
    }, (err) => {
  
      if (err.response.data.message === "Otp mismatched") {
        setoptIncorrect(true)
      }
      else {
        setoptExpired(true)
      }

    })

  }


  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Link href="#" variant="body2">
        Resend OTP.
      </Link>
    } else {
      return (

<Grid
  container
  direction="row"
  justifyContent="center"
  alignItems="center"
  sx={{mt:2,}}
>

      <BiTimeFive /> 
        <Typography sx={{mx:1,textAlign:"center"}}>{minutes}:{seconds} minutes left </Typography>
     </Grid>
      );
    }


  };


  const handleChange = (e) => {
    setopt(e.target.value)
    setoptIncorrect(false)
  }

  useEffect(() => {
    setnow(Date.now() + 600000)
  }, []);
  return (
   
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper variant='outlined' sx={{p:3,borderRadius:"24px",mt:8,pb:8,boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px",}}>
      <div className={classes.paper}>
        {!optExpired && <>
          <Typography component="h1" variant="h5">
            Enter Verification Code
          </Typography>
          <Typography variant="body2" style={{ textAlign: "center",marginTop:"10px",color:"grey" }}>
            You will recieve an OTP to your registered email address. Your OTP will expire in next 10 minutes.
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              
              value={opt}
              onChange={handleChange}
              fullWidth
              error={optIncorrect}
              id="email"
              helperText={optIncorrect && "Incorrect OTP"}
              label="Verification Code"
              name="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              
              variant="contained"
              color="primary"
              className={classes.submit}
              disableElevation
            >
              Verify
            </Button>
            <Grid container>
              {/* <Grid item xs>
              <Link href="#" variant="body2">
                Update email?
              </Link>
            </Grid> */}
              <Grid item>


              </Grid>
            </Grid>
          </form></>}
        {optExpired && <Fade in={true}>
        <div>
           <img src="https://react-nife-2024.s3.ap-south-1.amazonaws.com/OTP.svg" width={"100%"}/>
          <Typography component="h1" variant="h5">
            Your OTP has expired
          </Typography>
        </div>
       
        </Fade>}
      </div>

      {!optExpired && <Countdown date={now} renderer={renderer}>
            
            </Countdown>}
   
      </Paper>
    </Container>
  );
}