import { Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FaLock } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { logout } from '../actions';
import "./UpgradePlan.css";
import { CONFIG_APP_BASE_API } from '../configApp';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UpgradePlan(props) {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory()
    const handleClickOpen = () => {
        history.push("/plans?upgrade=plan")
    };

    const handleProductionPlan = (url) => {

        enqueueSnackbar("Disabled free plan", {
            variant: 'info',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        })
        const headers = {
            'Content-Type': 'application/json'
        }
        const data = {
            'userId': localStorage.getItem("userId"),
            'isfreePlan': false,
        }
        axios.put(`${CONFIG_APP_BASE_API}/api/v1/freePlan`, data, { headers: headers }).then((res) => {
            if (res.data) {
                window.location.assign(url)
            }
        }, (err) => {
            console.log(err.response.status)
        }
        )

        props.logout()

    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>

            {!props.org && !props.invite && !props.app && <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <IconButton><FaLock /></IconButton>
                {props.center ? <span style={{ textAlign: "center" }}>To Unlock this feature, please upgrade your plan.</span> : <span>To Unlock this feature, please upgrade your plan.</span>}

                <Button onClick={handleClickOpen}>Unlock</Button>
            </Grid>}
            {props.invite && <Button onClick={handleClickOpen}>upgrade</Button>}
            {props.app && <Button color='primary' variant='contained' onClick={handleClickOpen}>upgrade plan</Button>}
            {props.org && <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography style={{ margin: "10px" }} variant="h6" component="h6">
                    You have reached Organization limit</Typography>

                <Typography style={{ margin: "10px", marginTop: "0px", color: "grey" }} variant="subtitle1" component="subtitle1">To create more Organization, please upgrade your account</Typography>

                <Button onClick={handleClickOpen}>upgrade</Button>
            </Grid>}


       
        </div>
    )
}

const mapStateToProps = ({ authReducer }) => {
    return { authReducer }
}
export default connect(mapStateToProps, { logout })(UpgradePlan)