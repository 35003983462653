import { gql, useQuery } from '@apollo/client';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Grid, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect } from "react";
import { TiLocation } from "react-icons/ti";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import LogTrigger from "../../LogTrigger";
import { Types } from 'aws-sdk/clients/apigateway';
import { BsFillPieChartFill } from 'react-icons/bs';

export const LOCATIONS_GRAPH_DATA = gql`
{
  AppsCount {
    region {
      Region
      apps
    }
  }
}`


const COLORS = ["#66d8e5b5",
  "rgb(75, 192, 192)",
  "rgb(53, 162, 235)",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF"]

const RADIAN = Math.PI / 180;

export default function LocationGraph({ setodv }) {
  const { loading, error, data, refetch } = useQuery(LOCATIONS_GRAPH_DATA, {
    onCompleted: (data) => {
      if (setodv && data && data.AppsCount && data.AppsCount.region && data.AppsCount.region) {
        setodv(prevState => ({
          ...prevState,
          locationCount: data.AppsCount.region.length
        }));
      }
    }
  });

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "start"}
        dominantBaseline="central"
      >
        {data.AppsCount && data.AppsCount.region[index].Region} {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    refetch()
  }, []);

  if (error) {
    LogTrigger(error.message, "error", window.location.href, "Location graph")
  }


  if (loading) return <div>
    <Skeleton variant="rectangular" height={40} />
    <br /><Skeleton variant="circular" height={270} width={270} />
  </div>;
  return (
    <div>

      {data && data.AppsCount && data.AppsCount.region ?
        <ResponsiveContainer width={'99%'} height={300}>
          <PieChart>
            <Pie
              data={data && data.AppsCount.region}

              labelLine={false}
              label={renderCustomizedLabel}
              width={20}
              fill="#8884d8"
              dataKey="apps"
            >
              <Legend />
              {data && data.AppsCount.region && data.AppsCount.region.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>

          </PieChart>
        </ResponsiveContainer>
        : <div>

          <Typography variant="body2">Deploy an app to see a beautiful pie chart.</Typography> 
          <Typography sx={{textAlign:"center",mt:"150px"}}><BsFillPieChartFill style={{fontSize:"40px"}} /></Typography>
          </div>}
      <br />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >

        {data && data.AppsCount && data.AppsCount.region && data.AppsCount.region.map((entry, index) => {

          return (
            <div key={index}>
              <IconButton style={{ padding: "2px" }} size="large"><FiberManualRecordIcon style={{ color: `${COLORS[index]}` }} /> </IconButton>  {entry.Region} ({entry.apps} App{entry.apps > 1 && "s"})
            </div>
          );
        }
        )}
      </Grid>
    </div>
  );
}
