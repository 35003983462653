import { useQuery } from '@apollo/client';
import { Avatar, Fade, Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { BiBuildings } from "react-icons/bi";
import { USER_INFO } from '../Components/Home/Profile';
import { CustomTooltip } from '../Components/Settings/Organization';
import { NewListItems } from './listItems';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    background: "transparent",
    position: 'relative',
    maxHeight: '99vh',
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflow: 'hidden', // Hide overflow when closed

    width: theme.spacing(9),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '99.3vh',
    overflow: 'auto',
  },
}));

export default function SideBar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { data: userdata, refetch } = useQuery(USER_INFO, {
    variables: { userId: localStorage.getItem("userId") }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {useMediaQuery('(min-width:600px)') && (
        <SwipeableDrawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <Grid container direction="column" justifyContent="center" alignItems="flex-start">
            <div className={classes.toolbarIcon}>
              <Fade in={true} style={{ transformOrigin: '0 0 0' }}>
                <div>
                  {userdata && (
                    <CustomTooltip title={userdata.getUserById.companyName} placement="right-start">
                      <Avatar
                        sx={{
                          borderRadius: "12px",
                          height: "45px",
                          width: "45px",
                          margin: "15px",
                          border: "0px solid #160044",
                        }}
                        src={userdata.getUserById.companylogo}
                      >
                        <BiBuildings style={{ fontSize: "20px", marginTop: "20px", marginLeft: "20px", textAlign: "center" }} />
                      </Avatar>
                    </CustomTooltip>
                  )}
                </div>
              </Fade>
            </div>
            <NewListItems />
          </Grid>
        </SwipeableDrawer>
      )}
      <Grid container>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
}
