import React, { useEffect } from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { CONFIG_APP_BASE_COMPANY } from '../../../configApp';

const strokeColor = CONFIG_APP_BASE_COMPANY === "nife" ? "#a976f1" : "#C59C9A";
const fillColor = CONFIG_APP_BASE_COMPANY === "nife" ? "#a976f1b5" : "#C59C9Ab5";
const strokeColorDeployed = CONFIG_APP_BASE_COMPANY === "nife" ? "#4dfbe0" : "#D1A64D";
const fillColorDeployed = CONFIG_APP_BASE_COMPANY === "nife" ? "#4dfbe0b5" : "#D1A64Db5";


const NifeLineChart = ({data,type}) => {
  useEffect(() => {
  }, [data]);

  if(!data){
    return "No data here..";
  }
  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart data={data}>
        <XAxis dataKey="time" />
        <YAxis width={20}/>
        <Tooltip cursor={{fill: 'transparent'}} />
        
        {type === "app" && (
      <Area name='App Created' type="monotone" dataKey="appCreated" stroke={strokeColor} fill={fillColor} />
    )}
    {type === "app" && (
      <Area name='App Deployed' type="monotone" dataKey="appDeployed" stroke={strokeColorDeployed} fill={fillColorDeployed} />
    )}
    {type === "site" && (
      <Area name='Site Deployed' type="monotone" dataKey="siteDeployed" stroke={strokeColorDeployed} fill={fillColorDeployed} />
    )}
     
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default NifeLineChart;
