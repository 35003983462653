import { Divider, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React from 'react'
import { MdOutlineContentCopy } from 'react-icons/md'

export default function Configuration({ data }) {
    const { enqueueSnackbar } = useSnackbar();
    const handleClickToClipboard = (data) =>{
        navigator.clipboard.writeText(data);
              enqueueSnackbar("Copied to clipboard", {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        
    }
    if (!data) {
        return "No data.."
    }
    return (
        <div>
           {data.getS3deployments.buildCommandsS3 && data.getS3deployments.buildCommandsS3[0] && <div>
            <Divider sx={{mt:2}}/>
        
            <h3>Site Configuration</h3>
         <Paper variant='outlined' sx={{ p: 3, borderRadius: "12px" }}>
                <Grid container spacing={3}>
                    <Grid item md={6} sx={{mb:2}}>
                        <Typography variant='p' sx={{ fontWeight: "bold", margin: "2px", marginLeft: "0px" }}>Installed command</Typography>
                        <Typography sx={{ marginTop: "5px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                            The command that is used to install your Project's software dependencies.
                        </Typography>
                        <>
                            <TextField size='small' value={data.getS3deployments.buildCommandsS3[0].s3Cmd} />
                            <IconButton onClick={()=>handleClickToClipboard(data.getS3deployments.buildCommandsS3[0].s3Cmd)}><MdOutlineContentCopy /></IconButton>
                        </>
                    </Grid>

                    <Grid item md={6} sx={{mb:2}}>
                        <Typography variant='p' sx={{ fontWeight: "bold", margin: "2px", marginLeft: "0px" }}>Build command</Typography>
                        <Typography sx={{ marginTop: "5px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                            The command your frontend framework provides for compiling your code.
                        </Typography>
                        <>
                            <TextField size='small' value={data.getS3deployments.buildCommandsS3[1].s3Cmd} />
                            <IconButton onClick={()=>handleClickToClipboard(data.getS3deployments.buildCommandsS3[1].s3Cmd)}><MdOutlineContentCopy /></IconButton>
                        </>
                    </Grid>

                    <Grid item md={6}>
                        <Typography variant='p' sx={{ fontWeight: "bold", margin: "2px", marginLeft: "0px" }}>Environment Variables</Typography>
                        <Typography sx={{ marginTop: "5px", fontSize: "12px", color: "grey", marginBottom: "10px" }}>
                            Provide the Environment Variables for Build and Runtime.
                        </Typography>
                        <>
                            {data.getS3deployments.envVariablesS3 && data.getS3deployments.envVariablesS3.length > 0 ? <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Key</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.getS3deployments.envVariablesS3.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{item.name ==="" ? <i>Empty String</i> : item.name}</TableCell>
                                            <TableCell>{item.value ==="" ? <i>Empty String</i> : item.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> : 
                            "No Environment Variables configured"
                            }

                        </>
                    </Grid>


                </Grid>
            </Paper> 
            </div>}
        </div>
    )
}
