import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Chip } from '@mui/material';

const LATENCY_URLS = {
    'us-east-1': 'https://dynamodb.us-east-1.amazonaws.com/ping',
    'us-east-2': 'https://dynamodb.us-east-2.amazonaws.com/ping',
    'us-west-1': 'https://dynamodb.us-west-1.amazonaws.com/ping',
    'us-west-2': 'https://dynamodb.us-west-2.amazonaws.com/ping',
    'ca-central-1': 'https://dynamodb.ca-central-1.amazonaws.com/ping',
    'ca-west-1': 'https://dynamodb.ca-west-1.amazonaws.com/ping',
    'ap-south-1': 'https://dynamodb.ap-south-1.amazonaws.com/ping',
};

const fetchLatency = async (url) => {
    try {
        const startTime = performance.now();
        await fetch(url, {
            cache: 'reload',
            credentials: 'omit',
            redirect: 'error',
        });
        const endTime = performance.now();
        return Math.round(endTime - startTime);
    } catch (error) {
        console.error('Fetch error:', error);
        return 'error';
    }
};

const extractRegionCode = (url) => {
    try {
        const matches = url.match(/\.([a-z0-9-]+)\.elb\.amazonaws\.com/);
        if (matches && matches[1]) {
            return matches[1];
        } else {
            throw new Error('Region code not found in the URL');
        }
    } catch (error) {
        console.error('Error extracting region code:', error);
        return null;
    }
};

const checkLatency = async (regionCode) => {
    try {
        const url = LATENCY_URLS[regionCode];
        if (!url) {
            throw new Error('Invalid region code');
        }
        return await fetchLatency(url);
    } catch (error) {
        console.error('Error fetching latency:', error);
        return 'error';
    }
};

export const GET_CLUSTER_DETAILS = gql`
  query GetClusterDetails($regCode: String!) {
    getClusterDetails(regCode: $regCode) {
      id
      providerType
      regionName
      loadBalancerURL
    }
  }
`;

export default function PingChecker({ regCode }) {
    const [latencyValue, setLatencyValue] = useState(0);
    const { loading, error } = useQuery(GET_CLUSTER_DETAILS, {
        variables: { regCode },
        onCompleted: async (data) => {
            if (data) {
                const regionCodeValue = extractRegionCode(data.getClusterDetails.loadBalancerURL);
                if (regionCodeValue) {
                    const latency = await checkLatency(regionCodeValue);
                    setLatencyValue(0)
                    setLatencyValue(latency);
                } else {
                    setLatencyValue('error');
                }
            }
        }
    });

    if (error) return <p>Error: {error.message}</p>;

    return (
        <Chip 
            label={
                loading ? "Loading" : 
                latencyValue === 'error' ? "Error" :
                latencyValue <= 50 ? `Excellent (${latencyValue} ms)` : 
                latencyValue <= 100 ? `Good (${latencyValue} ms)` : 
                latencyValue <= 300 ? `Acceptable (${latencyValue} ms)` : 
                `High (${latencyValue} ms)`
            } 
            size='small' 
            color={
                loading ? "default" : 
                latencyValue === 'error' ? "error" : 
                latencyValue <= 50 ? "success" : 
                latencyValue <= 100 ? "primary" : 
                latencyValue <= 300 ? "warning" : 
                "error"
            }
        />
    );
}
